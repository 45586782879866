import { Divider, Menu, MenuItem } from '@mui/material';

import { Settings, Preferences, SignOut } from '../../Actions';
import { logoutUser } from '../../../store/user/user-action';
import { useAppDispatch } from '../../../hooks/redux-hooks';

interface DefaultMenuProps {
	isMenuOpen: boolean;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const DefaultMenu = ({ isMenuOpen, handleMenuClose, anchorEl }: DefaultMenuProps) => {
	const dispatch = useAppDispatch();

	const signOut = async () => {
		handleMenuClose();
		dispatch(logoutUser());
	};

	return (
		<Menu anchorEl={anchorEl} id='primary-search-account-menu' keepMounted open={isMenuOpen} onClose={handleMenuClose}>
			<MenuItem onClick={handleMenuClose}>
				<Settings disableTooltip />
				Profile
			</MenuItem>
			<Divider />
			<MenuItem onClick={signOut}>
				<SignOut disableTooltip />
				Sign Out
			</MenuItem>
		</Menu>
	);
};
