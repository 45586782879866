import React from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export interface IPaginationProps {
	currentPage: number;
	totalPages: number;
	gotoNextPageCb: () => void;
	gotoPrevPageCb: () => void;
}

export const Pagination = (props: IPaginationProps) => {
	return (
		<Box style={{ display: 'flex', justifyContent: 'space-around', bottom: '0px', width: '100%' }}>
			<IconButton
				style={{
					padding: '0px',
				}}
				color='primary'
				onClick={props.gotoPrevPageCb}
				disabled={props.currentPage === 0}>
				<NavigateBeforeIcon fontSize='medium'></NavigateBeforeIcon>
			</IconButton>
			{props.currentPage + 1} of {props.totalPages}
			<IconButton
				style={{
					padding: '0px',
				}}
				color='primary'
				onClick={props.gotoNextPageCb}
				disabled={props.totalPages - 1 === props.currentPage}>
				<NavigateNextIcon fontSize='medium'></NavigateNextIcon>
			</IconButton>
		</Box>
	);
};
