import React, { useState, useEffect } from 'react';
import type { GridAlignment } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { ClaimDetails } from '../../models/claim.model';
import moment from 'moment';
import { Typography } from '@mui/material';

export const ClaimsTable = (props: any) => {
	const { data, loading, single } = props;

	const [claims, setClaims] = useState<any>([]);

	const claimColumns = [
		{
			field: 'col1',
			headerName: 'Index',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col2',
			headerName: 'Claim Ref. No.',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col3',
			headerName: 'Date',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col4',
			headerName: 'From Date',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col5',
			headerName: 'To Date',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col6',
			headerName: 'Project',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col7',
			headerName: 'Client',
			width: 300,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col8',
			headerName: 'GST %',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col9',
			headerName: 'Amount',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
	];

	useEffect(() => {
		if (data) {
			formatClaimData(data);
		}
	}, [data]);

	const formatClaimData = async (data: ClaimDetails[]) => {
		let tmpList = data.map((info: ClaimDetails, index: number) => {
			const row = {
				id: info.id,
				projectId: info.projectId,
				clientId: info.clientId,
				col1: index + 1,
				col2: info.claimNumber ? info.claimNumber : 0,
				col3: info.createdAt && moment(info.createdAt).format('DD/MM/YYYY'),
				col4: info.fromDate && moment(info.fromDate).format('DD/MM/YYYY'),
				col5: info.toDate && moment(info.toDate).format('DD/MM/YYYY'),
				col6: info.projectName,
				col7: info.clientName,
				col8: info.gstAmount + '%',
				col9: info.claimAmount - Number(info.paymentReceived),
			};
			return row;
		});

		setClaims(tmpList);
	};

	const emptyListComponent = () => {
		return (
			<Typography textAlign={'center'} sx={{ marginTop: '15px' }}>
				No relevant claims found!
			</Typography>
		);
	};

	return (
		<DataGrid
			rows={claims}
			columns={claimColumns}
			loading={loading}
			rowCount={claims.length}
			hideFooterPagination={single || false}
			hideFooter={single || false}
			components={{
				NoRowsOverlay: emptyListComponent,
			}}
		/>
	);
};
