import { useEffect, useState } from 'react';
import { fetchAllUsers } from '../../../services/user.service';
import { UsersDataModel } from '../../../models/user-redux.model';

export const useUsers = (
	page: number,
	userType: string,
	toggle: boolean,
	limit: number
): UsersDataModel | undefined => {
	const [users, setUsers] = useState<UsersDataModel>();

	const fetchAll = async () => {
		const usersResponse = await fetchAllUsers(page, userType, limit);

		if (!usersResponse?.success) return;
		setUsers(usersResponse.data);
	};

	useEffect(() => {
		fetchAll();
	}, [page, userType, toggle]);

	return users;
};
