import { UserModel, UserReduxModel } from '../../models/user-redux.model';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialUserState: UserReduxModel = {
	user: undefined,
};

const userSlice = createSlice({
	name: 'user',
	initialState: initialUserState,
	reducers: {
		setUser(state, action: PayloadAction<UserModel>) {
			state.user = action.payload;
		},
		resetUser(state, action: PayloadAction<any>) {
			state.user = undefined;
		},
	},
});

export default userSlice;
