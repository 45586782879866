import React, { useState } from 'react';
import { List, Divider, Collapse, Box } from '@mui/material';

import { RouteItem } from './RouteItem';
import { SignOutRoute } from './SignOutRoute';

import { routes } from '../../../config';
import { Route } from '../../../types';

export const Routes = () => {
	const [routesState, setRoutesStage] = useState<Route[]>(routes);

	const handleMenuClick = (route: Route) => {
		const items = routesState.map((item) => {
			if (item.key === route.key) {
				item.expanded = !item.expanded;
			}
			return item;
		});
		setRoutesStage(items);
	};

	return (
		<div style={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
			<Box
				sx={{
					maxHeight: 'calc(100vh - 100px)', // Set a maximum height to limit overflow
					height: '100%',
					overflowY: 'auto',
					overflowX: 'hidden', // Hide horizontal scrollbar
					marginRight: '-20px',
					paddingRight: '20px',
					'&::-webkit-scrollbar': {
						width: '0.00001em', // Set a custom width for the scrollbar
					},
					'&::-webkit-scrollbar-track': {
						background: 'transparent', // Set a custom color for the track
					},
					'&::-webkit-scrollbar-thumb': {
						background: 'transparent', // Set a custom color for the thumb
					},
					'&::-webkit-scrollbar-corner': {
						background: 'transparent', // Set a custom color for the corner
					},
				}}>
				<List component='nav'>
					{routesState.map((route: Route) => {
						if (route?.isEnabled) {
							return (
								<div key={route.key}>
									{route.subRoutes ? (
										<>
											<RouteItem key={`${route.key}`} route={route} hasChildren handleMenuClick={handleMenuClick} />
											<Collapse in={route.expanded} timeout='auto' unmountOnExit>
												<List component='div' disablePadding>
													{route.subRoutes.map((sRoute: Route) => (
														<RouteItem key={`${sRoute.key}`} route={sRoute} nested />
													))}
												</List>
											</Collapse>
										</>
									) : (
										<RouteItem key={route.key} route={route} nested={false} />
									)}
									{route.appendDivider && <Divider />}
								</div>
							);
						}
					})}
				</List>
			</Box>
			<SignOutRoute />
		</div>
	);
};
