import axios from './api';

export const addNewClient = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const updateClient = async (url: string, payload: any) => {
	const response = await axios.put(url, payload);

	return response;
};

export const fetchAllClients = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const getClientDetails = async (clientId: number) => {
	const response = await axios.get('client/get?clientId=' + clientId);

	return response.data;
};

export const deleteClient = async (clientId: number) => {
	const response = await axios.delete('client/delete?clientId=' + clientId);

	return response.data;
};
