import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { Header } from './components/Header';
import { SegmentBody, IDataProps } from './components/SegmentBody';
import { Pagination, IPaginationProps } from './components/Pagination';
import '../css/PanelSegment.css';

export const spaceDelimiter = ';';

export interface ISegmentProps {
	header: IDataProps;
	placeholderText: string; // For when no data is present
	data?: Array<IDataProps>;
	dataElement?: FunctionComponent; // To render panel content with an FC - customized render from caller
	pagination?: IPaginationProps;
	width?: string;
}

export const SegmentView = (props: { segmentProps: ISegmentProps }) => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					height: 'inherit',
					paddingBottom: '8px',
					width: props.segmentProps.width || 'inherit',
				}}>
				{props.segmentProps.dataElement ? (
					<props.segmentProps.dataElement></props.segmentProps.dataElement>
				) : (
					<>
						<Box>
							<Header
								text={props.segmentProps.header.text}
								textClickCb={props.segmentProps.header.textClickCb}
								icons={props.segmentProps.header.icons}
							/>
						</Box>
						<hr style={{ margin: 0, color: '#D3D3D3' }} />
						{props.segmentProps.data && props.segmentProps.data.length > 0 ? (
							<>
								<Box
									style={{
										flex: 1,
										overflow: 'hidden',
										overflowY: 'scroll', // added scroll
									}}>
									<SegmentBody data={props.segmentProps.data} />
								</Box>
								{props.segmentProps?.pagination ? (
									<Pagination
										currentPage={props.segmentProps?.pagination?.currentPage}
										totalPages={props.segmentProps?.pagination?.totalPages}
										gotoNextPageCb={props.segmentProps?.pagination?.gotoNextPageCb}
										gotoPrevPageCb={props.segmentProps?.pagination?.gotoPrevPageCb}
									/>
								) : null}
							</>
						) : (
							<>
								{/* There are no data at all, render the place holder */}
								<div style={{ display: 'table', height: '100%', textAlign: 'center' }}>
									<span style={{ display: 'table-cell', verticalAlign: 'middle' }}>
										{props.segmentProps.placeholderText}
									</span>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
};
