import { createContext } from 'react';

import { User } from '../types/User';

export type IUserContext = {
	user?: User;
	setUser: (user: User) => void;
};

export const UserContext = createContext({
	user: undefined,
	setUser: (user: User) => {},
});
