import React, { useState } from 'react';
import { ProjectPanel } from './ProjectPanel';
import { ProjectInfoPanel } from './ProjectInfoPanel';
import { TypeSubTypePanel } from './TypeSubTypePanel';
import { PrecinctBlockPanel } from './PrecinctBlockPanel';
import { ProjectModel } from '../../models/project.model';
import {
	DefaultGridLayout,
	IDefaultGridLayoutProps,
	StackGridLayout,
	IStackLayoutProps,
} from '../../components/Layout/';

export function ManageProjects() {
	const [selectedProject, setSelectedProject] = useState<ProjectModel>();

	const handleProjectClick = (project: ProjectModel) => {
		setSelectedProject(project);
	};

	const layoutProps: IDefaultGridLayoutProps = {
		panelOne: <ProjectPanel onProjectClick={handleProjectClick}></ProjectPanel>,
		panelTwo: <ProjectInfoPanel selectedProject={selectedProject}></ProjectInfoPanel>,
		panelThree: <TypeSubTypePanel selectedProject={selectedProject}></TypeSubTypePanel>,
		panelFour: <PrecinctBlockPanel selectedProject={selectedProject}></PrecinctBlockPanel>,
	};

	return <DefaultGridLayout layoutProps={layoutProps}></DefaultGridLayout>;

	// const layoutProps: IStackLayoutProps = {
	// 	panelOne: <ProjectPanel></ProjectPanel>,
	// 	panelTwo: <ProjectInfoPanel selectedProject={selectedProject}></ProjectInfoPanel>,
	// 	panelThree: <TypeSubTypePanel></TypeSubTypePanel>,
	// 	panelFour: <PrecinctBlockPanel></PrecinctBlockPanel>,
	// };

	// return <StackGridLayout layoutProps={layoutProps}></StackGridLayout>;
}
