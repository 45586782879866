import axios from './api';

export const addBlock = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const updateBlock = async (url: string, payload: any) => {
	const response = await axios.put(url, payload);

	return response;
};

export const fetchAllBlocks = async (url: string) => {
	const response = await axios.get(url);

	return response;
};
