/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { logoutUser } from '../../../../store/user/user-action';
import { useAppDispatch } from '../../../../hooks/redux-hooks';

export const SignOutRoute = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const handleSignOutClick = () => {
		dispatch(logoutUser());
		history.push('/');
	};

	return (
		<ListItemButton
			css={css`
				position: absolute;
				bottom: 0;
				width: 100%;
			`}
			onClick={handleSignOutClick}>
			<ListItemIcon>
				<IconButton size='small'>
					<ExitToApp />
				</IconButton>
			</ListItemIcon>
			<ListItemText primary='Sign Out' />
		</ListItemButton>
	);
};
