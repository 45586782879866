import { useEffect, useState } from 'react';
import { fetchAllClients } from '../../../services/client.service';
import { ClientsDataModel, ClientModel } from '../../../models/client.model';

export const useAllClients = (): Array<ClientModel> | undefined => {
	const [clientsList, setClientsList] = useState<Array<ClientModel>>();

	const fetchAllClient = async () => {
		const typesResponse = await fetchAllClients('client/all');

		if (!typesResponse.data?.success) return;
		setClientsList(typesResponse.data.data);
	};

	useEffect(() => {
		fetchAllClient();
	}, []);

	return clientsList;
};

export const useClients = (page: number, toggle: boolean, limit: number): ClientsDataModel | undefined => {
	const [clientData, setClientdata] = useState<ClientsDataModel>();

	const fetchAll = async () => {
		const clientsResponse = await fetchAllClients('client/getAll?page=' + page + '&limit=' + limit);

		if (!clientsResponse.data?.success) return;
		setClientdata(clientsResponse.data.data);
	};

	useEffect(() => {
		fetchAll();
	}, [page, toggle]);

	return clientData;
};
