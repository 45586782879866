import React, { useState, FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

import { deleteClient } from '../../services/client.service';
import { useClients } from './hooks/clients';
import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { ClientsDataModel, ClientModel } from '../../models/client.model';
import { DefaultGridLayout, IDefaultGridLayoutProps } from '../../components/Layout';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { ISegmentProps, spaceDelimiter } from '../../components/Layout/Panel/views/Segment';
import { AddClientDialog } from '../../components/Dialog/AddClientDialog';
import { IDataProps } from '../../components/Layout/Panel/views/components/SegmentBody';
import DeleteConfirmationDialog from '../../components/Dialog/DeleteConfirmationDialog';

export function ManageClients(props: any) {
	const DEFAULT_PAGE_SIZE = 20;
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selectedClientForEdit, setSelectedClientForEdit] = useState<ClientModel>();
	const [page, setPage] = useState<number>(0);
	const [selectedClient, setSelectedClient] = useState<ClientModel>();
	const [showClientFormDialog, setClientFormDialog] = useState<boolean>(false);
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);

	const [showDeleteClientConfirmation, setShowDeleteClientConfirmation] = useState<boolean>(false);
	const [clientToDelete, setClientToDelete] = useState<number | null>(null);

	const clientsDataModel: ClientsDataModel | undefined = useClients(page, toggleForReload, DEFAULT_PAGE_SIZE);

	const handleClose = () => {
		setClientFormDialog((prevState) => false);
	};

	const refreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
	};

	const onClientAdded = () => {
		// Either one of the state change will refresh the page
		if (clientsDataModel!.clients.length == DEFAULT_PAGE_SIZE) {
			// We want to move to the next page if current page is already full
			setPage((prevState) => prevState + 1);
		} else {
			refreshPanel();
		}
	};

	const onDeleteClient = async (clientId: number) => {
		try {
			const result: any = await deleteClient(clientId);

			if (result?.success) {
				toast.success(result.message);
				refreshPanel();
			} else {
				toast.error(result.message);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
		setClientToDelete(null);
		setShowDeleteClientConfirmation(false);
	};

	const getClientsData = () => {
		let result: Array<IDataProps> = [];

		if (clientsDataModel?.clients) {
			for (let i = 0; i < clientsDataModel?.clients?.length; i++) {
				result.push({
					text: clientsDataModel?.clients[i].name,
					textClickCb: () => {
						let info = { ...clientsDataModel?.clients[i] };
						setSelectedClient((prevState) => ({ ...info }));
					},
					isTextSelected: selectedClient?.id === clientsDataModel?.clients[i]?.id,
					icons: [
						{
							icon: EIcon.DELETE,
							iconCb: () => (setClientToDelete(clientsDataModel?.clients[i].id), setShowDeleteClientConfirmation(true)),
						},
					],
				});
			}
		}

		return result;
	};

	const clientsProps: ISegmentProps = {
		header: {
			text: 'Client',
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						setClientFormDialog((prevState) => !prevState);
					},
				},
			],
		},
		placeholderText: 'No clients created',
		data: getClientsData(),
		width: '40%',
		pagination: {
			currentPage: page,
			totalPages: clientsDataModel?.totalPages as number,
			gotoNextPageCb: () => {
				if (clientsDataModel) {
					const { totalPages, currentPage } = clientsDataModel;

					if (currentPage < totalPages - 1) {
						setPage((prevState) => prevState + 1);
					}
				}
			},
			gotoPrevPageCb: () => {
				if (clientsDataModel) {
					const { totalPages, currentPage } = clientsDataModel;

					if (currentPage > 0) {
						setPage((prevState) => prevState - 1);
					}
				}
			},
		},
	};

	const createClientDetailLayout: FunctionComponent = () => {
		return (
			<div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
				<div
					style={{
						height: '500px',
						width: '600px',
						background: '#E1E5EA',
						boxShadow: '5px 0 30px rgba(1, 41, 112, 0.08)',
					}}>
					<div
						style={{
							height: '50px',
							width: '100%',
							background: '#A7BBC7',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingLeft: '20px',
						}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<CorporateFareIcon />
							<span
								style={{
									fontSize: '16px',
									fontWeight: 'bold',
									textTransform: 'uppercase',
									marginLeft: '10px',
									marginRight: '10px',
								}}>
								Client Details
							</span>
						</div>
						<IconButton
							onClick={() => {
								if (selectedClient) {
									setIsEditMode((prevState) => true);
									setSelectedClientForEdit((prevState) => ({ ...selectedClient }));
									setClientFormDialog((prevState) => !prevState);
								}
							}}
							aria-label='edit'>
							<EditIcon fontSize='small'></EditIcon>
						</IconButton>
					</div>
					<div style={{ display: 'flex', height: '450px', flexDirection: 'column', overflow: 'auto' }}>
						<div style={{ marginTop: '20px', paddingLeft: '30px' }}>
							<div style={{ display: 'flex' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Client Name:</span>
								<span style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', fontWeight: 'bold' }}>
									{selectedClient?.name}
								</span>
							</div>
							<div style={{ display: 'flex', marginTop: '20px' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Client Email:</span>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{selectedClient?.emails
										? selectedClient?.emails.split(',').map((email: string, index) => (
												<span key={index} style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '5px' }}>
													{email}
												</span>
										  ))
										: null}
								</div>
							</div>
							<div style={{ display: 'flex', marginTop: '20px' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Client Address:</span>
								<span style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', fontWeight: 'bold' }}>
									{selectedClient?.address}
								</span>
							</div>
							<div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Client Phone:</span>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{selectedClient?.contactNumbers
										? selectedClient?.contactNumbers.split(',').map((phone: string, index) => (
												<span key={index} style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '5px' }}>
													{phone}
												</span>
										  ))
										: null}
								</div>
							</div>
							<div style={{ display: 'flex', marginTop: '20px' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Contact Person:</span>
								<span style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', fontWeight: 'bold' }}>
									{selectedClient?.contactPerson}
								</span>
							</div>
							<div style={{ display: 'flex', marginTop: '20px' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Invoice Cut-Off Date:</span>
								<span style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', fontWeight: 'bold' }}>
									{selectedClient?.invoiceCutOffDate}
								</span>
							</div>
							<div style={{ display: 'flex', marginTop: '20px' }}>
								<span style={{ width: '180px', textAlign: 'left', fontSize: '15px' }}>Bulk Invoicing Enabled:</span>
								<span style={{ display: 'flex', flexWrap: 'wrap', fontSize: '14px', fontWeight: 'bold' }}>
									{selectedClient?.enableBulkInvoicing ? 'Yes' : 'No'}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const clientDetailsProps: ISegmentProps = {
		header: {
			text: `Name${spaceDelimiter}Email${spaceDelimiter}Phone${spaceDelimiter}Type`,
			icons: [
				{
					icon: EIcon.EDIT,
					iconCb: () => null,
				},
			],
		},
		placeholderText: 'Select a client',
		dataElement: createClientDetailLayout,
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [clientsProps, clientDetailsProps],
	};

	const layoutProps: IDefaultGridLayoutProps = {
		panelOne: <Panel panelProps={panelProps}></Panel>,
	};

	useEffect(() => {
		if (clientsDataModel?.clients.length) {
			if (!selectedClient) {
				setSelectedClient({ ...clientsDataModel?.clients[0] });
			} else {
				const updatedClientInfo = clientsDataModel?.clients.find(
					(client: ClientModel) => client.id === selectedClient.id
				);

				if (updatedClientInfo) {
					setSelectedClient({ ...updatedClientInfo });
				}
			}
		}
	}, [clientsDataModel]);

	return (
		<>
			<Helmet>
				<title>{'Clients'}</title>
			</Helmet>
			<div>
				<DefaultGridLayout layoutProps={layoutProps}></DefaultGridLayout>
				<AddClientDialog
					isEditMode={isEditMode}
					selectedClientForEdit={selectedClientForEdit}
					show={showClientFormDialog}
					close={() => {
						handleClose();
						setSelectedClientForEdit((prevState) => undefined);
						setIsEditMode((prevState) => false);
					}}
					refreshPanel={isEditMode ? refreshPanel : onClientAdded}
				/>
			</div>
			<DeleteConfirmationDialog
				open={showDeleteClientConfirmation}
				onClose={() => setShowDeleteClientConfirmation(false)}
				onConfirm={() => onDeleteClient(clientToDelete)}
				entity={'client'}
			/>
		</>
	);
}
