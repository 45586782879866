export const DEFAULT_LIMIT = 15;

export const getPagingData = (data: any, page: number | undefined, limit: number | undefined) => {
	let totalPages = Math.ceil(data.length / limit);
	let totalItems = data.length;
	let paginatedData = data.slice(page * limit, page * limit + limit);

	return { totalItems, paginatedData, totalPages, page };
};

export function getOffset() {
	const offset = new Date().getTimezoneOffset();
	return offset;
}
