import React, { useMemo, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Layout } from './components/Layout';
import { ThemeModeContext } from './contexts';
import { useAppSelector } from './hooks/redux-hooks';
import { routes, preAuthRoutes } from './config';
import { Route as AppRoute } from './types';
import { getAppTheme } from './styles/theme';
import { DARK_MODE_THEME, LIGHT_MODE_THEME } from './utils/constants';

function App(props: any) {
	const user = useAppSelector((state) => state.user.user);
	const [mode, setMode] = useState<typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME>(LIGHT_MODE_THEME);

	const themeMode = useMemo(
		() => ({
			toggleThemeMode: () => {
				setMode((prevMode) => (prevMode === DARK_MODE_THEME ? LIGHT_MODE_THEME : DARK_MODE_THEME));
			},
		}),
		[]
	);

	const theme = useMemo(() => getAppTheme(mode), [mode]);

	const addRoute = (route: AppRoute) => <Route key={route.key} path={route.path} component={route.component} exact />;

	return (
		<ThemeModeContext.Provider value={themeMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					<Switch>
						{user ? (
							<Layout>
								{routes.map((route: AppRoute) =>
									route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route)
								)}
							</Layout>
						) : (
							preAuthRoutes.map((route: AppRoute) => addRoute(route))
						)}
						<Route path='/*' component={preAuthRoutes[0].component} />
					</Switch>
				</Router>
			</ThemeProvider>
		</ThemeModeContext.Provider>
	);
}

export default App;
