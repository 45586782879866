import React, { Fragment } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, InputAdornment } from '@mui/material';

export interface SummaryPanelItemProps {
	name: string;
	values: Array<string>;
}

export interface SummaryPanelProps {
	containerHeight?: string;
	items: Array<SummaryPanelItemProps> | undefined;
	handleInputChange: (event: any) => void;
}

export const SummaryPanel = (props: { panelProps: SummaryPanelProps }) => {
	return (
		<div
			style={{
				maxHeight: props.panelProps.containerHeight || '100%',
			}}>
			{props.panelProps.items?.map((item: any, index) => {
				return (
					<Fragment key={index}>
						<Grid container columnSpacing={1} alignItems='stretch' height='100%'>
							<Grid xs={6} height='100%'>
								{item?.name}
							</Grid>
							<Grid xs={6} height='100%'>
								{item?.values.map((value: any, index: number) => {
									return (
										<div
											key={index}
											style={{
												paddingBottom: '8px',
											}}>
											<span>{value}</span>
											<TextField
												style={{ marginTop: '2%' }}
												label='Sub Cost'
												InputProps={{
													startAdornment: <InputAdornment position='start'>$</InputAdornment>,
												}}
												name={`${item.subtypeId},${item.valuesId[index]}_purchaseCost`}
												defaultValue={item?.purchaseCostList[index] ? item?.purchaseCostList[index] : 0}
												variant='standard'
												onChange={props.panelProps?.handleInputChange}
												fullWidth
											/>
											<TextField
												style={{ marginTop: '2%' }}
												label='Contract Price'
												InputProps={{
													startAdornment: <InputAdornment position='start'>$</InputAdornment>,
												}}
												name={`${item.subtypeId},${item.valuesId[index]}_sellingPrice`}
												defaultValue={item?.sellingPriceList[index] ? item?.sellingPriceList[index] : 0}
												variant='standard'
												onChange={props.panelProps?.handleInputChange}
												fullWidth
											/>
										</div>
									);
								})}
							</Grid>
						</Grid>
						{index < props.panelProps.items!.length - 1 && <hr style={{ margin: '4px', color: '#D3D3D3' }} />}
					</Fragment>
				);
			})}
		</div>
	);
};
