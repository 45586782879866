import React, { FunctionComponent } from 'react';

import { ProjectStatusType } from '../../models/project.model';
import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { ISegmentProps } from '../../components/Layout/Panel/views/Segment';

export const ProjectInfoPanel = (props: any) => {
	const projectInfoLayout: FunctionComponent = () => {
		return (
			<div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ fontSize: '14px', fontWeight: 'bold' }}>Status: </span>
					<span
						style={{
							fontSize: '12px',
							fontWeight: 'bold',
							marginLeft: '5px',
							padding: '5px',
							borderRadius: '8px',
							backgroundColor:
								ProjectStatusType.InActive === props?.selectedProject?.status
									? '#D32F2F'
									: ProjectStatusType.InProgress === props?.selectedProject?.status
									? '#0277BD'
									: '#43A047',
							color: 'white',
						}}>
						{ProjectStatusType.InActive === props?.selectedProject?.status
							? 'Inactive'
							: ProjectStatusType.InProgress === props?.selectedProject?.status
							? 'In-Progress'
							: 'Completed'}
					</span>
				</div>
				<div style={{ display: 'flex' }}>
					<div
						style={{
							height: '20px',
							width: '20px',
							borderRadius: '5px',
							backgroundColor: '#D32F2F',
							marginRight: '10px',
						}}></div>
					<span style={{ fontSize: '12px', color: 'black', marginRight: '10px' }}>Inactive</span>
					<div
						style={{
							height: '20px',
							width: '20px',
							borderRadius: '5px',
							backgroundColor: '#0277BD',
							marginRight: '10px',
						}}></div>
					<span style={{ fontSize: '12px', color: 'black', marginRight: '10px' }}>In-Progress</span>
					<div
						style={{
							height: '20px',
							width: '20px',
							borderRadius: '5px',
							backgroundColor: '#43A047',
							marginRight: '10px',
						}}></div>
					<span style={{ fontSize: '12px', color: 'black', marginRight: '10px' }}>Completed</span>
				</div>
			</div>
		);
	};

	const projectProps: ISegmentProps = {
		header: {
			text: 'Project Info',
		},
		placeholderText: 'No project selected',
		dataElement: projectInfoLayout,
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [projectProps],
	};

	return (
		<>
			<Panel panelProps={panelProps}></Panel>
		</>
	);
};
