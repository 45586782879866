import { useState, useRef, useEffect } from 'react';
import type { TextFieldProps } from '@mui/material';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress } from '@mui/material';
import * as yup from 'yup';

import {
	addNewSubType,
	addNewSubValue,
	deleteSubType,
	getSubTypeIdByName,
	updateSubType,
	updateSubTypeValue,
} from '../../services/type.service';

export const AddEditNewSubTypeDialog = (props: any) => {
	const [subType, setSubType] = useState(props?.isEditMode ? props?.subtype?.name : '');
	const [subTypeValue, setSubTypeValue] = useState(props?.isEditMode ? props?.subtype?.values[0]?.name : '');
	const [loading, setLoading] = useState<boolean>(false);
	const subTypeRef = useRef<TextFieldProps>(null);
	const subTypeValueRef = useRef<TextFieldProps>(null);
	const subTypeSchema = yup.object().shape({
		typeId: yup.number().required(),
		name: yup.string().required(),
	});

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			typeId: props.typeId,
			name: subTypeRef?.current?.value,
		};

		let subTypeValuePayload = {
			value: subTypeValueRef?.current?.value,
			subtypeId: 0,
		};

		try {
			setLoading(true);
			subTypeSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await addNewSubType('type/subtype/name/add', payload);
					const subTypeId = await getSubTypeIdByName(
						'type/subtype/id/get?typeId=' + payload.typeId + '&name=' + payload.name
					);
					subTypeValuePayload.subtypeId = subTypeId.data?.data?.id;
					const subTypeValueResult: any = await addNewSubValue('type/subtype/value/add', subTypeValuePayload);

					if (result.data.success && subTypeValueResult.data.success) {
						toast.success(result.data.message);
						props.onRefreshPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const onUpdate = async () => {
		if (subTypeRef?.current?.value) {
			let payload = {
				typeId: props.typeId,
				subTypeId: 0,
				name: subTypeRef?.current?.value,
			};
			let subTypeValuePayload = {
				subtypeValueId: props.subtype?.values[0]?.id,
				subtypeId: 0,
				value: subTypeValueRef?.current?.value,
			};
			const subTypeId = await getSubTypeIdByName(
				'type/subtype/id/get?typeId=' + payload.typeId + '&name=' + payload.name
			);
			subTypeValuePayload.subtypeId = subTypeId.data?.data?.id;
			payload.subTypeId = subTypeId.data?.data?.id;

			try {
				setLoading(true);
				const result: any = await updateSubType('type/subtype/name', payload);
				const subTypeValueResult: any = await updateSubTypeValue('type/subtype/value/update', subTypeValuePayload);

				if (result.data.success && subTypeValueResult.data.success) {
					toast.success(result.data.message);
					props.onRefreshPanel();
					onClose();
				} else {
					toast.error(result.data.message);
				}
				setLoading(false);
			} catch (err: any) {
				toast.error(err?.response?.data?.message || err?.message);
				setLoading(false);
			}
		} else {
			toast.error('Please enter value!');
		}
	};

	const isFormValid = () => {
		// Check if text fields are not empty
		return subType.trim() !== '' && subTypeValue.trim() !== '';
	};

	useEffect(() => {
		if (props?.isEditMode && props?.subtype) {
			setSubType(props?.subtype?.name);
			setSubTypeValue(props?.subtype?.values[0]?.name);
		}
	}, [props.isEditMode, props.subtype]);

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props?.isEditMode ? 'Edit' : 'Add'} SubType</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<div>
					<TextField
						style={{ marginTop: '2%' }}
						inputRef={subTypeRef}
						value={subType}
						onChange={(e) => setSubType(e.target.value)}
						label='SubType'
						variant='standard'
						fullWidth
					/>
					<TextField
						style={{ marginTop: '2%' }}
						inputRef={subTypeValueRef}
						value={subTypeValue}
						onChange={(e) => setSubTypeValue(e.target.value)}
						label='SubType Value (E.g. Default)'
						variant='standard'
						fullWidth
					/>
				</div>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							disabled={!isFormValid()}
							onClick={props?.isEditMode ? onUpdate : handleSubmit}>
							{props?.isEditMode ? 'Edit' : 'Add'}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
