import React from 'react';
import { Modal, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const GenerateClaimDialog = ({ open, onClose, onPreview, onConfirm }) => {
	return (
		<Modal open={open} onClose={onClose} closeAfterTransition>
			<div
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 800,
					backgroundColor: 'white',
					boxShadow: '24px',
					padding: 10,
					borderRadius: 3,
				}}>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<IconButton onClick={onClose} style={{ position: 'absolute', top: 5, right: 5 }}>
						<CloseIcon />
					</IconButton>
				</div>
				<div style={{ paddingLeft: 10, paddingRight: 10 }}>
					<h2>Confirmation</h2>
					<div style={{ display: 'flex' }}>
						<p style={{ color: 'orange', marginRight: 5, marginTop: 0 }}>
							Warning: Generating a report claim cannot be reversed. To review the report before finalizing, make use of
							the &quot;Preview&quot; function.
						</p>
					</div>
					<p>Proceed with report generation?</p>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
					<Button style={{ marginRight: 10 }} onClick={onPreview}>
						Preview
					</Button>
					<Button onClick={onConfirm} variant='contained' color='primary'>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default GenerateClaimDialog;
