import React from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Button,
	TextField,
	Autocomplete,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useProjectsList, useTypesForProject } from '../../pages/Project/hooks/projects';
import { ProjectToTypesDataModel, ProjectModel, ProjectToTypesFullDetailsDataModel } from '../../models/project.model';
import { ClientModel } from '../../models/client.model';
import { getClientDetails } from '../../services/client.service';
import { fetchLastGSTValue } from '../../services/common.service';
import { createPayment, fetchClaimRefs } from '../../services/payment.service';
import moment from 'moment';
import { getOffset } from '../../utils/utilityFunctions';

const GenerateTextLine = (props: any) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
			<span style={{ fontSize: '14px', color: props.keyColor ? props.keyColor : 'black', width: '220px' }}>
				{props?.keyText}
			</span>
			{props?.valueText && (
				<span
					style={{
						marginLeft: '6px',
						fontSize: '12px',
						color: props.valueColor ? props.valueColor : 'black',
						fontWeight: 'bold',
					}}>
					{props?.valueText}
				</span>
			)}
			{props?.valuesText && (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{props?.valuesText.split(',').map((value: string, index: number) => (
						<span
							key={index}
							style={{
								marginLeft: '6px',
								fontSize: '12px',
								color: props.valueColor ? props.valueColor : 'black',
								fontWeight: 'bold',
								marginBottom: '5px',
							}}>
							{value}
						</span>
					))}
				</Box>
			)}
		</Box>
	);
};

const paymentSchema = yup.object().shape({
	projectId: yup.number().required(),
	clientId: yup.number().required(),
	gst: yup.number().test('greaterThanZero', 'GST must be greater than zero', (value) => (value ? value > 0 : false)),
	amount: yup
		.number()
		.test('greaterThanZero', 'Amount must be greater than zero', (value) => (value ? value > 0 : false)),
	date: yup.string().required(),
});

export const AddPaymentDialog = (props: any) => {
	const [gstValue, setGSTValue] = React.useState<number>(7);
	const [amount, setAmount] = React.useState<number>(0);
	const [claimRefValue, setClaimRefValue] = React.useState<string>('');
	const [claimRefInputValue, setClaimRefInputValue] = React.useState('');
	const [claimRefsList, setClaimRefsList] = React.useState<Array<string>>([]);
	const [date, setDate] = React.useState<any | null>(moment());
	const [selectedProjectId, setSelectedProjectId] = React.useState<number>();
	const [selectedClientId, setSelectedClientId] = React.useState<number>();
	const [selectedClientDetails, setSelectedClientDetails] = React.useState<ClientModel>();
	const [paymentRefNumber, setPaymentRefNumber] = React.useState<number>();
	const [toggleForReload, setToggleForReload] = React.useState<boolean>(false);
	const [pendingAmount, setPendingAmount] = React.useState<number>(0);
	const [showWarningDialog, setShowWarningDialog] = React.useState<boolean>(false);

	const onRefresh = () => {
		setToggleForReload(!toggleForReload);
	};

	const loadGSTValue = async () => {
		try {
			const result = await fetchLastGSTValue();

			if (result?.success) {
				setGSTValue(result?.data?.value);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	const handleDateChange = (newValue: any | null) => {
		setDate(newValue);
	};

	const onClose = () => {
		setSelectedProjectId(undefined);
		setSelectedClientId(undefined);
		setSelectedClientDetails(undefined);
		setClaimRefValue('');
		setClaimRefInputValue('');
		setAmount(0);
		setPendingAmount(0);
		props.close();
	};

	const projectsList = useProjectsList(true);
	const projectToTypesList = useTypesForProject(selectedProjectId, toggleForReload);
	let uniqueArray;
	if (projectToTypesList && projectToTypesList?.length > 0) {
		uniqueArray = projectToTypesList.reduce((acc: any, obj: ProjectToTypesFullDetailsDataModel) => {
			if (!acc.some((item: any) => item.clientId === obj.clientId)) {
				acc.push(obj);
			}
			return acc;
		}, []);
	}
	const getSelectedClientDetails = async () => {
		if (selectedClientId) {
			const clientResult: any = await getClientDetails(selectedClientId);

			if (clientResult?.success) {
				setSelectedClientDetails({ ...clientResult?.data });
			}
		}
	};

	const getClaimRefs = async () => {
		if (selectedProjectId && selectedClientId) {
			const result = await fetchClaimRefs(selectedProjectId, selectedClientId);

			if (result.data?.data?.length) {
				const list = result.data?.data.map((claim: any) => claim?.claimNumber);
				setClaimRefsList(list);
				setClaimRefValue(list[list.length - 1]);
				setPendingAmount(
					result.data.data[result.data.data.length - 1].claimAmount -
						result.data.data[result.data.data.length - 1].paymentReceived
				);
				setGSTValue(result.data.data[result.data.data.length - 1].gstAmount);
			}
		}
	};

	const submitPayment = async () => {
		if (selectedProjectId && selectedClientId && amount) {
			try {
				let payload = {
					paymentRefNum: paymentRefNumber,
					projectId: selectedProjectId,
					clientId: selectedClientId,
					claimRef: claimRefInputValue,
					date: moment(date).utcOffset(getOffset() * -1),
					amount: amount,
					gst: gstValue,
				};

				paymentSchema
					.validate(payload)
					.then(async (value) => {
						const result: any = await createPayment(payload);

						if (result.success) {
							toast.success('Payment created successfully');
							onClose();
						} else {
							toast.error(result.message);
						}
					})
					.catch((err: any) => {
						toast.error(err?.response?.data?.message || err?.message);
					});
			} catch (err: any) {
				toast.error(err?.response?.data?.message || err?.message);
			}
		} else {
			if (selectedProjectId && !selectedClientId) {
				toast.error('Please select a client');
			} else if (selectedProjectId && selectedClientId && (!amount || amount === 0)) {
				toast.error('Please enter a valid amount');
			} else {
				toast.error('Please select project and client');
			}
		}
	};

	React.useEffect(() => {
		if (selectedClientId) {
			getSelectedClientDetails();
			getClaimRefs();
		}
	}, [selectedClientId]);

	React.useEffect(() => {
		loadGSTValue();
	}, [props.show]);

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'md'}
			open={props.show}
			onClose={onClose}
			aria-labelledby='customized-dialog-title'>
			<Dialog
				fullWidth={false}
				maxWidth={'md'}
				open={showWarningDialog}
				onClose={() => setShowWarningDialog(false)}
				aria-labelledby='customized-dialog-title'>
				<DialogTitle>Amount mismatch</DialogTitle>
				<DialogContent>
					<Box sx={{ marginBottom: '20px' }}>
						<span style={{ fontSize: '14px', color: amount > pendingAmount ? 'green' : 'red', width: '220px' }}>
							The entered amount is {amount > pendingAmount ? 'greater' : 'less'} than the pending amount! Do you wish
							to continue?
						</span>
					</Box>
					<DialogActions>
						<>
							<Button
								variant='outlined'
								size='small'
								style={{ marginLeft: '20px' }}
								onClick={() => setShowWarningDialog(false)}>
								Cancel
							</Button>
							<Button
								type='button'
								size='small'
								variant='contained'
								onClick={() => (setShowWarningDialog(false), submitPayment())}>
								Confirm
							</Button>
						</>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<DialogTitle>Create Payment</DialogTitle>
			<DialogContent>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
					<TextField
						label='Payment Ref Number'
						variant='standard'
						style={{ width: '200px' }}
						value={paymentRefNumber}
						defaultValue={paymentRefNumber}
						onChange={(event: any) => setPaymentRefNumber(event.target.value)}
					/>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							label='Date'
							inputFormat='DD/MM/YYYY'
							value={date}
							onChange={handleDateChange}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
					<FormControl style={{ height: '50px' }}>
						<InputLabel>{'Project'}</InputLabel>
						<Select
							label='Project'
							style={{ width: '250px' }}
							onChange={(e: any) => {
								setSelectedProjectId(e.target.value);
								setSelectedClientId(undefined);
								setSelectedClientDetails(undefined);
								onRefresh();
							}}>
							{projectsList?.length &&
								projectsList.map((projectInfo: ProjectModel, index: number) => (
									<MenuItem key={index} value={projectInfo?.id}>
										{projectInfo?.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl style={{ height: '50px' }}>
						<InputLabel>{'Client'}</InputLabel>
						<Select
							label='Client'
							style={{ width: '250px' }}
							variant='outlined'
							onChange={(e: any) => {
								setSelectedClientId(e.target.value);
							}}
							disabled={!selectedProjectId ? true : false}>
							{uniqueArray?.length &&
								uniqueArray.map((projectToTypeInfo: ProjectToTypesDataModel, index: number) => (
									<MenuItem key={index} value={`${projectToTypeInfo?.clientId}`}>
										{projectToTypeInfo?.clientName}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl style={{ height: '50px' }}>
						<Autocomplete
							value={claimRefValue}
							onChange={(event: any, newValue) => {
								if (newValue) setClaimRefValue(newValue);
							}}
							inputValue={claimRefInputValue}
							onInputChange={(event, newInputValue) => {
								setClaimRefInputValue(newInputValue);
							}}
							options={claimRefsList}
							sx={{ width: 300 }}
							renderInput={(params) => <TextField {...params} label='Claim Ref' />}
							disabled={true}
						/>
					</FormControl>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '30px' }}>
					<TextField
						sx={{ width: '40%' }}
						label='GST Value'
						type='number'
						inputProps={{ min: 1 }}
						value={gstValue}
						onChange={(e) => setGSTValue(parseInt(e.target.value))}
						disabled={true}
					/>
					<Box
						sx={{
							display: 'flow',
							width: '40%',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginTop: '30px',
						}}>
						<TextField
							sx={{ width: '100%' }}
							label='Amount'
							inputProps={{ min: 1 }}
							value={amount}
							onChange={(e) => !Number.isNaN(Number(e.target.value)) && setAmount(Number(e.target.value))}
						/>
						<GenerateTextLine
							keyText={'Pending Amount:'}
							valueText={pendingAmount - amount}
							keyColor={amount - pendingAmount < 0 ? 'red' : amount - pendingAmount > 0 ? 'green' : 'black'}
							valueColor={amount - pendingAmount < 0 ? 'red' : amount - pendingAmount > 0 ? 'green' : 'black'}
						/>
					</Box>
				</Box>
				<Box sx={{ marginTop: '40px', display: 'flex', alignItems: 'end' }}>
					<Box>
						<GenerateTextLine
							keyText={'CLIENT:'}
							valueText={selectedClientDetails?.name ? selectedClientDetails?.name : undefined}
						/>
						<GenerateTextLine
							keyText={'ADDRESS:'}
							valueText={selectedClientDetails?.address ? selectedClientDetails?.address : undefined}
						/>
						<GenerateTextLine
							keyText={'TEL NO:'}
							valuesText={selectedClientDetails?.contactNumbers ? selectedClientDetails?.contactNumbers : undefined}
						/>
						<GenerateTextLine
							keyText={'CONTACT PERSON:'}
							valueText={selectedClientDetails?.contactPerson ? selectedClientDetails?.contactPerson : undefined}
						/>
						<GenerateTextLine
							keyText={'EMAIL:'}
							valuesText={selectedClientDetails?.emails ? selectedClientDetails?.emails : undefined}
						/>
					</Box>
				</Box>
				<hr />
				<Box style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
					<Box>
						<GenerateTextLine keyText={'TOTAL:'} valueText={amount + ' $'} />
						<GenerateTextLine
							keyText={`ADD ${gstValue}% GST:`}
							valueText={(amount * (gstValue / 100)).toFixed(2) + ' $'}
						/>
						<GenerateTextLine
							keyText={'TOTAL INCL GST:'}
							valueText={(amount + amount * (gstValue / 100)).toFixed(2) + ' $'}
						/>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<>
					<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
						Cancel
					</Button>
					<Button
						type='submit'
						size='small'
						variant='contained'
						onClick={() => (amount !== pendingAmount ? setShowWarningDialog(true) : submitPayment())}>
						Create
					</Button>
				</>
			</DialogActions>
		</Dialog>
	);
};
