import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { deleteType, fetchAllTypes } from '../../services/type.service';
import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { TypesToProjectDataModel, TypeDataModel } from '../../models/types.model';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { ISegmentProps } from '../../components/Layout/Panel/views/Segment';
import { IDataProps } from '../../components/Layout/Panel/views/components/SegmentBody';
import { AddEditTypeDialog } from '../../components/Dialog/AddEditTypeDialog';
import { DEFAULT_LIMIT, getPagingData } from '../../utils/utilityFunctions';
import DeleteConfirmationDialog from '../../components/Dialog/DeleteConfirmationDialog';

export function TypesPanel(props: any) {
	const DEFAULT_PAGE_SIZE = DEFAULT_LIMIT;
	const [showAddTypeDialog, setShowAddTypeDialog] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [typesData, setTypesData] = useState<TypesToProjectDataModel>();
	const [selectedType, setSelectedType] = useState<TypeDataModel>();
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selectedTypeForEdit, setSelectedTypeForEdit] = useState<TypeDataModel>();
	const [currentPage, setCurrentPage] = useState<number>(0);
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);
	const [allTypesData, setAllTypesData] = useState<TypeDataModel[]>([]); // New state for all types data

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

	const onTypeAdded = () => {
		// Either one of the state change will refresh the page
		if (typesData!.types.length === DEFAULT_PAGE_SIZE) {
			// We want to move to the next page if current page is already full
			setPage((prevState) => prevState + 1);
		} else {
			// Purely for refreshing the page
			setToggleForReload(!toggleForReload);
		}
	};

	const onDeleteType = async () => {
		try {
			const result: any = await deleteType(selectedTypeForEdit?.id);

			if (result?.success) {
				toast.success(result.message);
				getAllTypes();
			} else {
				toast.error(result.message);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
		setSelectedTypeForEdit(null);
		setShowDeleteConfirmation(false);
	};

	const fetchAndStoreAllTypes = async () => {
		try {
			const result: any = await fetchAllTypes(`type/getAll`);
			if (result.data.success) {
				const typesDataModel: TypesToProjectDataModel = result.data.data;
				setAllTypesData(typesDataModel.types); // Store the complete data
				return typesDataModel.types;
			} else {
				toast.error(result.data.message);
				return [];
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			return [];
		}
	};

	const getAllTypes = async () => {
		let typesToSort: TypeDataModel[] = allTypesData;

		if (!allTypesData.length) {
			typesToSort = await fetchAndStoreAllTypes();
		}

		if (typesToSort.length) {
			let manDayIndex = typesToSort.findIndex((item: TypeDataModel) => item.name === 'Man-day');
			if (manDayIndex !== -1) {
				let element = typesToSort.splice(manDayIndex, 1);
				typesToSort.sort((a, b) => a.name.localeCompare(b.name));
				typesToSort.unshift(element[0]);
			}

			const pagingData = getPagingData(typesToSort, page, DEFAULT_PAGE_SIZE);
			setSelectedType({ ...pagingData.paginatedData[0] });
			props.onTypeSelection({ ...pagingData.paginatedData[0] });

			let modifiedData = {
				currentPage: page,
				totalPages: pagingData.totalPages,
				totalItems: pagingData.totalItems,
				types: pagingData.paginatedData,
			};

			setCurrentPage(modifiedData.currentPage);
			setTypesData(modifiedData);
		}
	};

	const getTypes = (): Array<IDataProps> => {
		let result: Array<IDataProps> = [];

		if (typesData?.types) {
			for (let i = 0; i < typesData.types.length; i++) {
				result.push({
					text: typesData.types[i].name,
					textClickCb: () => {
						let info = { ...typesData.types[i] };
						setSelectedType((prevState) => ({ ...info }));
						props.onTypeSelection({ ...info });
					},
					isTextSelected: selectedType?.id === typesData.types[i]?.id,
					icons: [
						{
							icon: EIcon.EDIT,
							iconCb: () => {
								let info = { ...typesData.types[i] };
								setIsEditMode((prevState) => !prevState);
								setSelectedTypeForEdit((prevState) => ({ ...info }));
								setShowAddTypeDialog((prevState) => !prevState);
							},
						},
						{
							icon: EIcon.DELETE,
							iconCb: () => {
								let info = { ...typesData.types[i] };
								setSelectedTypeForEdit((prevState) => ({ ...info }));
								setShowDeleteConfirmation(true);
							},
						},
					],
				});
			}
		}

		return result;
	};

	const typesProps: ISegmentProps = {
		header: {
			text: 'Type',
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						setShowAddTypeDialog((prevState) => !prevState);
					},
				},
			],
		},
		placeholderText: 'No types created',
		data: getTypes(),
		pagination: {
			currentPage: page,
			totalPages: typesData?.totalPages as number,
			gotoNextPageCb: () => {
				if (typesData) {
					const { totalPages, currentPage } = typesData;

					if (currentPage < totalPages - 1) {
						setPage((prevState) => prevState + 1);
					}
				}
			},
			gotoPrevPageCb: () => {
				if (typesData) {
					const { totalPages, currentPage } = typesData;

					if (currentPage > 0) {
						setPage((prevState) => prevState - 1);
					}
				}
			},
		},
	};

	const typesPanelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [typesProps],
	};

	useEffect(() => {
		getAllTypes();
	}, [page, toggleForReload]);

	return (
		<>
			<Panel panelProps={typesPanelProps}></Panel>
			<AddEditTypeDialog
				isEditMode={isEditMode}
				selectedTypeForEdit={selectedTypeForEdit}
				show={showAddTypeDialog}
				close={() => {
					setShowAddTypeDialog(false);
					setIsEditMode(false);
					setSelectedTypeForEdit(undefined);
				}}
				onRefresh={() => setToggleForReload(!toggleForReload)}
				onAdded={onTypeAdded}
			/>
			<DeleteConfirmationDialog
				open={showDeleteConfirmation}
				onClose={() => setShowDeleteConfirmation(false)}
				onConfirm={() => onDeleteType()}
				entity={'type'}
			/>
		</>
	);
}
