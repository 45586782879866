import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { SettingsChoicesDialog } from '../Dialog/SettingsChoicesDialog';
import { Hamburger } from './Hamburger';
import { AppTitle } from './AppTitle';
import { More } from '../Actions';
import { DefaultMenu, MobileMenu } from './Menu';

interface HeaderProps {
	toggleNavigation: () => void;
}

export const Header = ({ toggleNavigation }: HeaderProps) => {
	const [openSettingDialog, setOpenSettingDialog] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMobileMoreAnchorEl(event.currentTarget);

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	return (
		<>
			<AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar disableGutters variant='dense'>
					<Hamburger toggleNavigation={toggleNavigation} />
					<AppTitle />
					<Box sx={{ flexGrow: 1 }} />
					<IconButton onClick={() => setOpenSettingDialog(!openSettingDialog)}>
						<SettingsIcon style={{ color: 'white' }} />
					</IconButton>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<More onClick={handleMobileMenuOpen} />
					</Box>
				</Toolbar>
			</AppBar>
			<MobileMenu
				isMenuOpen={Boolean(mobileMoreAnchorEl)}
				handleMenuOpen={handleMobileMenuOpen}
				handleMenuClose={handleMobileMenuClose}
				anchorEl={mobileMoreAnchorEl}
			/>
			<DefaultMenu isMenuOpen={Boolean(anchorEl)} handleMenuClose={handleMenuClose} anchorEl={anchorEl} />
			<SettingsChoicesDialog show={openSettingDialog} onClose={() => setOpenSettingDialog(false)} />
		</>
	);
};
