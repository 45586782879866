import axios from 'axios';

console.log('process.env.APIS_BASE_URL', process.env.REACT_APP_API_BASE_URL);

const protocol = location.protocol;
const slashes = protocol.concat('//');
const host = process.env.REACT_APP_API_BASE_URL
	? process.env.REACT_APP_API_BASE_URL
	: slashes.concat(window.location.host);

export default axios.create({
	baseURL: host + '/api/v1/',
});
