import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import type { TextFieldProps } from '@mui/material';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	CircularProgress,
} from '@mui/material';
import { updatePrecinct, deletePrecinct } from '../../services/precinct.service';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from 'yup';

import { addPrecinct } from '../../services/precinct.service';

export const AddEditPrecinctDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const precinctNameRef = useRef<TextFieldProps>(null);
	const precinctLocationRef = useRef<TextFieldProps>(null);
	const precinctSchema = yup.object().shape({
		projectId: yup.number().required(),
		name: yup.string().required(),
		location: yup.string().required(),
	});
	const updatePrecinctSchema = yup.object().shape({
		precinctId: yup.number().required(),
		name: yup.string().required(),
		location: yup.string().required(),
	});

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			projectId: props.selectedProjectId,
			name: precinctNameRef?.current?.value,
			location: precinctLocationRef?.current?.value,
		};

		try {
			setLoading(true);
			precinctSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await addPrecinct('precinct/add', payload);

					if (result.success) {
						toast.success(result.message);
						props.onRefreshPanel();
						onClose();
					} else {
						toast.error(result.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const handleUpdate = async () => {
		let payload = {
			precinctId: props.selectedPrecinct.id,
			name: precinctNameRef?.current?.value,
			location: precinctLocationRef?.current?.value,
		};

		try {
			setLoading(true);
			updatePrecinctSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await updatePrecinct(payload);

					if (result.success) {
						toast.success(result.message);
						props.onRefreshPanel();
						onClose();
					} else {
						toast.error(result.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const onDeletePrecinct = async () => {
		try {
			const result: any = await deletePrecinct(props.selectedPrecinct.id);

			if (result?.success) {
				toast.success(result.message);
				props.onRefresh();
				onClose();
			} else {
				toast.error(result.message);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props?.isEditMode ? 'Edit' : 'Add'} Precinct</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<Box>
					<TextField
						style={{ marginTop: '2%' }}
						label='Precinct Name'
						variant='standard'
						defaultValue={props?.isEditMode ? props?.selectedPrecinct?.name : null}
						inputRef={precinctNameRef}
						fullWidth
					/>
				</Box>
				<Box style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						label='Precinct Location'
						variant='standard'
						defaultValue={props?.isEditMode ? props?.selectedPrecinct?.location : null}
						inputRef={precinctLocationRef}
						fullWidth
					/>
				</Box>
				{props?.isEditMode ? (
					<Box>
						<IconButton
							style={{ padding: '0px' }}
							color='primary'
							aria-label='add more'
							component='label'
							onClick={onDeletePrecinct}>
							<DeleteIcon style={{ fontSize: '14px', color: 'red' }}></DeleteIcon>
							<span style={{ fontSize: '14px', color: 'red' }}>Remove</span>
						</IconButton>
					</Box>
				) : null}
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							onClick={props?.isEditMode ? handleUpdate : handleSubmit}>
							Add
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
