import axios from './api';

export const addPrecinct = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response.data;
};

export const fetchAllPrecincts = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const updatePrecinct = async (payload: any) => {
	const response = await axios.put('precinct/update', payload);

	return response.data;
};

export const deletePrecinct = async (precinctId: number) => {
	const response = await axios.delete('precinct/delete?precinctId=' + precinctId);

	return response.data;
};
