import React, { useEffect } from 'react';
import type { GridAlignment } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { fetchWorkerWorkDone } from '../../services/workeranalytics.service';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/system';
import { downloadAnalytics } from '../../services/project.service';
import { getOffset } from '../../utils/utilityFunctions';

const StyledIconButton = styled(IconButton, { name: 'StyledIconButton', slot: 'Wrapper' })({
	color: 'gray',
	'&:hover': {
		color: 'blue',
	},
});

export function ManageWorkerAnalytics(props: any) {
	const {
		loadDataToGrid,
		page,
		setPage,
		pageSize,
		setPageSize,
		fromDate,
		toDate,
		loading,
		setLoading,
		workerAnalyticsData,
		workerAnalyticsTotalLength,
	} = props;

	const columns = [
		{
			field: 'workerName',
			headerName: `Worker's Name`,
			flex: 1,
			align: 'left' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'workDoneEntriesCount',
			headerName: 'No. of Entries',
			flex: 1,
			align: 'right' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'salary',
			headerName: 'Salary',
			flex: 1,
			align: 'right' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'expenses',
			headerName: 'Expenses',
			flex: 1,
			align: 'right' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'revenue',
			headerName: 'Revenue',
			flex: 1,
			align: 'right' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'profits',
			headerName: 'Profit/Loss',
			flex: 1,
			align: 'right' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			renderCell: (params: any) => {
				const profit = params.row.revenue - (params.row.expenses || 0);
				const style: React.CSSProperties = {
					color: profit >= 0 ? 'green' : 'red',
				};
				return <span style={style}>{profit}</span>;
			},
		},
		{
			field: 'download',
			headerName: 'Download',
			flex: 0.5,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			renderCell: (params: any) => (
				<div style={{ marginLeft: '91px' }}>
					<StyledIconButton onClick={() => download(params.row.id, params.row.workerName)}>
						<DownloadIcon></DownloadIcon>
					</StyledIconButton>
					{params.value}
				</div>
			),
		},
	];

	useEffect(() => {
		loadDataToGrid(page, pageSize);
	}, [page]);

	const handlePageChange = (pageNumber: any) => {
		setPage(pageNumber);
		loadDataToGrid(pageNumber, pageSize);
	};

	const handlePageSizeChange = (size: number) => {
		setPageSize(size);
		// Reload data using the new page size
		loadDataToGrid(page, size);
	};

	const styles = {
		root: {
			'& .MuiDataGrid-cell': {
				paddingLeft: '30px',
				paddingRight: '9%',
			},
		},
	};

	//Function to handle downloading the report
	const download = async (id: number, workerName: string) => {
		const result: any = await fetchWorkerWorkDone(
			id,
			moment(fromDate)
				.startOf('day')
				.utcOffset(getOffset() * -1)
				.format('YYYY-MM-DD HH:mm:ss'),
			moment(toDate)
				.startOf('day')
				.utcOffset(getOffset() * -1)
				.format('YYYY-MM-DD HH:mm:ss')
		);
		const uniqueWorkerDetails = result.data?.data;
		if (fromDate && toDate) {
			const excel = await downloadAnalytics(
				id,
				uniqueWorkerDetails,
				moment(toDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
				moment(fromDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss')
			);
			setLoading(true);

			if (excel.status === 200) {
				const a = document.createElement('a');
				a.href = URL.createObjectURL(new Blob([excel.data], { type: 'application/xlsx' }));
				a.download = `${workerName}-WorkDone-${moment(fromDate).format('DDMMYY')}-${moment(toDate).format(
					'DDMMYY'
				)}.xlsx`;
				a.click();
			}
		}
		setLoading(false);
	};

	return (
		<Box sx={{ flex: 1, height: '90%' }}>
			<Box sx={{ height: '630.5px', marginBottom: '20px' }}>
				<DataGrid
					rows={workerAnalyticsData}
					columns={columns}
					loading={loading}
					rowCount={workerAnalyticsTotalLength}
					pagination={true}
					paginationMode='server'
					pageSize={pageSize}
					rowsPerPageOptions={[10, 20, 50, 100]}
					page={page}
					onPageSizeChange={handlePageSizeChange}
					onPageChange={handlePageChange}
					localeText={{
						noRowsLabel: fromDate === '' || toDate === '' ? 'Select A Date' : 'There is no data during this date range',
					}}
					sx={styles.root}
				/>
			</Box>
		</Box>
	);
}
