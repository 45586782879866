import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@mui/material';
import * as yup from 'yup';

import { EditParams } from '../../pages/WorkDone/ManageWorkDone';

interface EditWorkDoneProps {
	show: boolean;
	params: EditParams;
	onSubmit: (id: number, level: number, unit: number) => void;
	onClose: () => void;
}

export const EditWorkDoneDialog = (props: EditWorkDoneProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [levelList, setLevelList] = useState<number[]>([]);
	const [level, setLevel] = useState<number>(0);
	const [unitList, setUnitList] = useState<number[]>([]);
	const [unit, setUnit] = useState<number>(0);

	// Clear all state values on cancel
	const onClose = () => {
		setLevelList([]);
		setUnitList([]);
		setLevel(0);
		setUnit(0);
		setLoading(false);
		props.onClose();
	};

	// Generate list of levels from levelTo value received
	const generateLevelsList = () => {
		let levelsList: any = [];

		for (let i = 0; i < props.params.levelTo; i++) {
			levelsList.push(i + 1);
		}

		setLevelList(levelsList);
	};

	// Generate list of units from unitFrom and unitTo values received
	const generateUnitsList = () => {
		let unitsList: any = [];

		for (let i = props.params.unitFrom; i <= props.params.unitTo; i += 2) {
			unitsList.push(i);
		}

		setUnitList(unitsList);
	};

	// Validate the received values and set state
	useEffect(() => {
		if (Object.values(props).every((prop) => prop !== null && prop !== undefined && prop !== false)) {
			setLevel(props.params.level);
			setUnit(props.params.unit);
			if (levelList.length === 0) {
				generateLevelsList();
			}
			if (unitList.length === 0) {
				generateUnitsList();
			}
		}
	}, [props.show, levelList, unitList]);

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>Edit Work Done Entry: </DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<div>
					<FormControl fullWidth sx={{ width: '300px', height: '50px', marginTop: '10px' }}>
						<InputLabel>{'Level'}</InputLabel>
						<Select onChange={(e: any) => setLevel(e.target.value)} label='Level' variant='outlined' value={level}>
							{levelList.map((item, i) => (
								<MenuItem key={i} value={item}>
									{item}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div>
					<FormControl fullWidth sx={{ width: '300px', height: '50px', marginTop: '40px' }}>
						<InputLabel>{'Unit'}</InputLabel>
						<Select onChange={(e: any) => setUnit(e.target.value)} label='Unit' variant='outlined' value={unit}>
							{unitList.map((item, i) => (
								<MenuItem key={i} value={item}>
									{item}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							onClick={() => props.onSubmit(props.params.id, level, unit)}>
							Update
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
