import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const COLORS = [
	'#1f77b4',
	'#ff7f0e',
	'#2ca02c',
	'#d62728',
	'#9467bd',
	'#8c564b',
	'#e377c2',
	'#7f7f7f',
	'#bcbd22',
	'#17becf',
];

const ProjectAnalyticsPieChart = ({ data }) => {
	const processedData = () => {
		const sortedData = data.sort((a, b) => b.value - a.value);
		const totalValue = sortedData.reduce((acc, cur) => acc + cur.value, 0);
		let accumulatedValue = 0;
		let others = { name: 'Others', value: 0 };
		let topData = [];

		for (let i = 0; i < sortedData.length; i++) {
			if (accumulatedValue / totalValue < 0.9) {
				topData.push(sortedData[i]);
				accumulatedValue += sortedData[i].value;
			} else {
				others.value += sortedData[i].value;
			}
		}

		if (others.value > 0) {
			topData.push(others);
		}

		return topData;
	};

	const chartData = processedData();

	return (
		<ResponsiveContainer width='100%' height={450}>
			<PieChart>
				<Pie
					data={chartData}
					dataKey='value'
					cx='50%'
					cy='50%'
					outerRadius={70}
					fill='#8884d8'
					label={({ name, value }) => `$${value.toLocaleString()}`}>
					{chartData.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
				<Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
				<Legend
					layout='vertical'
					verticalAlign='bottom'
					align='center'
					scale={'50%'}
					formatter={(value, entry, index) => {
						const percent = (entry.payload.value / chartData.reduce((acc, cur) => acc + cur.value, 0)) * 100;
						return `${value} (${percent.toFixed(2)}%)`;
					}}
				/>
			</PieChart>
		</ResponsiveContainer>
	);
};

export default ProjectAnalyticsPieChart;
