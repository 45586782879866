import React from 'react';
import { Box } from '@mui/material';
import { Icon, EIcon, IIconProps } from './Icon';

export const spaceDelimiter: string = ';';

export interface ITextLineProps {
	inputText: string;
	textClickCb?: () => void;
	isTextSelected?: boolean;
	icons?: IIconProps[];
}

// Util method to split inputText using delimiter and
// space them out equally along with an iconButton at the end
export const TextLine = (props: ITextLineProps) => {
	const splittedText = props.inputText.split(spaceDelimiter);
	const width = 90 / splittedText.length;

	return (
		<Box
			className='panelSegmentRow'
			style={{
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				backgroundColor: props.isTextSelected ? 'rgba(25, 118, 210, 0.08)' : '',
			}}>
			<div
				onClick={props.textClickCb}
				role='presentation'
				style={{
					cursor: props.textClickCb ? 'pointer' : 'auto',
					color: props.textClickCb ? '#1976d2' : 'black',
					display: 'flex',
					flexDirection: 'row',
					flexGrow: 1,
					width: 'inherit',
					padding: '4px',
					paddingRight: props.icons ? '8px' : '4px',
				}}>
				{splittedText.map((text, index) => {
					return (
						<div
							key={index}
							style={{
								width: `${width}%`,
								color: 'black',
							}}>
							{text}
						</div>
					);
				})}
			</div>
			{splittedText?.length > 0 &&
				props.icons?.map((data: IIconProps, index: number) => {
					return data &&
						!(splittedText[0] === 'Man-day' && (data.icon === EIcon.EDIT || data.icon === EIcon.DELETE)) ? (
						<Icon
							key={index}
							inputText={data.inputText}
							icon={data.icon}
							iconCb={data.iconCb}
							isIconChecked={data.isIconChecked}
						/>
					) : null;
				})}
		</Box>
	);
};
