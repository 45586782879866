import userSlice from './user-slice';
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserModel } from '../../models/user-redux.model';

export const UserActions = userSlice.actions;

export const updateUser = (payload: UserModel): ThunkAction<void, RootState, unknown, AnyAction> => {
	return async (dispatch, getState) => {
		dispatch(UserActions.setUser(payload));
	};
};

export const logoutUser = (): ThunkAction<void, RootState, unknown, AnyAction> => {
	return async (dispatch, getState) => {
		dispatch(UserActions.resetUser(undefined));
	};
};
