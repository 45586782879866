import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { DefaultGridLayout, IDefaultGridLayoutProps } from '../../components/Layout/';
import { TypeDataModel } from '../../models/types.model';
import { TypesPanel } from './TypesPanel';
import { SubTypesPanel } from './SubTypesPanel';

export function ManageType(props: any) {
	const [selectedType, setSelectedType] = useState<TypeDataModel>();

	const layoutProps: IDefaultGridLayoutProps = {
		panelOne: (
			<TypesPanel
				onTypeSelection={(type: TypeDataModel) => {
					setSelectedType((prevState) => ({ ...type }));
				}}></TypesPanel>
		),
		panelTwo: <SubTypesPanel selectedType={selectedType}></SubTypesPanel>,
	};

	return (
		<>
			<Helmet>
				<title>{'Manage Type'}</title>
			</Helmet>
			<div>
				<DefaultGridLayout layoutProps={layoutProps}></DefaultGridLayout>
			</div>
		</>
	);
}
