import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import type { TextFieldProps } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress } from '@mui/material';
import * as yup from 'yup';

import { addNewSubValue } from '../../services/type.service';

export const AddNewSubValueDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const subValueRef = useRef<TextFieldProps>(null);
	const subValueSchema = yup.object().shape({
		subtypeId: yup.number().required(),
		value: yup.string().required(),
	});

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			subtypeId: props.subtype.id,
			value: subValueRef?.current?.value,
		};

		try {
			setLoading(true);
			subValueSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await addNewSubValue('type/subtype/value/add', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.onRefreshPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>Add Value To {props?.subtype?.name}</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<div>
					<TextField style={{ marginTop: '2%' }} inputRef={subValueRef} label='Value' variant='standard' fullWidth />
				</div>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button type='submit' size='small' variant='contained' onClick={handleSubmit}>
							Add
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
