import { Home as HomeIcon, ListAlt as ListIcon } from '@mui/icons-material';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import HardwareIcon from '@mui/icons-material/Hardware';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { ManageProjects } from '../pages/Project/ManageProjects';
import { ManageUser } from '../pages/User/ManageUser';
import { ManageType } from '../pages/Type/ManageType';
import { ManageClients } from '../pages/Client/ManageClients';
import { ManageReport } from '../pages/Report/ManageReport';
import { ManagePayments } from '../pages/Payment/ManagePayment';
import { ManageInvoices } from '../pages/Invoice/ManageInvoice';
import { ManageWorkDone } from '../pages/WorkDone/ManageWorkDone';
import WorkerAnalytics from '../pages/WorkerAnalytics';
import { Route } from '../types/Route';

export const preAuthRoutes: Array<Route> = [
	{
		key: 'router-login',
		title: 'Login',
		description: 'Login',
		component: Login,
		path: '/',
	},
];

export const routes: Array<Route> = [
	{
		key: 'router-home',
		title: 'Home',
		description: 'Home',
		component: Home,
		path: '/',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
	},
	{
		key: 'router-users',
		title: 'Users',
		description: 'Users List',
		component: ManageUser,
		path: '/users',
		isEnabled: true,
		icon: EngineeringIcon,
	},
	{
		key: 'router-clients',
		title: 'Clients',
		description: 'Clients List',
		component: ManageClients,
		path: '/clients',
		isEnabled: true,
		icon: PeopleOutlineIcon,
	},
	{
		key: 'router-types',
		title: 'Types',
		description: 'Types List',
		component: ManageType,
		path: '/types',
		isEnabled: true,
		icon: ListIcon,
	},
	{
		key: 'router-projects',
		title: 'Projects',
		description: 'Projects',
		component: ManageProjects,
		path: '/project',
		isEnabled: true,
		icon: HomeRepairServiceIcon,
	},
	{
		key: 'router-report',
		title: 'Report',
		description: 'Excel Report',
		component: ManageReport,
		path: '/report',
		isEnabled: true,
		icon: MenuBookOutlinedIcon,
	},
	{
		key: 'router-payments',
		title: 'Payments',
		description: 'Manage Payment',
		component: ManagePayments,
		path: '/payments',
		isEnabled: true,
		icon: PaidOutlinedIcon,
	},
	{
		key: 'router-invoices',
		title: 'Invoices',
		description: 'Manage Invoice',
		component: ManageInvoices,
		path: '/invoices',
		isEnabled: true,
		icon: ReceiptLongOutlinedIcon,
	},
	{
		key: 'router-work-done',
		title: 'Work Done',
		description: 'Manage Work Done',
		component: ManageWorkDone,
		path: '/work-done',
		isEnabled: true,
		icon: HardwareIcon,
	},
	{
		key: 'router-worker-analytics',
		title: 'Worker Analytics',
		description: 'Worker Analytics',
		component: WorkerAnalytics,
		path: '/worker-analytics',
		isEnabled: true,
		icon: AnalyticsIcon,
	},
];
