import axios from './api';

export const fetchAllInvoices = async (page: number) => {
	const response = await axios.get(`invoice/getAll?page=${page}`);

	return response.data;
};

export const fetchInvoiceNumber = async () => {
	const response = await axios.get(`invoice/genInvoiceNum`);

	return response;
};

export const fetchPaymentProgressNum = async (clientId: number) => {
	const response = await axios.post('invoice/get/payment_progress_number', {
		clientId,
	});

	return response.data;
};

export const createInvoice = async (payload: any) => {
	const response = await axios.post('invoice/generate', payload);

	return response.data;
};

export const downloadInvoice = async (payload: any) => {
	const response = await axios.get('invoice/download', { responseType: 'blob', params: payload });

	return response;
};

export const fetchClaimRefs = async (projectId: number, clientId: number) => {
	const response = await axios.get('claim/claimRefsByProjectClient?projectId=' + projectId + '&clientId=' + clientId);

	return response;
};

export const calculateInvoiceAmount = async (payload: any) => {
	const response = await axios.post('invoice/calculate', payload);

	return response.data;
};

export const getLatestInvoiceNo = async () => {
	const response = await axios.get('invoice/getLatestInvoiceNo');

	return response.data?.data;
};

export const getLatestClaimRefs = async () => {
	const response = await axios.get('claim/latestClaims');

	return response.data;
};

export const fetchLatestInvoiceOfClient = async (clientId: number) => {
	const response = await axios.get('invoice/getLatestInvoiceOfClient?clientId=' + clientId);

	return response.data;
};
