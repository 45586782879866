import React from 'react';
import { Box } from '@mui/material';
import { Header } from './components/Header';
import { IIconProps } from './components/Icon';
import { TreeBody, IDataProps } from './components/TreeBody';
import '../css/PanelSegment.css';

export const spaceDelimiter = ';';

export interface IItemProps {
	text: string;
	icons?: IIconProps[];
}

export interface ITreeViewProps {
	header: IItemProps;
	placeholderText: string; // For when no data is present
	treeData: Array<IDataProps>;
	width?: string;
}

export const TreeView = (props: { treeProps: ITreeViewProps }) => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					height: 'inherit',
					paddingBottom: '8px',
					width: props.treeProps.width || 'inherit',
				}}>
				<Box>
					<Header text={props.treeProps.header.text} icons={props.treeProps.header.icons} />
				</Box>
				<hr style={{ margin: 0, color: '#D3D3D3' }} />
				{props?.treeProps?.treeData?.length ? (
					<Box style={{ flexGrow: 1, overflowY: 'scroll' }}>
						<TreeBody treeData={props.treeProps.treeData} />
					</Box>
				) : (
					<>
						{/* There are no data at all, render the place holder */}
						<div style={{ display: 'table', height: '100%', textAlign: 'center' }}>
							<span style={{ display: 'table-cell', verticalAlign: 'middle' }}>{props.treeProps.placeholderText}</span>
						</div>
					</>
				)}
			</div>
		</>
	);
};
