import { useState, useRef } from 'react';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { Box, TextField, Button } from '@mui/material';
import { CircularProgress } from '@mui/material';

import { login } from '../services/auth.service';
import { EType } from '../models/user-redux.model';
import { useAppDispatch } from '../hooks/redux-hooks';
import { updateUser } from '../store/user/user-action';
import { PREAUTH_APP_TITLE, PAGE_TITLE_LOGIN } from '../utils/constants';
import React from 'react';

export const Login = () => {
	const dispatch = useAppDispatch();
	const emailRef: any = useRef();
	const passwordRef: any = useRef();
	const [error, setError] = useState<any>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const schema = yup.object().shape({
		email: yup.string().email().required(),
		password: yup.string().required(),
	});

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setError(null);
		let payload = {
			email: emailRef?.current?.value,
			password: passwordRef?.current?.value,
		};

		try {
			setLoading(true);
			schema
				.validate(payload)
				.then(async (value) => {
					const result: any = await login('auth/login', value);

					if (result.data.success) {
						const { data } = result.data;
						if (data.type == EType.admin) {
							dispatch(
								updateUser({
									id: data.id,
									name: data.name,
									email: data.email,
									mobileNumber: data.mobileNumber,
									type: data.type == EType.admin ? EType.admin : EType.worker,
									createdAt: data.createdAt,
									updatedAt: data.updatedAt,
								})
							);
						} else {
							setError({ message: 'Invalid email & password combination!' });
						}
					} else {
						console.log('err', result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					setError(err);
					setLoading(false);
				});
		} catch (err: any) {
			console.log('err', err);
			setError(err);
			setLoading(false);
		}
	};

	return (
		<>
			<Helmet>
				<title>
					{PAGE_TITLE_LOGIN} | {PREAUTH_APP_TITLE}
				</title>
			</Helmet>
			<Box
				sx={{
					display: 'flex',
					flex: 1,
					border: '1px solid black',
					height: '100vh',
					width: '100vw',
					background: 'white',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<Box
					sx={{
						height: '500px',
						width: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<h2 style={{ display: 'block' }}>Login to admin panel!</h2>
					<form className='form' onSubmit={handleSubmit}>
						<TextField label='Email' variant='standard' type='email' fullWidth={true} inputRef={emailRef} />
						<TextField
							label='Password'
							variant='standard'
							type='password'
							style={{ marginTop: '30px' }}
							fullWidth={true}
							inputRef={passwordRef}
						/>
						{error && <p style={{ color: 'red', textAlign: 'center' }}>{error?.message}</p>}
						<Button variant='contained' fullWidth={true} style={{ marginTop: '20px' }} type='submit'>
							{isLoading ? <CircularProgress color='warning' /> : 'Login'}
						</Button>
					</form>
				</Box>
			</Box>
		</>
	);
};
