import React from 'react';
import { Typography } from '@mui/material';
import { TextLine } from './TextLine';
import { EIcon, IIconProps } from './Icon';

export interface IHeaderProps {
	text: string;
	textClickCb?: (...args: any[]) => void;
	icons?: IIconProps[];
}

export const Header = (props: IHeaderProps) => {
	return (
		<Typography variant='subtitle2'>
			<TextLine inputText={props.text} textClickCb={props.textClickCb} icons={props.icons} />
		</Typography>
	);
};
