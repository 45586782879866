import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, entity }) => {
	const [countdown, setCountdown] = useState(3);
	const intervalRef = useRef(null);

	useEffect(() => {
		if (open) {
			setCountdown(3);
			intervalRef.current = setInterval(() => {
				setCountdown((prev) => prev - 1);
			}, 1000);
		}

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [open]);

	useEffect(() => {
		if (countdown === 0) {
			clearInterval(intervalRef.current);
		}
	}, [countdown]);

	return (
		<Modal open={open} onClose={onClose} closeAfterTransition>
			<div
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 800,
					backgroundColor: 'white',
					boxShadow: '24px',
					padding: 10,
					borderRadius: 3,
				}}>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<IconButton onClick={onClose} style={{ position: 'absolute', top: 5, right: 5 }}>
						<CloseIcon />
					</IconButton>
				</div>
				<div style={{ paddingLeft: 10, paddingRight: 10 }}>
					<h2>Confirmation</h2>
					<div style={{ display: 'flex' }}>
						<p style={{ color: 'orange', marginRight: 5, marginTop: 0 }}>
							Warning: You are about to delete this {entity}! This action cannot be undone and all the data associated
							with this {entity} will be lost.
						</p>
					</div>
					<p>Proceed with deletion?</p>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
					<Button style={{ marginRight: 10 }} onClick={onClose}>
						Cancel
					</Button>
					<Button onClick={onConfirm} variant='contained' color='error' disabled={countdown > 0}>
						Delete {countdown > 0 ? `(${countdown})` : ''}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteConfirmationDialog;
