import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Button,
	TextField,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import { useAllClients } from '../../pages/Client/hooks/clients';
import { getClientDetails } from '../../services/client.service';
import {
	fetchPaymentProgressNum,
	createInvoice,
	calculateInvoiceAmount,
	getLatestInvoiceNo,
	fetchLatestInvoiceOfClient,
} from '../../services/invoice.service';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import moment from 'moment';
import { ClientModel } from '../../models/client.model';
import { InvoiceModel } from '../../models/invoice.model';
import { PaymentModel } from '../../models/payment.model';
import { InvoiceTable } from '../Tables/InvoiceTable';
import { PaymentsTable } from '../Tables/PaymentsTable';
import { getOffset } from '../../utils/utilityFunctions';

const GenerateTextLine = (props: any) => {
	return (
		<Box style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
			<span style={{ fontSize: '14px', color: 'black', width: '220px' }}>{props?.keyText}</span>
			{props?.valueText && (
				<span style={{ marginLeft: '6px', fontSize: '12px', color: 'black', fontWeight: 'bold' }}>
					{props?.valueText}
				</span>
			)}
			{props?.valuesText && (
				<Box style={{ display: 'flex', flexDirection: 'column' }}>
					{props?.valuesText.split(',').map((value: string, index: number) => (
						<span key={index} style={{ marginLeft: '6px', fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}>
							{value}
						</span>
					))}
				</Box>
			)}
		</Box>
	);
};

const invoiceSchema = yup.object().shape({
	invoiceNumber: yup.number().positive().required(),
	clientId: yup.number().required(),
	fromDate: yup.string().required(),
	toDate: yup.string().required(),
});

export const AddInvoiceDialog = (props: any) => {
	const [paymentProgressNum, setPaymentProgressNum] = React.useState<number>(0);
	const [fromDate, setFromDate] = React.useState<string>();
	const [toDate, setToDate] = React.useState<string>();
	const [selectedClientId, setSelectedClientId] = React.useState<number>();
	const [selectedClientDetails, setSelectedClientDetails] = React.useState<ClientModel>();
	const [invoiceNumber, setInvoiceNumber] = React.useState<number>();
	const [enableBulkInvoicing, setEnableBulkInvoicing] = React.useState<boolean>(false);
	const [taxAmount, setTaxAmount] = React.useState<number>(0);
	const [invoiceAmount, setInvoiceAmount] = React.useState<number>(0);
	const [paymentDetails, setPaymentDetails] = React.useState<PaymentModel[]>();
	const [latestInvoiceDetails, setLatestInvoiceDetails] = React.useState<InvoiceModel[]>();
	const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = React.useState<boolean>(true);
	const [isLatestInvoiceOpen, setIsLatestInvoiceOpen] = React.useState<boolean>(true);
	const clientsModel: Array<ClientModel> | undefined = useAllClients();

	const onClose = () => {
		setSelectedClientId(undefined);
		setSelectedClientDetails(undefined);
		setPaymentProgressNum(0);
		setInvoiceAmount(0);
		setTaxAmount(0);
		setFromDate(undefined);
		setToDate(undefined);
		setEnableBulkInvoicing(false);
		setPaymentDetails([]);
		setLatestInvoiceDetails([]);
		setIsPaymentDetailsOpen(false);
		setIsLatestInvoiceOpen(false);
		setInvoiceNumber(undefined);
		props.close();
	};

	const getInvoiceNo = async () => {
		let invoiceNo: number = await getLatestInvoiceNo();
		if (invoiceNo) {
			setInvoiceNumber(invoiceNo);
		}
	};

	useEffect(() => {
		if (!invoiceNumber) {
			getInvoiceNo();
		}
	}, [props.show]);

	const loadLatestInvoiceOfClient = async () => {
		try {
			let result: any;
			if (selectedClientId) {
				result = await fetchLatestInvoiceOfClient(selectedClientId);
			}
			let tmpList: any = [];
			if (result?.data?.length) {
				tmpList = result.data.map((info: any) => {
					const row = {
						id: info.id,
						progressPaymentNumber: info.progressPaymentNumber,
						paymentId: info.paymentId,
						fromDate: info.fromDate,
						toDate: info.toDate,
						amount: info.amount.toFixed(2),
						tax: info.tax.toFixed(2),
						invoiceNumber: info.invoiceNumber,
					};
					return row;
				});
				setIsLatestInvoiceOpen(true);
			}
			setLatestInvoiceDetails(tmpList);
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};
	const formatPaymentData = async (data: PaymentModel[]) => {
		let tmpList = data.map((info: PaymentModel, index: number) => {
			let row = {
				id: index,
				itemNo: index + 1,
				projectId: info.projectName,
				date: moment(info.date).format('YYYY-MM-DD'),
				amount: info.amount,
				gst: info.gst,
				totalAmount: info.totalAmount,
				invoiceNumber: info.invoiceNumber,
				isTemporary: info.isTemporary,
			};
			return row;
		});

		setPaymentDetails(tmpList as unknown as PaymentModel[]);
	};

	const getSelectedClientDetails = async () => {
		if (selectedClientId) {
			const clientResult: any = await getClientDetails(selectedClientId);

			if (clientResult?.success) {
				setSelectedClientDetails({ ...clientResult?.data });
				setEnableBulkInvoicing(clientResult?.data?.enableBulkInvoicing);
			}
		}
	};

	const getPaymentProgressNum = async () => {
		if (selectedClientId) {
			const result: any = await fetchPaymentProgressNum(selectedClientId);

			if (result?.success) {
				setPaymentProgressNum(parseInt(result?.data?.progressPaymentNumber));
			}
		}
	};

	const submitInvoice = async () => {
		if (selectedClientId && paymentProgressNum) {
			try {
				let payload = {
					invoiceNumber: invoiceNumber,
					progressPaymentNumber: paymentProgressNum,
					clientId: selectedClientId,
					fromDate: moment(fromDate)
						.startOf('day')
						.utcOffset(getOffset() * -1)
						.format('YYYY-MM-DD HH:mm:ss'),
					toDate: moment(toDate)
						.startOf('day')
						.utcOffset(getOffset() * -1)
						.format('YYYY-MM-DD HH:mm:ss'),
					amount: invoiceAmount,
					tax: taxAmount,
					enabledBulkInvoicing: enableBulkInvoicing,
				};

				invoiceSchema
					.validate(payload)
					.then(async (value) => {
						const result: any = await createInvoice(payload);

						if (result.success) {
							toast.success('Invoice created successfully');
							props.setAddedInvoiceId(result.data.allInvoiceId);
							onClose();
						} else {
							toast.error(result.message);
						}
					})
					.catch((err: any) => {
						let toastMessage = '';
						if (err?.response?.data?.message?.invoiceNo) {
							toastMessage = `Invalid Invoice number used. Please use number >= ${err?.response?.data?.message?.invoiceNo}`;
						} else {
							toastMessage = err?.message;
						}
						toast.error(toastMessage);
					});
			} catch (err: any) {
				toast.error(err?.response?.data?.message || err?.message);
			}
		} else {
			toast.error('Required fields cannot be empty!');
		}
	};

	const calculateInvoice = async () => {
		try {
			const result = await calculateInvoiceAmount({
				clientId: selectedClientId,
				fromDate: moment(fromDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
				toDate: moment(toDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
			});
			if (result?.success) {
				setTaxAmount(result.data?.taxAmount);
				setInvoiceAmount(result.data?.totalAmount);
				if (paymentDetails) {
					const temporaryInvoice = {
						itemNo: paymentDetails.length + 1,
						projectName: result.data?.projectName,
						date: moment().format('YYYY-MM-DD'),
						amount: ' $' + invoiceAmount.toFixed(2),
						gst: ' $' + taxAmount.toFixed(2),
						totalAmount: ' $' + (invoiceAmount + taxAmount).toFixed(2),
						invoiceNumber: invoiceNumber,
						isTemporary: true,
					};
					const combinedPaymentDetails = [...result.data?.paymentDetails, temporaryInvoice];
					formatPaymentData(combinedPaymentDetails);
				}
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		if (selectedClientId) {
			getSelectedClientDetails();
			getPaymentProgressNum();
			loadLatestInvoiceOfClient();
		}
		setFromDate('');
		setToDate('');
		setPaymentDetails([]);
		setIsPaymentDetailsOpen(false);
		setIsLatestInvoiceOpen(false);
		setInvoiceAmount(0);
		setTaxAmount(0);
	}, [selectedClientId]);

	useEffect(() => {
		if (selectedClientId && fromDate && toDate) {
			calculateInvoice();
		}
	}, [fromDate, toDate]);

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'md'}
			open={props.show}
			onClose={onClose}
			aria-labelledby='customized-dialog-title'>
			<DialogTitle>Create Invoice</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<TextField
						label='Invoice Number'
						variant='standard'
						value={invoiceNumber}
						onChange={(event: any) => setInvoiceNumber(event.target.value)}
					/>
					<FormControlLabel
						value='top'
						control={
							<Checkbox
								checked={enableBulkInvoicing}
								onChange={(event: any) => setEnableBulkInvoicing(event.target.checked)}
							/>
						}
						label='Enable bulk invoicing'
						labelPlacement='end'
					/>
				</Box>
				<FormControl fullWidth sx={{ marginTop: '20px' }}>
					<InputLabel>{'Client'}</InputLabel>
					<Select
						label='Client'
						variant='outlined'
						onChange={(e: any) => {
							setSelectedClientId(e.target.value);
						}}>
						{clientsModel?.length &&
							clientsModel.map((clientInfo: ClientModel, index: number) => (
								<MenuItem key={index} value={clientInfo?.id}>
									{clientInfo?.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				{selectedClientId && latestInvoiceDetails ? (
					<>
						<Button onClick={() => setIsLatestInvoiceOpen(!isLatestInvoiceOpen)} sx={{ marginTop: '10px' }}>
							Latest Invoice Details
							{isLatestInvoiceOpen ? (
								<ExpandLess style={{ marginBottom: '2px' }} />
							) : (
								<ExpandMore style={{ marginBottom: '2px' }} />
							)}
						</Button>
						{isLatestInvoiceOpen ? <InvoiceTable data={latestInvoiceDetails}></InvoiceTable> : null}
					</>
				) : null}
				<FormControl fullWidth sx={{ marginTop: '20px' }}>
					<TextField
						type='date'
						label='From'
						sx={{ width: '100%' }}
						defaultValue={''}
						value={fromDate}
						onChange={(e) => setFromDate(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							max: toDate ? dayjs(toDate).subtract(1, 'day').format('YYYY-MM-DD') : null,
						}}
					/>
				</FormControl>
				<FormControl fullWidth sx={{ marginTop: '20px' }}>
					<TextField
						type='date'
						label='To'
						sx={{ width: '100%' }}
						value={toDate}
						onChange={(e) => {
							setToDate(e.target.value);
						}}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							min: dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'),
						}}
					/>
				</FormControl>
				<Box sx={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
					<Box>
						<GenerateTextLine
							keyText={'CLIENT:'}
							valueText={selectedClientDetails?.name ? selectedClientDetails?.name : undefined}
						/>
						<GenerateTextLine
							keyText={'ADDRESS:'}
							valueText={selectedClientDetails?.address ? selectedClientDetails?.address : undefined}
						/>
						<GenerateTextLine
							keyText={'TEL NO:'}
							valuesText={selectedClientDetails?.contactNumbers ? selectedClientDetails?.contactNumbers : undefined}
						/>
						<GenerateTextLine
							keyText={'CONTACT PERSON:'}
							valueText={selectedClientDetails?.contactPerson ? selectedClientDetails?.contactPerson : undefined}
						/>
						<GenerateTextLine
							keyText={'EMAIL:'}
							valuesText={selectedClientDetails?.emails ? selectedClientDetails?.emails : undefined}
						/>
					</Box>
					<Box>
						<GenerateTextLine keyText={'PROGRESS PAYMENT NUMBER:'} valueText={paymentProgressNum} />
						<GenerateTextLine keyText={'SUB TOTAL:'} valueText={invoiceAmount.toFixed(2) + ' $'} />
						<GenerateTextLine keyText={'TAX:'} valueText={taxAmount.toFixed(2) + ' $'} />
						<GenerateTextLine keyText={'TOTAL:'} valueText={(invoiceAmount + taxAmount).toFixed(2) + ' $'} />
					</Box>
				</Box>
				<hr />

				<Button onClick={() => setIsPaymentDetailsOpen(!isPaymentDetailsOpen)}>
					Payment Details
					{isPaymentDetailsOpen ? (
						<ExpandLess style={{ marginBottom: '2px' }} />
					) : (
						<ExpandMore style={{ marginBottom: '2px' }} />
					)}
				</Button>
				{isPaymentDetailsOpen ? <PaymentsTable data={paymentDetails}></PaymentsTable> : null}
			</DialogContent>
			<DialogActions>
				<>
					<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
						Cancel
					</Button>
					<Button type='submit' size='small' variant='contained' onClick={submitInvoice} disabled={invoiceAmount === 0}>
						Create
					</Button>
				</>
			</DialogActions>
		</Dialog>
	);
};
