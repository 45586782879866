import React from 'react';
import { Checkbox, Radio } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export enum EIcon {
	ADD = 'add',
	EDIT = 'edit',
	DELETE = 'delete',
	CHECKBOX = 'checkbox',
	RADIO = 'radio',
}

export interface IIconProps {
	inputText?: string;
	icon?: EIcon;
	iconCb: (...args: any[]) => void;
	isIconChecked?: boolean;
}

export const Icon = (props: IIconProps) => {
	return (
		// See if its checkbox
		props.icon === EIcon.CHECKBOX ? (
			<Checkbox
				sx={{ p: 0 }}
				checked={props.isIconChecked}
				size='small'
				onChange={props.iconCb}
				inputProps={{ 'aria-label': 'controlled' }}
			/>
		) : // See if its radio
		props.icon === EIcon.RADIO ? (
			<Radio
				sx={{ p: 0 }}
				size='small'
				checked={props.isIconChecked}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					props.iconCb();
				}}
				inputProps={{ 'aria-label': 'controlled' }}
			/>
		) : (
			// Else render the icons
			<IconButton
				className='iconButton'
				style={{
					padding: '0px',
					float: 'right',
				}}
				onClick={props.iconCb}
				color='primary'
				aria-label='add'>
				{(props.icon === EIcon.ADD && <AddIcon fontSize='small'></AddIcon>) ||
					(props.icon === EIcon.EDIT && <EditIcon fontSize='small' style={{ fontSize: '18px' }}></EditIcon>) ||
					(props.icon === EIcon.DELETE && (
						<DeleteIcon fontSize='small' color='error' style={{ fontSize: '16px' }}></DeleteIcon>
					))}
			</IconButton>
		)
	);
};
