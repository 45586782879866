import React, { useState, useRef, useEffect } from 'react';
import type { TextFieldProps } from '@mui/material';
import { toast } from 'react-toastify';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	CircularProgress,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import * as yup from 'yup';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { addNewClient, updateClient } from '../../services/client.service';

export const AddClientDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [emailsList, setEmailsList] = useState<Array<string>>([]);
	const [phoneNumList, setPhoneNumList] = useState<Array<string>>([]);
	const [enableBulkInvoicing, setEnableBulkInvoicing] = React.useState<boolean>(false);
	const clientNameRef = useRef<TextFieldProps>(null);
	let clientEmailRef = useRef<TextFieldProps>(null);
	const clientAddressRef = useRef<TextFieldProps>(null);
	let clientContactNumberRef = useRef<TextFieldProps>(null);
	const clientContactPersonRef = useRef<TextFieldProps>(null);
	const invoiceCutOffDateRef = useRef<TextFieldProps>(null);
	const phoneRegExp = /^[0-9+]*[0-9]+$/;
	const emailSchema = yup.object().shape({
		email: yup.string().email().required(),
	});

	const phoneSchema = yup.object().shape({
		phone: yup
			.string()
			.matches(phoneRegExp, 'Phone number is not valid')
			.min(8, 'Phone number should be of minimum 8 digits')
			.required(),
	});

	const clientSchema = yup.object().shape({
		name: yup.string().min(3, 'Name must be at least 3 characters long').required(),
		emails: yup.array().min(1, 'Atleast one valid email address is required').of(yup.string().email().required()),
		address: yup.string().min(10, 'Address name must be at least 10 characters long').required(),
		contactNumber: yup
			.array()
			.of(
				yup
					.string()
					.matches(phoneRegExp, 'Phone number is not valid')
					.min(8, 'Phone number should be of minimum 8 digits')
					.required()
			)
			.min(1, 'At least one contact number is required'),
		contactPerson: yup.string().min(3, `Contact Person's name must be at least 3 characters long`).required(),
		invoiceCutOffDate: yup
			.number()
			.min(1, 'Please enter date between 1 to 31.')
			.max(31, 'Please enter date between 1 to 31.')
			.required(),
		enableBulkInvoicing: yup.boolean(),
	});

	const onClose = () => {
		setEmailsList([]);
		setPhoneNumList([]);
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			name: clientNameRef?.current?.value,
			emails: [...emailsList],
			address: clientAddressRef?.current?.value,
			contactNumbers: [...phoneNumList],
			contactPerson: clientContactPersonRef?.current?.value,
			invoiceCutOffDate: invoiceCutOffDateRef?.current?.value,
			enableBulkInvoicing: enableBulkInvoicing,
		};

		try {
			setLoading(true);
			clientSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await addNewClient('client/add', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.refreshPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const addEmail = () => {
		emailSchema
			.validate({ email: clientEmailRef?.current?.value })
			.then(async (value) => {
				setEmailsList((prevState) => [...prevState, clientEmailRef?.current?.value as string]);
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.message || err?.message);
			})
			.finally(() => clientEmailRef?.current?.value && (clientEmailRef.current.value = null));
	};

	const deleteEmail = (index: number) => {
		let oldEmails = [...emailsList];
		oldEmails.splice(index, 1);

		setEmailsList((prevState) => [...oldEmails]);
	};

	const addPhone = () => {
		phoneSchema
			.validate({ phone: clientContactNumberRef?.current?.value })
			.then(async (value) => {
				setPhoneNumList((prevState) => [...prevState, clientContactNumberRef?.current?.value as string]);
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.message || err?.message);
			})
			.finally(() => clientContactNumberRef?.current?.value && (clientContactNumberRef.current.value = null));
	};

	const deletePhone = (index: number) => {
		let oldPhones = [...phoneNumList];
		oldPhones.splice(index, 1);

		setPhoneNumList((prevState) => [...oldPhones]);
	};

	const handleUpdate = async () => {
		let payload = {
			clientId: props?.selectedClientForEdit?.id,
			name: clientNameRef?.current?.value,
			emails: [...emailsList],
			address: clientAddressRef?.current?.value,
			contactNumbers: [...phoneNumList],
			contactPerson: clientContactPersonRef?.current?.value,
			invoiceCutOffDate: invoiceCutOffDateRef?.current?.value,
			enableBulkInvoicing: enableBulkInvoicing,
		};

		try {
			setLoading(true);
			clientSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await updateClient('client/update', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.refreshPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (props?.isEditMode) {
			setEmailsList((prevState) => [...props?.selectedClientForEdit?.emails?.split(',')]);
			setPhoneNumList((prevState) => [...props?.selectedClientForEdit?.contactNumbers?.split(',')]);
			setEnableBulkInvoicing(props?.selectedClientForEdit?.enableBulkInvoicing);
		}
	}, [props.show]);

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props.isEditMode ? 'Edit' : 'Add'} Client</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<div>
					<TextField
						style={{ marginTop: '2%' }}
						label='Client Name'
						variant='standard'
						defaultValue={props.isEditMode ? props.selectedClientForEdit?.name : null}
						inputRef={clientNameRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						label='Client Email'
						variant='standard'
						inputRef={clientEmailRef}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={addEmail}>
										<AddIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>
					{emailsList?.length
						? emailsList.map((email: string, index: number) => (
								<div
									key={index}
									style={{
										display: 'flex',
										marginRight: '10px',
										marginBottom: '10px',
										backgroundColor: '#eeeeee',
										justifyContent: 'space-between',
										alignItems: 'center',
										borderRadius: '20px',
										padding: '6px',
									}}>
									<span style={{ fontSize: '12px', color: 'black' }}>{email}</span>
									<CloseIcon
										style={{ fontSize: '12px', color: 'black', marginLeft: '10px' }}
										onClick={() => deleteEmail(index)}
									/>
								</div>
						  ))
						: null}
				</div>

				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						label='Client Address'
						variant='standard'
						defaultValue={props.isEditMode ? props.selectedClientForEdit?.address : null}
						inputRef={clientAddressRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						label='Contact Number'
						variant='standard'
						inputRef={clientContactNumberRef}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={addPhone}>
										<AddIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>
					{phoneNumList?.length
						? phoneNumList.map((phone: string, index: number) => (
								<div
									key={index}
									style={{
										display: 'flex',
										marginRight: '10px',
										marginBottom: '10px',
										backgroundColor: '#eeeeee',
										justifyContent: 'space-between',
										alignItems: 'center',
										borderRadius: '20px',
										padding: '6px',
									}}>
									<span style={{ fontSize: '12px', color: 'black' }}>{phone}</span>
									<CloseIcon
										style={{ fontSize: '12px', color: 'black', marginLeft: '10px' }}
										onClick={() => deletePhone(index)}
									/>
								</div>
						  ))
						: null}
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						label='Contact Person'
						variant='standard'
						defaultValue={props.isEditMode ? props.selectedClientForEdit?.contactPerson : null}
						inputRef={clientContactPersonRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						label='Invoice Cut-Off Date'
						variant='standard'
						defaultValue={props.isEditMode ? props.selectedClientForEdit?.invoiceCutOffDate : null}
						inputRef={invoiceCutOffDateRef}
						fullWidth
					/>
				</div>
				<Box>
					<FormControlLabel
						value='top'
						control={
							<Checkbox
								checked={enableBulkInvoicing}
								onChange={(event: any) => setEnableBulkInvoicing(event.target.checked)}
							/>
						}
						label='Enable bulk invoicing'
						labelPlacement='end'
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							onClick={props.isEditMode ? handleUpdate : handleSubmit}>
							{props.isEditMode ? 'Update' : 'Add'}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
