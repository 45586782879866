import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Panel, IPanelProps } from '../Layout/Panel/Panel';
import { EIcon } from '../Layout/Panel/views/components/Icon';
import { ISegmentProps } from '../Layout/Panel/views/Segment';
import { ProjectToTypesFullDetailsDataModel, TypeDataModel } from '../../models/project.model';
import { ClientModel } from '../../models/client.model';
import { useAllTypes } from '../../pages/Project/hooks/types';
import { useAllClients } from '../../pages/Client/hooks/clients';
import { useTypesForProject } from '../../pages/Project/hooks/projects';
import { addTypeToProject } from '../../services/project.service';
import { IDataProps } from '../Layout/Panel/views/components/SegmentBody';

export const AddTypeDialog = (props: any) => {
	const [selectedType, setSelectedType] = useState<TypeDataModel | null>();
	const [selectedClient, setSelectedClient] = useState<ClientModel | null>();
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);

	const projectTypesModel: Array<ProjectToTypesFullDetailsDataModel> | undefined = useTypesForProject(
		props.selectedProjectId,
		toggleForReload
	);

	const onRefreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
		props.onRefreshTypePanel();
	};

	const typesModel: Array<TypeDataModel> | undefined = useAllTypes();
	const clientsModel: Array<ClientModel> | undefined = useAllClients();

	const getAllTypes = () => {
		let data: Array<IDataProps> = [];
		let addedTypes: any = [];

		if (projectTypesModel) {
			projectTypesModel.map((item) => addedTypes.push(item.typeName));
		}

		if (typesModel) {
			data = typesModel.map((type: TypeDataModel) => {
				if (!addedTypes.includes(type.name)) {
					return {
						text: type.name,
						icons: [
							{
								icon: EIcon.RADIO,
								iconCb: () => {
									setSelectedType(type);
								},
								iconValue: type,
								isIconChecked: selectedType === type,
							},
						],
					};
				}
			});
		}

		return data;
	};

	const getAllClients = () => {
		let data: Array<IDataProps> = [];

		if (clientsModel) {
			data = clientsModel.map((client: ClientModel) => {
				return {
					text: client.name,
					icons: [
						{
							icon: EIcon.RADIO,
							iconCb: () => {
								setSelectedClient(client);
							},
							iconValue: client,
							isIconChecked: selectedClient === client,
						},
					],
				};
			});
		}

		return data;
	};

	const typesProps: ISegmentProps = {
		header: {
			text: 'Select Type',
		},
		placeholderText: 'No types available',
		data: getAllTypes(),
	};

	const clientsProps: ISegmentProps = {
		header: {
			text: 'For Client',
		},
		placeholderText: 'No clients available',
		data: getAllClients(),
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: '250px',
			padding: '2px',
		},
		segments: [typesProps, clientsProps],
	};

	const handleClose = () => {
		setSelectedType(null);
		setSelectedClient(null);
		props.close();
	};

	const handleSubmit = async () => {
		try {
			if (selectedType?.id && selectedClient?.id) {
				const result = await addTypeToProject('project/addTypeAssociation', {
					projectId: props.selectedProjectId,
					typeId: selectedType?.id,
					clientId: selectedClient?.id,
				});

				if (result.data.success) {
					toast.success('Type added successfully');
					onRefreshPanel();
					handleClose();
				} else {
					toast.error(result.data.message);
				}
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		onRefreshPanel();
	}, [props.show]);

	return (
		<Dialog open={props.show} onClose={handleClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle> Add Type </DialogTitle>
			<DialogContent style={{ width: '500px' }}>
				<Panel panelProps={panelProps}></Panel>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' size='small' onClick={handleClose}>
					Cancel
				</Button>
				<Button type='submit' size='small' variant='contained' onClick={handleSubmit}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
