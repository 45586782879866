import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Button,
	Box,
	FormControl,
	Typography,
} from '@mui/material';
import { fetchTaxByDateRange } from '../../services/payment.service';
import moment from 'moment';
import { getOffset } from '../../utils/utilityFunctions';

export const TaxCalculationDialog = (props: any) => {
	const [gstValue, setGSTValue] = React.useState<number>(0);
	const [fromDate, setFromDate] = React.useState<string>();
	const [toDate, setToDate] = React.useState<string>();

	const getTaxValue = async () => {
		try {
			if (fromDate && toDate) {
				const result = await fetchTaxByDateRange(
					moment(fromDate)
						.startOf('day')
						.utcOffset(getOffset() * -1)
						.format('YYYY-MM-DD HH:mm:ss'),
					moment(toDate)
						.startOf('day')
						.utcOffset(getOffset() * -1)
						.format('YYYY-MM-DD HH:mm:ss')
				);

				if (result?.success) {
					setGSTValue(result?.data?.taxTotal);
				}
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		if (fromDate && toDate) {
			getTaxValue();
		}
	}, [fromDate, toDate]);

	return (
		<Dialog open={props.show} onClose={props.onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>Calculate GST</DialogTitle>
			<DialogContent style={{ width: '500px' }}>
				<Box sx={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
					<FormControl fullWidth>
						<TextField
							type='date'
							label='From'
							sx={{ width: '100%' }}
							defaultValue={''}
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								max: toDate ? dayjs(toDate).subtract(1, 'day').format('YYYY-MM-DD') : null,
							}}
						/>
					</FormControl>
					<FormControl fullWidth sx={{ marginLeft: '20px' }}>
						<TextField
							type='date'
							label='To'
							sx={{ width: '100%' }}
							value={toDate}
							onChange={(e) => {
								setToDate(e.target.value);
							}}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								min: dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'),
							}}
						/>
					</FormControl>
				</Box>
				<Box sx={{ marginTop: '20px', padding: '10px' }}>
					<Typography variant='subtitle2' sx={{ textAlign: 'center' }}>
						Total TAX: ${gstValue.toFixed(2)}
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' size='small' onClick={props.onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
