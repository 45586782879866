import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { IDataProps } from '../../components/Layout/Panel/views/components/SegmentBody';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { ISegmentProps, spaceDelimiter } from '../../components/Layout/Panel/views/Segment';
import { PrecinctModel, BlockModel } from '../../models/precinct.model';
import { usePrecinctsForProject } from './hooks/precincts';
import { fetchAllBlocks } from '../../services/block.service';
import { AddEditPrecinctDialog } from '../../components/Dialog/AddEditPrecinctDialog';
import { AddEditBlockDialog } from '../../components/Dialog/AddEditBlockDialog';

export const PrecinctBlockPanel = (props: any) => {
	const [isPrecinctEditMode, setIsPrecinctEditMode] = useState<boolean>(false);
	const [isBlockEditMode, setIsBlockEditMode] = useState<boolean>(false);
	const [selectedPrecinctForEdit, setSelectedPrecinctForEdit] = useState<PrecinctModel | null>();
	const [selectedBlock, setSelectedBlock] = useState<BlockModel | null>(null);
	const [showPrecinctDialog, setShowAddPrecinctDialog] = useState<boolean>(false);
	const [showBlockDialog, setShowBlockDialog] = useState<boolean>(false);
	const [selectedPrecinct, setSelectedPrecinct] = useState<PrecinctModel | null>();
	const [blocksList, setBlocksList] = useState<Array<BlockModel> | null>(null);
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);
	// Just alternates to trigger reload
	const [toggleForBlockReload, setToggleForBlockReload] = useState<boolean>(false);

	// Fetch all precincts
	const precinctsDataModel: Array<PrecinctModel> | undefined = usePrecinctsForProject(
		props.selectedProject?.id,
		toggleForReload
	);

	const getBlocksFromPrecinct = async () => {
		try {
			if (selectedPrecinct) {
				const result = await fetchAllBlocks('block/getAll?precinctId=' + selectedPrecinct.id);

				if (result.data.success) {
					setBlocksList(result.data.data);
				} else {
					toast.error(result.data.message);
				}
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	const onRefreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
	};

	const onRefreshBlockPanel = () => {
		// Purely for refreshing the page
		setToggleForBlockReload(!toggleForBlockReload);
	};

	const getPrecinctsData = (): Array<IDataProps> => {
		if (!precinctsDataModel) return [];
		//Select first Precinct by default if it exists
		if (!selectedPrecinct && precinctsDataModel?.length > 0) {
			setSelectedPrecinct(precinctsDataModel[0]);
		}

		return precinctsDataModel.map((precinct: PrecinctModel) => {
			return {
				text: precinct.name,
				isTextSelected: selectedPrecinct?.id === precinct.id,
				textClickCb: async () => {
					setSelectedPrecinct(precinct);
				},
				icons: [
					{
						icon: EIcon.EDIT,
						iconCb: () => {
							setIsPrecinctEditMode((prevState) => !prevState);
							setSelectedPrecinctForEdit((prevState) => ({ ...precinct }));
							setShowAddPrecinctDialog((prevState) => !prevState);
						},
					},
				],
			};
		});
	};

	const getBlocksData = () => {
		let result: Array<IDataProps> = [];
		if (blocksList?.length) {
			for (let i = 0; i < blocksList.length; i++) {
				result.push({
					text: `${blocksList[i].number}${spaceDelimiter}${blocksList[i].levelTo}${spaceDelimiter}${blocksList[i].unitFrom}${spaceDelimiter}${blocksList[i].unitTo}`,
					icons: [
						{
							icon: EIcon.EDIT,
							iconCb: () => {
								setSelectedBlock((prevState) => ({ ...blocksList[i] }));
								setIsBlockEditMode((prevState) => true);
								setShowBlockDialog((prevState) => !prevState);
							},
						},
					],
				});
			}
		}

		return result;
	};

	const precinctProps: ISegmentProps = {
		header: {
			text: 'Precinct',
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						setShowAddPrecinctDialog(true);
					},
				},
			],
		},
		placeholderText: 'No precincts created',
		data: getPrecinctsData(),
	};

	const blockProps: ISegmentProps = {
		header: {
			text: `Block${spaceDelimiter}Levels${spaceDelimiter}Units From${spaceDelimiter}Units To`,
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						setShowBlockDialog((prevState) => !prevState);
					},
				},
			],
		},
		placeholderText: 'No blocks',
		data: getBlocksData(),
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [precinctProps, blockProps],
	};

	useEffect(() => {
		setSelectedPrecinct(null);
		setBlocksList(null);
	}, [precinctsDataModel, toggleForBlockReload]);

	useEffect(() => {
		getBlocksFromPrecinct();
	}, [selectedPrecinct]);

	return (
		<>
			<Panel panelProps={panelProps}></Panel>
			<AddEditPrecinctDialog
				isEditMode={isPrecinctEditMode}
				selectedPrecinct={selectedPrecinctForEdit}
				selectedProjectId={props.selectedProject?.id}
				show={showPrecinctDialog}
				onRefreshPanel={onRefreshPanel}
				close={() => {
					setShowAddPrecinctDialog(false);
					setIsPrecinctEditMode((prevState) => false);
					setSelectedPrecinctForEdit((prevState) => null);
				}}
			/>
			<AddEditBlockDialog
				isEditMode={isBlockEditMode}
				selectedBlock={selectedBlock}
				precinctId={selectedPrecinct?.id}
				show={showBlockDialog}
				onRefreshBlockPanel={onRefreshBlockPanel}
				close={() => {
					setShowBlockDialog(false);
					setIsBlockEditMode(false);
					setSelectedBlock(null);
				}}
			/>
		</>
	);
};
