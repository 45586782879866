import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import type { GridAlignment } from '@mui/x-data-grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import { IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AddInvoiceDialog } from '../../components/Dialog/AddInvoiceDialog';
import { fetchAllInvoices, downloadInvoice } from '../../services/invoice.service';
import moment from 'moment';

export function ManageInvoices() {
	const pageSize = 10;
	const [showInvoiceFormDialog, setInvoiceFormDialog] = useState<boolean>(false);
	const [addedInvoiceId, setAddedInvoiceId] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [invoicesTotalLength, setInvoicesTotalLength] = useState<number>(0);
	const [page, setPage] = useState(0);
	const [invoiceData, setInvoiceData] = useState<any>([]);

	const handleInvoiceClose = () => {
		setInvoiceFormDialog((prevState) => false);
	};

	const download = async (info: any) => {
		const pdfFile: any = await downloadInvoice({
			invoiceNumber: info.invoiceNumber,
			clientId: info.clientId,
			progressPaymentNum: info.progressPaymentNum,
			paymentId: info?.col4,
			amount: info.col7,
			tax: info.col8,
		});

		if (pdfFile.status === 200) {
			const a = document.createElement('a');
			a.href = URL.createObjectURL(new Blob([pdfFile.data], { type: 'application/pdf' }));
			a.download = `${info.col2}_OCR${info.invoiceNumber}_${moment().format('DDMMYY')}.pdf`;
			a.click();
		}
	};

	const columns = [
		{
			field: 'col1',
			headerName: 'Index',
			flex: 1,
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col2',
			headerName: 'Client',
			flex: 1,
			width: 300,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col3',
			headerName: 'Payment Progress Num',
			flex: 1,
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col4',
			headerName: 'PaymentID',
			flex: 1,
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col5',
			headerName: 'FromDate',
			flex: 1,
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col6',
			headerName: 'ToDate',
			flex: 1,
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col7',
			headerName: 'Amount',
			flex: 0.5,
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col8',
			headerName: 'Tax',
			flex: 0.5,
			width: 180,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col9',
			headerName: 'Download',
			flex: 0.5,
			width: 250,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			renderCell: (params: any) => {
				return (
					<Box>
						<IconButton size='large' color='inherit' onClick={() => download(params.row)}>
							<GetAppIcon />
						</IconButton>
					</Box>
				);
			},
		},
	];

	const loadDataToGrid = async () => {
		try {
			setLoading(true);
			const result: any = await fetchAllInvoices(page);
			setInvoiceData([]);

			if (result?.success) {
				if (result?.data?.taxInvoices?.length) {
					let tmpList: any = [];
					let lastAddedInvoiceInfo: any = null;

					setInvoicesTotalLength(Number(result?.data?.totalItems));
					let startIndex = page * pageSize + 1;

					tmpList = result.data.taxInvoices.map((info: any, index: number) => {
						const row = {
							id: info.id,
							clientId: info.clientId,
							progressPaymentNum: info.progressPaymentNumber,
							claimRefNum: info.claimRefNum,
							invoiceNumber: info.invoiceNumber,
							col1: startIndex,
							col2: info.clientName,
							col3: info.progressPaymentNumber,
							col4: info.paymentId,
							col5: info.fromDate,
							col6: info.toDate,
							col7: info.amount.toFixed(2),
							col8: info.tax.toFixed(2),
							col9: '<span></span>',
						};

						startIndex = startIndex + 1;

						if (addedInvoiceId > 0 && info.id === addedInvoiceId) {
							lastAddedInvoiceInfo = { ...row };
						}
						return row;
					});

					setInvoiceData([...tmpList]);

					if (lastAddedInvoiceInfo) {
						//Download last added invoice
						await download(lastAddedInvoiceInfo);
						setAddedInvoiceId(0);
					}
				}
			} else {
				toast.error(result?.data?.message);
			}
			setLoading(false);
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		loadDataToGrid();
	}, [page, addedInvoiceId > 0]);

	const handlePageChange = (params: any) => {
		setPage(params);
	};

	return (
		<Box sx={{ flex: 1, height: '100%' }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<h2>All Invoices</h2>
				<Button
					onClick={() => setInvoiceFormDialog((prevState) => !prevState)}
					color='primary'
					variant='contained'
					startIcon={<AddIcon />}>
					Create Invoice
				</Button>
			</Box>
			<Box sx={{ height: '90%' }}>
				<DataGrid
					rows={invoiceData}
					columns={columns}
					loading={loading}
					rowCount={invoicesTotalLength}
					pagination
					paginationMode='server'
					pageSize={pageSize}
					page={page}
					onPageChange={handlePageChange}
				/>
			</Box>
			<AddInvoiceDialog
				show={showInvoiceFormDialog}
				setAddedInvoiceId={setAddedInvoiceId}
				close={() => {
					handleInvoiceClose();
					loadDataToGrid();
				}}
			/>
		</Box>
	);
}
