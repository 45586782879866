import axios from './api';

export const fetchAllTypes = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const fetchAllValues = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const getTypeDetails = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const addType = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const updateType = async (url: string, payload: any) => {
	const response = await axios.put(url, payload);

	return response;
};

export const addNewSubType = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const updateSubType = async (url: string, payload: any) => {
	const response = await axios.put(url, payload);

	return response;
};

export const addNewSubValue = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const updateSubTypeValue = async (url: string, payload: any) => {
	const response = await axios.put(url, payload);

	return response;
};

export const getSubTypeIdByName = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const fetchAllSubTypesByType = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const fetchAllSubValuesBySubType = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const deleteSubValue = async (url: string) => {
	const response = await axios.delete(url);

	return response;
};

export const deleteSubType = async (url: string) => {
	const response = await axios.delete(url);

	return response;
};

export const deleteType = async (typeId: number) => {
	const response = await axios.delete('type/delete?typeId=' + typeId);

	return response.data;
};
