import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Icon, IIconProps } from './Icon';
import { TypeDataModel } from '../../../../../models/types.model';

export interface IItemProps {
	id: number;
	label: string;
	type?: TypeDataModel;
	sellingPrice?: number;
	purchaseCost?: number;
	icons?: IIconProps[];
}

export interface IDataProps {
	item: IItemProps;
	addMoreCb?: (...args: any[]) => void;
	subItems: Array<IItemProps>;
}

export interface ITreeBodyProps {
	treeData: Array<IDataProps>;
}

export const TreeBody = (props: ITreeBodyProps) => {
	const [expanded, setExpanded] = React.useState<string[]>([]);

	const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	useEffect(() => {
		if (props.treeData?.length > 0) {
			setExpanded([props.treeData[0].item?.id?.toString()]);
		}
	}, [props.treeData]);

	return (
		<>
			<TreeView
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				expanded={expanded}
				onNodeToggle={handleToggle}
				multiSelect>
				{props.treeData?.length
					? props.treeData.map((mainItem: IDataProps, index: number) => (
							<TreeItem
								key={index}
								nodeId={mainItem?.item?.id?.toString()}
								label={
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
										<span>{mainItem.item.label}</span>
										{mainItem?.item?.type?.name !== 'Man-day' &&
											mainItem.item?.icons?.map((iconProps: IIconProps, index: number) => {
												return iconProps ? (
													<Icon
														inputText={iconProps.inputText}
														icon={iconProps.icon}
														iconCb={(event: any) => {
															iconProps.iconCb ? iconProps.iconCb(mainItem.item.id) : null;
															event.stopPropagation();
														}}
														isIconChecked={iconProps.isIconChecked}
													/>
												) : null;
											})}
									</div>
								}>
								{mainItem.subItems.length
									? mainItem.subItems.map((subItem: IItemProps, indx: number) => (
											<TreeItem
												key={indx}
												nodeId={subItem.label + subItem.id?.toString()}
												label={
													<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<span style={{ fontSize: '14px', color: '#000' }}>{subItem.label}</span>
															{mainItem?.item?.type?.name !== 'Man-day' &&
																subItem?.icons?.map((iconProps: IIconProps, index: number) => {
																	return iconProps ? (
																		<Icon
																			inputText={iconProps.inputText}
																			icon={iconProps.icon}
																			iconCb={(event: any) => {
																				iconProps.iconCb ? iconProps.iconCb(subItem.id) : null;
																				event.stopPropagation();
																			}}
																			isIconChecked={iconProps.isIconChecked}
																		/>
																	) : null;
																})}
														</div>
														{subItem?.sellingPrice !== (null || undefined) ? (
															<span style={{ fontSize: '14px', color: '#000' }}>
																${subItem?.sellingPrice} | ${subItem?.purchaseCost}
															</span>
														) : null}
													</div>
												}
											/>
									  ))
									: null}
								{mainItem?.item?.type?.name !== 'Man-day' && mainItem?.addMoreCb ? (
									<TreeItem
										nodeId={'Add More' + mainItem.item.id}
										label={
											<IconButton
												color='primary'
												component='label'
												onClick={() => {
													mainItem.addMoreCb ? mainItem.addMoreCb(mainItem.item.id) : null;
												}}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<AddIcon style={{ fontSize: '14px' }}></AddIcon>
													<span style={{ fontSize: '14px' }}>{'Add More'}</span>
												</div>
											</IconButton>
										}
									/>
								) : null}
							</TreeItem>
					  ))
					: null}
			</TreeView>
		</>
	);
};
