import { useEffect, useState } from 'react';
import { fetchAllProjects, getProjectTypeAssociation } from '../../../services/project.service';
import {
	ProjectsDataModel,
	SubTypeDataModel,
	ProjectToTypesFullDetailsDataModel,
	ProjectToTypesFullDetailsResponseDataModel,
	ProjectModel,
	SubTypeValueDataModel,
} from '../../../models/project.model';

export const useProjects = (page: number, toggle: boolean, limit: number): ProjectsDataModel | undefined => {
	const [projects, setProjects] = useState<ProjectsDataModel>();

	const fetchAll = async () => {
		const projectsResponse = await fetchAllProjects('project/getAll?page=' + page + '&limit=' + limit);
		if (!projectsResponse.data?.success) return;
		setProjects(projectsResponse.data.data);
	};

	useEffect(() => {
		fetchAll();
	}, [page, toggle]);

	return projects;
};

export const useProjectsList = (toggle: boolean): Array<ProjectModel> | undefined => {
	const [projects, setProjects] = useState<Array<ProjectModel>>();

	const fetchAll = async () => {
		const projectsResponse = await fetchAllProjects('project/all');

		if (!projectsResponse.data?.success) return;
		setProjects(projectsResponse.data.data);
	};

	useEffect(() => {
		fetchAll();
	}, [toggle]);

	return projects;
};

export const useTypesForProject = (
	projectId: number | undefined,
	toggle?: boolean
): Array<ProjectToTypesFullDetailsDataModel> | undefined => {
	const [projectToTypes, setProjectToTypes] = useState<Array<ProjectToTypesFullDetailsDataModel>>();

	const fetchTypesForProject = async () => {
		if (projectId) {
			const projectToTypesResponse = await getProjectTypeAssociation('project/typeAssociation/getAll', projectId);
			if (!projectToTypesResponse.data?.success) return;

			const responseData: Array<ProjectToTypesFullDetailsResponseDataModel> = projectToTypesResponse.data.data;
			const projectToTypesResult: Array<ProjectToTypesFullDetailsDataModel> = [];
			const typeDict: any = {};
			const subTypeDict: any = {};
			for (let i = 0; i < responseData.length; i++) {
				// Prepare typeDict
				if (!typeDict[responseData[i].typeId]) {
					typeDict[responseData[i].typeId] = {
						projectToTypesId: responseData[i].projectToTypesId,
						clientId: responseData[i].clientId,
						clientName: responseData[i].clientName,
						typeName: responseData[i].typeName,
						subTypeIds: [],
					};
				}
				typeDict[responseData[i].typeId].subTypeIds.push(responseData[i].subTypeId);

				// Prepare subTypeDict
				if (responseData[i].subTypeId && responseData[i].subTypeValue) {
					const subTypeValue: SubTypeValueDataModel = {
						id: responseData[i].subTypeValueId,
						value: responseData[i].subTypeValue,
						sellingPrice: responseData[i].subTypeSellingPrice,
						purchaseCost: responseData[i].subTypePurchaseCost,
					};
					if (!subTypeDict[responseData[i].typeId]) {
						subTypeDict[responseData[i].typeId] = {};
					}
					if (!subTypeDict[responseData[i].typeId][responseData[i].subTypeId]) {
						subTypeDict[responseData[i].typeId][responseData[i].subTypeId] = {
							subTypeName: responseData[i].subTypeName,
							subTypeValuesList: [],
						};
					}
					subTypeDict[responseData[i].typeId][responseData[i].subTypeId].subTypeValuesList.push(subTypeValue);
				}
			}
			for (const typeId in typeDict) {
				const value = typeDict[typeId];

				// Form the array of subTypes associated to this type
				let subTypes: Array<SubTypeDataModel> = [];
				for (const subTypeId in subTypeDict[typeId]) {
					subTypes.push({
						subTypeId: parseInt(subTypeId),
						subTypeName: subTypeDict[typeId][subTypeId].subTypeName,
						subtypeValuesList: subTypeDict[typeId][subTypeId].subTypeValuesList,
					});
				}

				// Form the return object
				projectToTypesResult.push({
					id: value.projectToTypesId,
					projectId: responseData[0].projectId,
					clientId: value.clientId,
					clientName: value.clientName,
					typeName: value.typeName,
					typeId: parseInt(typeId),
					subTypes: subTypes,
				});
			}
			setProjectToTypes(projectToTypesResult);
		}
	};
	useEffect(() => {
		fetchTypesForProject();
	}, [projectId, toggle]);

	return projectToTypes;
};
