import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { deleteSubType, deleteSubValue, fetchAllSubValuesBySubType } from '../../services/type.service';
import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { SubTypeDataModel, SubValueDataModel } from '../../models/types.model';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { IDataProps, IItemProps } from '../../components/Layout/Panel/views/components/TreeBody';
import { ITreeViewProps } from '../../components/Layout/Panel/views/Tree';
import { AddEditNewSubTypeDialog } from '../../components/Dialog/AddEditNewSubTypeDialog';
import { AddNewSubValueDialog } from '../../components/Dialog/AddNewSubValueDialog';
import DeleteConfirmationDialog from '../../components/Dialog/DeleteConfirmationDialog';

type TSubTypeWithValue = SubTypeDataModel & {
	values: Array<SubValueDataModel> | [];
};

export function SubTypesPanel(props: any) {
	const [isDialogEditMode, setDialogEditMode] = useState<boolean>(false);
	const [showSubTypeDialog, setShowSubTypeDialog] = useState<boolean>(false);
	const [showSubValueDialog, setShowSubValueDialog] = useState<boolean>(false);
	const [subTypesData, setSubTypesData] = useState<Array<TSubTypeWithValue>>(props?.selectedType?.subtypes || []);
	const [selectedSubType, setSelectedSubType] = useState<SubTypeDataModel>();
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
	const [subtypeToDelete, setSubtypeToDelete] = useState<{ subValueId: number | null; subtypeNameId: number | null }>({
		subValueId: null,
		subtypeNameId: null,
	});

	const onRefreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
	};

	const onDeleteSubValue = async ({
		subValueId,
		subtypeNameId,
	}: {
		subValueId: number | null;
		subtypeNameId: number | null;
	}): Promise<void> => {
		try {
			if (subValueId !== null && subtypeNameId !== null) {
				const noOfSubValue: any = await fetchAllSubValuesBySubType(
					'type/subtype/value/getall?subtypeId=' + subtypeNameId
				);
				if (noOfSubValue?.data?.success && noOfSubValue?.data?.data?.length > 1) {
					const subTypeValueResult: any = await deleteSubValue('type/subtype/value?subValueId=' + subValueId);
					if (subTypeValueResult?.data?.success) {
						toast.success(subTypeValueResult.data.message);
						onRefreshPanel();
					} else {
						toast.error(subTypeValueResult.data.message);
					}
				} else {
					toast.error('Cannot delete subvalue if it is the only one.');
				}
			}
			if (subValueId === null && subtypeNameId !== null) {
				const subTypeNameResult: any = await deleteSubType('type/subtype/name?subTypeId=' + subtypeNameId);

				if (subTypeNameResult?.data?.success) {
					toast.success(subTypeNameResult.data.message);
					onRefreshPanel();
				} else {
					toast.error(subTypeNameResult.data.message);
				}
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
		setSubtypeToDelete({ subValueId: null, subtypeNameId: null });
		setShowDeleteConfirmation(false);
	};

	const generateTreeViewData = () => {
		let data: Array<IDataProps> = [];

		if (subTypesData.length) {
			subTypesData.map((mainItem: TSubTypeWithValue, index: number) => {
				let subList: Array<IItemProps> = [];
				if (mainItem.values.length) {
					mainItem.values.map((subItem: SubValueDataModel, index: number) => {
						subList.push({
							id: subItem.id,
							label: subItem.name,
							icons: [
								{
									icon: EIcon.DELETE,
									iconCb: () => {
										setSubtypeToDelete({ subValueId: subItem.id, subtypeNameId: mainItem.id }),
											setShowDeleteConfirmation(true);
									},
								},
							],
						});
					});
				}
				data.push({
					item: {
						id: mainItem.id,
						label: mainItem.name,
						type: props?.selectedType,
						icons: [
							{
								icon: EIcon.EDIT,
								iconCb: () => {
									setSelectedSubType((prevState) => ({ ...mainItem }));
									setDialogEditMode((prevState) => !prevState);
									setShowSubTypeDialog((prevState) => !prevState);
								},
							},
							{
								icon: EIcon.DELETE,
								iconCb: () => {
									setSelectedSubType((prevState) => ({ ...mainItem }));
									setSubtypeToDelete({ subValueId: null, subtypeNameId: mainItem.id }), setShowDeleteConfirmation(true);
								},
							},
						],
					},
					addMoreCb: () => {
						setSelectedSubType((prevState) => ({ ...mainItem }));
						setShowSubValueDialog((prevState) => !prevState);
					},
					subItems: [...subList],
				});
			});
		}

		return data;
	};

	const subTypesProps: ITreeViewProps = {
		header: {
			text: 'Sub Type',
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => setShowSubTypeDialog((prevState) => !prevState),
				},
			],
		},
		placeholderText: 'No-subtypes added yet',
		treeData: generateTreeViewData(),
	};

	const subTypesPanelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [subTypesProps],
	};

	useEffect(() => {
		setSubTypesData(props?.selectedType?.subtypes || []);
	}, [props?.selectedType?.id, toggleForReload]);

	return (
		<>
			<Panel panelProps={subTypesPanelProps}></Panel>
			<AddEditNewSubTypeDialog
				isEditMode={isDialogEditMode}
				typeId={props?.selectedType?.id}
				subtype={selectedSubType}
				show={showSubTypeDialog}
				onRefreshPanel={onRefreshPanel}
				close={() => {
					setShowSubTypeDialog(false);
					setSelectedSubType(undefined);
					setDialogEditMode(false);
				}}
			/>
			<AddNewSubValueDialog
				subtype={selectedSubType}
				show={showSubValueDialog}
				onRefreshPanel={onRefreshPanel}
				close={() => {
					setShowSubValueDialog(false);
					setSelectedSubType(undefined);
				}}
			/>

			<DeleteConfirmationDialog
				open={showDeleteConfirmation}
				onClose={() => setShowDeleteConfirmation(false)}
				onConfirm={() => onDeleteSubValue(subtypeToDelete)}
				entity={'sub-type'}
			/>
		</>
	);
}
