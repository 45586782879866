import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { InvoiceModel } from '../../models/invoice.model';
import { Typography } from '@mui/material';

export const InvoiceTable = (props: any) => {
	const { data, loading, single } = props;

	const [invoice, setInvoice] = useState<InvoiceModel>([]);

	const invoiceColumns = [
		{
			field: 'col1',
			headerName: 'Payment Progress Num',
			flex: 2,
			width: 200,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col2',
			headerName: 'Payment ID',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col3',
			headerName: 'FromDate',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col4',
			headerName: 'ToDate',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col5',
			headerName: 'Amount',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col6',
			headerName: 'Tax',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col7',
			headerName: 'Invoice Number',
			width: 150,
			flex: 1.5,
			align: 'center',
			headerAlign: 'center',
		},
	];

	useEffect(() => {
		if (data) {
			formatInvoiceData(data);
		}
	}, [data]);

	const formatInvoiceData = async (data: InvoiceModel[]) => {
		let tmpList = data.map((info: InvoiceModel, index: number) => {
			const row = {
				id: info.id,
				col1: info.progressPaymentNumber ? info.progressPaymentNumber : 0,
				col2: info?.paymentId ? info?.paymentId : 0,
				col3: info.fromDate,
				col4: info.toDate,
				col5: info.amount,
				col6: info.tax,
				col7: info.invoiceNumber ? info.invoiceNumber : 0,
			};
			return row;
		});
		setInvoice(tmpList);
	};

	const emptyListComponent = () => {
		return (
			<Typography textAlign={'center'} sx={{ marginTop: '50px' }}>
				No invoice record found!
			</Typography>
		);
	};

	return (
		<div style={{ display: 'flex', alignContent: 'center', marginTop: '20px', flex: 1 }}>
			<div style={{ width: '100%' }}>
				<DataGrid
					rows={invoice}
					columns={invoiceColumns}
					loading={loading}
					hideFooterPagination={true}
					autoHeight
					hideFooter={single || true}
					components={{
						NoRowsOverlay: emptyListComponent,
					}}
				/>
			</div>
		</div>
	);
};
