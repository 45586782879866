import axios from './api';

export const fetchAllProjects = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const getProjectDetails = async (url: string, id: number) => {
	const response = await axios.get(url + '?projectId=' + id);

	return response;
};

export const getProjectTypeAssociation = async (url: string, id: number) => {
	const response = await axios.get(url + '?projectId=' + id);

	return response;
};

export const getProjectSubTypeAssociation = async (url: string) => {
	const response = await axios.get(url);

	return response;
};

export const createProject = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const updateProject = async (url: string, payload: any) => {
	const response = await axios.put(url, payload);

	return response;
};

export const addTypeToProject = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const addSubTypesToProject = async (url: string, payload: any) => {
	const response = await axios.post(url, payload);

	return response;
};

export const deleteProjectToType = async (url: string) => {
	const response = await axios.delete(url);

	return response;
};

export const downloadExcel = async (
	projectId: number,
	clientId: number,
	gstValue: number,
	toDate: any,
	fromDate: any,
	isClaim: boolean
) => {
	const response = await axios({
		url: `/project/downloadExcel`,
		method: 'POST',
		responseType: 'blob',
		data: {
			projectId,
			clientId,
			gstValue,
			endDate: toDate,
			startDate: fromDate,
			isClaim,
		},
	});

	return response;
};

export const downloadAnalytics = async (userId: number, uniqueWorkerDetails: any, toDate: any, fromDate: any) => {
	const response = await axios({
		url: `/project/downloadAnalytics`,
		method: 'POST',
		responseType: 'blob',
		data: {
			userId,
			uniqueWorkerDetails,
			endDate: toDate,
			startDate: fromDate,
		},
	});

	return response;
};

export const fetchAllProjectAnalytics = async (fromDate: string, toDate: string, page?: number, limit?: number) => {
	const response = await axios.get(`project/get-project-analytics`, {
		params: {
			toDate: toDate,
			fromDate: fromDate,
		},
	});

	return response;
};

export const getAllActiveProjects = async () => {
	const response = await axios.get(`project/get-all-active-projects`);

	return response;
};
