import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { IDataProps, IItemProps } from '../../components/Layout/Panel/views/components/TreeBody';
import { ITreeViewProps } from '../../components/Layout/Panel/views/Tree';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { ISegmentProps, spaceDelimiter } from '../../components/Layout/Panel/views/Segment';
import { AddTypeDialog } from '../../components/Dialog/AddTypeDialog';
import { EditSubTypeDialog } from '../../components/Dialog/EditSubTypeDialog';
import { useTypesForProject } from './hooks/projects';
import {
	SubTypeDataModel,
	SubTypeValueDataModel,
	ProjectToTypesFullDetailsDataModel,
} from '../../models/project.model';
import { deleteProjectToType } from '../../services/project.service';
import DeleteConfirmationDialog from '../../components/Dialog/DeleteConfirmationDialog';

export const TypeSubTypePanel = (props: any) => {
	const [selectedProjectType, setSelectedProjectType] = useState<ProjectToTypesFullDetailsDataModel | null>();
	const [subTypesList, setSubTypesList] = useState<Array<SubTypeDataModel>>([]);
	const [showAddTypeDialog, setShowAddTypeDialog] = useState<boolean>(false);
	const [showEditSubTypeDialog, setShowEditSubTypeDialog] = useState<boolean>(false);
	// Just alternates to trigger reload
	const [toggleForTypeReload, setToggleForTypeReload] = useState<boolean>(false);

	const [showDeleteTypeConfirmation, setShowDeleteTypeConfirmation] = useState<boolean>(false);
	const [typeToDelete, setTypeToDelete] = useState<number | null>(null);

	const onRefreshTypePanel = () => {
		// Purely for refreshing the page
		setToggleForTypeReload(!toggleForTypeReload);
	};

	const projectTypesModel: Array<ProjectToTypesFullDetailsDataModel> | undefined = useTypesForProject(
		props.selectedProject?.id,
		toggleForTypeReload
	);

	const handleDelete = async (projectToTypeId: number) => {
		try {
			const result: any = await deleteProjectToType('project/typeAssociation?projectToTypeId=' + projectToTypeId);

			if (result.data.success) {
				toast.success(result.data.message);
				onRefreshTypePanel();
			} else {
				toast.error(result.data.message);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
		setTypeToDelete(null);
		setShowDeleteTypeConfirmation(false);
	};

	const getTypeData = () => {
		let result = [];

		if (projectTypesModel) {
			for (let i = 0; i < projectTypesModel?.length; i++) {
				result.push({
					text: `${projectTypesModel[i].typeName} ${spaceDelimiter}${projectTypesModel[i].clientName}`,
					textClickCb: () => {
						setSelectedProjectType(projectTypesModel[i]);
					},
					isTextSelected: projectTypesModel[i].typeId === selectedProjectType?.typeId,
					icons: [
						{
							icon: EIcon.DELETE,
							iconCb: async () => {
								await handleDelete(projectTypesModel[i].id);
							},
						},
					],
				});
			}
		}

		return result;
	};
	const getAllSubTypes = async () => {
		try {
			if (selectedProjectType) {
				setSubTypesList(selectedProjectType.subTypes);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	const onAddTypeIconClick = () => {
		setShowAddTypeDialog(true);
	};

	const onEditSubTypeIconClick = () => {
		setShowEditSubTypeDialog(true);
	};

	const getSubTypeData = () => {
		let data: Array<IDataProps> = [];

		if (subTypesList?.length) {
			subTypesList.map((subType: SubTypeDataModel, index: number) => {
				let subList: Array<IItemProps> = [];
				if (subType.subTypeId) {
					if (subType?.subtypeValuesList?.length) {
						subType.subtypeValuesList.map((subItem: SubTypeValueDataModel, index: number) => {
							subList.push({
								id: subItem.id,
								label: subItem.value!,
								sellingPrice: subItem?.sellingPrice ? subItem?.sellingPrice : 0,
								purchaseCost: subItem?.purchaseCost ? subItem?.purchaseCost : 0,
							});
						});
					}

					data.push({
						item: {
							id: subType.subTypeId,
							label: subType.subTypeName,
						},
						subItems: [...subList],
					});
				}
			});
		}

		return data;
	};

	const typeProps: ISegmentProps = {
		header: {
			text: `Types${spaceDelimiter}Clients`,
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						onAddTypeIconClick();
					},
				},
			],
		},
		placeholderText: 'No types set',
		data: getTypeData(),
	};

	const subTypeProps: ITreeViewProps = {
		header: {
			text: 'Sub Types',
			icons: [
				{
					icon: EIcon.EDIT,
					iconCb: () => {
						onEditSubTypeIconClick();
					},
				},
			],
		},
		placeholderText: 'No sub-types',
		treeData: getSubTypeData(),
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [typeProps, subTypeProps],
	};

	useEffect(() => {
		if (projectTypesModel?.length) {
			setSelectedProjectType(projectTypesModel[0]);
		} else {
			setSelectedProjectType(null);
			setSubTypesList([]);
		}
	}, [projectTypesModel]);

	useEffect(() => {
		getAllSubTypes();
	}, [selectedProjectType]);

	return (
		<>
			<Panel panelProps={panelProps}></Panel>
			<AddTypeDialog
				selectedProjectId={props.selectedProject?.id}
				show={showAddTypeDialog}
				onRefreshTypePanel={onRefreshTypePanel}
				close={() => {
					setShowAddTypeDialog(false);
				}}></AddTypeDialog>
			<EditSubTypeDialog
				selectedProjectId={props.selectedProject?.id}
				selectedProjectType={selectedProjectType}
				selectedSubTypeList={subTypesList}
				show={showEditSubTypeDialog}
				onRefreshTypePanel={onRefreshTypePanel}
				close={() => {
					setShowEditSubTypeDialog(false);
				}}></EditSubTypeDialog>

			<DeleteConfirmationDialog
				open={showDeleteTypeConfirmation}
				onClose={() => setShowDeleteTypeConfirmation(false)}
				onConfirm={async () => await handleDelete(typeToDelete)}
				entity={'type'}
			/>
		</>
	);
};
