import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import type { GridAlignment } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { AddPaymentDialog } from '../../components/Dialog/AddPaymentDialog';
import { TaxCalculationDialog } from '../../components/Dialog/TaxCalculationDialog';
import { fetchAllPayments } from '../../services/payment.service';
import { getLatestClaimRefs } from '../../services/invoice.service';
import moment from 'moment';
import { ClaimsTable } from '../../components/Tables/ClaimsTable';
import { ClaimModel } from '../../models/claim.model';

export function ManagePayments() {
	const [pageSize, setPageSize] = useState<number>(10);
	const [showPaymentDialog, setPaymentDialog] = useState<boolean>(false);
	const [showTaxDialog, setTaxDialog] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [paymentsTotalLength, setPaymentsTotalLength] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [paymentsData, setPaymentsData] = useState<any>([]);
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);
	const [claimDetails, setClaimDetails] = useState<ClaimModel[]>([]);

	const handleDialogClose = () => {
		setPaymentDialog((prevState) => false);
	};

	const paymentColumns = [
		{
			field: 'col1',
			headerName: 'Index',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col2',
			headerName: 'Date',
			width: 180,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col3',
			headerName: 'Project',
			width: 250,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col4',
			headerName: 'Client',
			width: 280,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col5',
			headerName: 'Amount (bef. GST)',
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col6',
			headerName: 'GST %',
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'col7',
			headerName: 'Claim Ref',
			width: 180,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
	];

	const loadDataToGrid = async () => {
		try {
			setLoading(true);
			const result: any = await fetchAllPayments(page, pageSize);
			const claimResult: any = await getLatestClaimRefs();
			setPaymentsData([]);

			if (claimResult?.success) {
				setClaimDetails(claimResult.data);
			}

			if (result?.success) {
				if (result?.data?.payments?.length) {
					let tmpList: any = [];
					let startIndex = page * pageSize + 1;

					setPaymentsTotalLength(Number(result?.data?.totalItems));

					tmpList = result?.data?.payments?.map((info: any, index: number) => {
						const row = {
							id: info?.id,
							projectId: info.projectId,
							clientId: info.clientId,
							col1: startIndex,
							col2: moment(info?.date).format('DD/MM/YYYY'),
							col3: info?.projectName,
							col4: info?.clientName,
							col5: info?.amount,
							col6: info?.gst + '%',
							col7: info?.claimRef,
						};
						startIndex = startIndex + 1;

						return row;
					});
					setPaymentsData([...tmpList]);
				}
			} else {
				toast.error(result?.data?.message);
			}
			setLoading(false);
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		loadDataToGrid();
	}, [page, pageSize, toggleForReload]);

	const handlePageChange = (params: any) => {
		setPage(params);
	};

	return (
		<Box style={{ flex: 1, height: '100%' }}>
			<Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<h2>All Payments</h2>
				<Box>
					<Button onClick={() => setTaxDialog(true)} color='primary' variant='contained' sx={{ marginRight: '10px' }}>
						Calculate TAX
					</Button>
					<Button
						onClick={() => setPaymentDialog((prevState) => !prevState)}
						color='primary'
						variant='contained'
						startIcon={<AddIcon />}>
						Create Payment
					</Button>
				</Box>
			</Box>
			<Box sx={{ height: '40%' }}>
				<DataGrid
					rows={paymentsData}
					columns={paymentColumns}
					loading={loading}
					rowCount={paymentsTotalLength}
					pageSize={pageSize}
					rowsPerPageOptions={[10, 20, 50, 100]}
					pagination
					paginationMode='server'
					onPageSizeChange={(size) => setPageSize(size)}
					onPageChange={handlePageChange}
				/>
			</Box>

			<Box sx={{ height: '40%', marginTop: '2%' }}>
				<h2>Outstanding Claims</h2>
				<ClaimsTable data={claimDetails} loading={loading} />
			</Box>
			<AddPaymentDialog
				show={showPaymentDialog}
				close={() => {
					handleDialogClose();
					setToggleForReload(!toggleForReload);
				}}
			/>
			<TaxCalculationDialog show={showTaxDialog} onClose={() => setTaxDialog(false)} />
		</Box>
	);
}
