import React from 'react';
import { Typography } from '@mui/material';
import { TextLine } from './TextLine';
import { IIconProps } from './Icon';

export interface IDataProps {
	text: string;
	textClickCb?: () => void;
	isTextSelected?: boolean;
	icons?: IIconProps[];
}

export interface ISegmentBodyProps {
	data: Array<IDataProps>;
}

export const SegmentBody = (props: ISegmentBodyProps) => {
	return (
		<Typography variant='body2' component={'div'}>
			{props.data?.map((data: IDataProps, index: number) => {
				return data ? (
					<div className='panelSegmentBody' key={index}>
						<TextLine
							inputText={data.text}
							textClickCb={data.textClickCb}
							isTextSelected={data.isTextSelected}
							icons={data.icons}
						/>
					</div>
				) : null;
			})}
		</Typography>
	);
};
