import React, { Fragment } from 'react';

import { SegmentView, ISegmentProps } from './views/Segment';
import { TreeView, ITreeViewProps } from './views/Tree';

export interface IPanelContainerProps {
	width?: string;
	height?: string;
	padding?: string;
	margin?: string;
}

export interface IPanelProps {
	container: IPanelContainerProps;
	segments: Array<ISegmentProps | ITreeViewProps>;
}

export const Panel = (props: { panelProps: IPanelProps }) => {
	const isSegment = (object: any): object is ISegmentProps => {
		return 'data' in object || 'dataElement' in object;
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				border: '1px solid #D3D3D3',
				padding: props.panelProps.container.padding ? props.panelProps.container.padding : '0px',
				margin: props.panelProps.container.margin ? props.panelProps.container.margin : '0px',
				borderRadius: '8px',
				width: props.panelProps.container.width || '100%',
				height: props.panelProps.container.height || '100%',
				maxHeight: props.panelProps.container.height || '100%',
			}}>
			<div
				style={{
					display: props.panelProps.segments.length > 1 ? 'flex' : 'block',
					flexDirection: 'row',
					height: 'inherit',
					width: 'inherit',
				}}>
				{props.panelProps.segments.map((segment: ISegmentProps | ITreeViewProps, index) => {
					return (
						// Use Fragment just so we can insert attribute "key"
						<Fragment key={index}>
							{isSegment(segment) ? (
								<SegmentView segmentProps={segment}></SegmentView>
							) : (
								<TreeView treeProps={segment}></TreeView>
							)}
							{/* Draw line except for the last segment */}
							{props.panelProps.segments.length > 1 && index < props.panelProps.segments.length - 1 && (
								<hr style={{ margin: '8px', color: '#D3D3D3' }} />
							)}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};
