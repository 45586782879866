import { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import { Button, FormControl, Box, MenuItem, Grid, Typography, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { downloadExcel } from '../../services/project.service';
import { ClientModel } from '../../models/client.model';
import { fetchLastGSTValue } from '../../services/common.service';
import { useProjectsList, useTypesForProject } from '../Project/hooks/projects';
import { ProjectModel, ProjectToTypesDataModel } from '../../models';
import { getClientDetails } from '../../services/client.service';
import { fetchLatestClaimRef } from '../../services/payment.service';
import { ClaimModel } from '../../models/claim.model';
import { ClaimsTable } from '../../components/Tables/ClaimsTable';
import { ProjectToTypesFullDetailsDataModel } from '../../models/project.model';
import GenerateClaimDialog from '../../components/Dialog/GenerateClaimDialog';
import { getOffset } from '../../utils/utilityFunctions';

export function ManageReport() {
	// State Variables
	const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(undefined);
	const [selectedClientStr, setSelectedClientStr] = useState<string | undefined>('');
	const [gstValue, setGSTValue] = useState<number>(7);
	const [toDate, setToDate] = useState<string>();
	const [fromDate, setFromDate] = useState<string>();
	const [selectedClientDetails, setSelectedClientDetails] = useState<ClientModel>();
	const [latestClaimDetails, setLatestClaimDetails] = useState<ClaimModel[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [warningMessage, setWarningMessage] = useState<string>('');
	const [reportDisabled, setReportDisabled] = useState<boolean>(true);
	const [claimDisabled, setClaimDisabled] = useState<boolean>(true);
	const [clientsList, setClientsList] = useState<ProjectToTypesDataModel[] | undefined>([]);
	const [showModal, setShowModal] = useState<boolean>(false);

	const projectsList = useProjectsList(true);
	const projectToTypesList = useTypesForProject(selectedProjectId);
	//Function to handle downloading the report
	const download = async (isClaim: boolean) => {
		if (!selectedProjectId) {
			toast.error('Please select project');
			return;
		}

		if (!selectedClientStr) {
			toast.error('Please select client');
			return;
		}

		if (!gstValue || gstValue <= 0) {
			toast.error('Please enter valid GST value');
			return;
		}
		if (fromDate && toDate) {
			setLoading(true);
			const clientId = parseInt(selectedClientStr);
			const excel = await downloadExcel(
				selectedProjectId,
				clientId,
				gstValue,
				moment(toDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
				moment(fromDate)
					.startOf('day')
					.utcOffset(getOffset() * -1)
					.format('YYYY-MM-DD HH:mm:ss'),
				isClaim
			);
			let filenamePrefix = isClaim ? 'report' : 'preview';
			if (excel.status === 200) {
				const a = document.createElement('a');
				a.href = URL.createObjectURL(new Blob([excel.data], { type: 'application/xlsx' }));
				a.download = `${filenamePrefix}-${moment(fromDate).format('DDMMYY')}-${moment(toDate).format('DDMMYY')}.xlsx`;
				a.click();
			}
		}
		setLoading(false);
	};

	//Prepare Client's list
	useEffect(() => {
		if (projectToTypesList && projectToTypesList?.length > 0) {
			const uniqueArray = projectToTypesList.reduce((acc: any, obj: ProjectToTypesFullDetailsDataModel) => {
				if (!acc.some((item: any) => item.clientId === obj.clientId)) {
					acc.push(obj);
				}
				return acc;
			}, []);

			setClientsList(uniqueArray);
		}
	}, [selectedProjectId, projectToTypesList]);

	// Load client details
	useEffect(() => {
		if (selectedClientStr) {
			const loadClientDetails = async () => {
				const clientId = parseInt(selectedClientStr);
				const result = await getClientDetails(clientId);

				if (result?.success) {
					setSelectedClientDetails({ ...result?.data });
				}
			};

			loadClientDetails();
		}
	}, [selectedClientStr]);

	// Load latest claim details
	useEffect(() => {
		const loadClaimDetails = async () => {
			let claimInfo = await fetchLatestClaimRef(selectedProjectId, selectedClientDetails?.id);
			if (claimInfo?.success) {
				setLatestClaimDetails(claimInfo.data);
			}
		};
		if (selectedProjectId && selectedClientDetails) {
			loadClaimDetails();
		}
	}, [selectedClientDetails]);

	// Function to get latest global GST value
	const getGSTValue = async () => {
		const gstValueResult = await fetchLastGSTValue();
		if (gstValueResult?.success) {
			setGSTValue(gstValueResult?.data?.value);
		}
	};

	//Fetch latest global gst value
	useLayoutEffect(() => {
		getGSTValue();
	}, []);

	// Load dates according to the latest claim (if available) or invoiceCutOffDate
	useEffect(() => {
		const currentDate = moment(); // Get the current date
		const invoiceCutOffDate = selectedClientDetails?.invoiceCutOffDate;
		// Construct invoiceCutOffDate string if available
		let invoiceCutOffDateString = null;
		if (invoiceCutOffDate) {
			invoiceCutOffDateString = `${currentDate.year()}-${currentDate.format('MM')}-${invoiceCutOffDate
				.toString()
				.padStart(2, '0')}`;
		}
		if (latestClaimDetails.length > 0) {
			const latestClaimToDate = moment(latestClaimDetails[0].toDate);
			if (
				moment(invoiceCutOffDateString).isAfter(currentDate) ||
				latestClaimToDate.add(1, 'day').isAfter(invoiceCutOffDateString)
			) {
				setToDate(currentDate.subtract(1, 'day').format('YYYY-MM-DD'));
			} else {
				setToDate(invoiceCutOffDateString);
			}
			setFromDate(latestClaimToDate.add(1, 'day').format('YYYY-MM-DD'));
		} else {
			// Calculate toDate and fromDate when there are no latestClaimDetails
			let toMonthValue = currentDate.format('YYYY-MM'); // Get current year and month
			let toDayValue = null;
			if (invoiceCutOffDateString && moment(invoiceCutOffDateString).isSameOrBefore(currentDate, 'day')) {
				toDayValue = moment(invoiceCutOffDateString).format('DD');
			} else {
				toDayValue = currentDate.subtract(1, 'days').format('DD');
			}
			let dateInfo = `${toMonthValue}-${toDayValue}`;
			const prevDate = moment(dateInfo).subtract(1, 'month').add(1, 'day');
			setToDate(dateInfo);
			setFromDate(prevDate.format('YYYY-MM-DD'));
		}
	}, [latestClaimDetails, selectedClientStr]);

	// Function to validate date range
	function validateDates() {
		if (selectedProjectId && selectedClientStr) {
			if (fromDate && toDate && fromDate !== '' && toDate !== '') {
				if (dayjs(fromDate).isAfter(dayjs(toDate))) {
					toast.error('Please select a valid date range');
					setFromDate('');
					setReportDisabled(true);
					setClaimDisabled(true);
				} else {
					if (
						latestClaimDetails.length > 0 &&
						(dayjs(fromDate).isSame(dayjs(latestClaimDetails[0].toDate)) ||
							dayjs(fromDate).isBefore(dayjs(latestClaimDetails[0].toDate)))
					) {
						setReportDisabled(false);
						setClaimDisabled(true);
						setWarningMessage(
							'As the selected date range overlaps the latest claim date range, new claim will not be generated! You can generate a preview report.'
						);
					} else {
						setWarningMessage('');
						setReportDisabled(false);
						setClaimDisabled(false);
					}
				}
			}
		}
	}

	useEffect(() => {
		validateDates();
	}, [fromDate, toDate, selectedProjectId, selectedClientStr]);

	// Handlers for modal
	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handlePreview = () => {
		download(false);
		setShowModal(false);
	};

	const handleConfirm = () => {
		download(true);
		setShowModal(false);
	};

	// Render component
	return (
		<div>
			<Typography variant='h4'>Generate Report</Typography>
			<Grid item xs={12} paddingTop={2} paddingBottom={2}>
				<Divider textAlign='left'>Project Details</Divider>
			</Grid>
			<Grid container spacing={2} justifyContent='center' alignItems='center'>
				<Grid item md={2} xs={2}>
					<FormControl style={{ width: '100%', height: '100%' }}>
						<InputLabel>Project</InputLabel>
						<Select
							label='Project'
							variant='outlined'
							value={selectedProjectId}
							onChange={(e: any) => {
								setSelectedProjectId(e.target.value);
								setSelectedClientStr('');
								setSelectedClientDetails(undefined);
							}}>
							{projectsList?.length &&
								projectsList.map((projectInfo: ProjectModel, index: number) => (
									<MenuItem key={index} value={projectInfo?.id}>
										{projectInfo?.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={10} xs={10}>
					<FormControl style={{ width: '100%', height: '100%' }}>
						<InputLabel>Client</InputLabel>
						<Select
							label='Client'
							variant='outlined'
							value={selectedClientStr}
							disabled={selectedProjectId ? false : true}
							onClick={() => (selectedProjectId ? null : alert('Please select a project first!'))}
							onChange={(e: any) => {
								setSelectedClientStr(e.target.value);
							}}>
							{clientsList?.length &&
								clientsList?.map((clientInfo: ProjectToTypesDataModel, index: number) => (
									<MenuItem key={index} value={`${clientInfo?.clientId}`}>
										{clientInfo?.clientName}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				{selectedProjectId && selectedClientStr ? (
					<Grid container sx={{ width: '100%', marginLeft: 2 }} paddingTop={2} paddingBottom={2}>
						<Grid item xs={12} paddingTop={2} paddingBottom={2}>
							<Divider textAlign='left'>Latest Claim Details</Divider>
						</Grid>
						<Grid container sx={{ height: '150px' }} paddingTop={2} paddingBottom={2}>
							<ClaimsTable data={latestClaimDetails} loading={loading} single={true} />
						</Grid>
						<Grid item xs={12} paddingTop={2} paddingBottom={2}>
							<Divider textAlign='left'>Work Done Period</Divider>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ width: '100%', marginBottom: '30px' }}>
								{warningMessage !== '' ? (
									<Typography textAlign={'center'} color={'red'}>
										{warningMessage}
									</Typography>
								) : null}
							</Box>
						</Grid>
						<Grid item xs={12} sm={5}>
							<Box sx={{ width: '100%', height: '50px' }}>
								<TextField
									label='From (SGT)'
									type='date'
									sx={{ width: '100%' }}
									value={fromDate}
									onChange={(e) => setFromDate(e.target.value)}
									inputProps={{
										max: dayjs(toDate).format('YYYY-MM-DD'),
									}}
									InputLabelProps={{ shrink: true }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography variant='subtitle1' textAlign={'center'} sx={{ marginTop: '15px' }}>
								to
							</Typography>
						</Grid>
						<Grid item xs={12} sm={5}>
							<Box style={{ display: 'flex' }}>
								<TextField
									label='To (SGT)'
									type='date'
									sx={{ width: '100%' }}
									value={toDate}
									onChange={(e) => {
										setToDate(e.target.value);
									}}
									inputProps={{
										min: dayjs(fromDate).format('YYYY-MM-DD'),
										max: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
									}}
									InputLabelProps={{ shrink: true }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} paddingTop={2} paddingBottom={2}>
							<Divider textAlign='left'>GST Value</Divider>
						</Grid>
						<Grid item xs={12}>
							<Box style={{ display: 'flex' }}>
								<TextField
									label='GST Value'
									sx={{ width: '41%' }}
									type='number'
									inputProps={{ min: 1 }}
									value={gstValue}
									onChange={(e) => setGSTValue(parseInt(e.target.value))}
								/>
							</Box>
						</Grid>
					</Grid>
				) : null}
				<Box
					sx={{
						display: 'flex',
						marginTop: '50px',
						marginLeft: 'auto',
						marginBottom: '20px',
						justifyContent: 'flex-end',
					}}>
					<Button
						variant='outlined'
						size='small'
						color='inherit'
						onClick={() => download(false)}
						disabled={reportDisabled}
						sx={{ marginRight: '10px' }}>
						Preview
					</Button>
					<Button variant='outlined' size='small' color='primary' onClick={handleOpenModal} disabled={claimDisabled}>
						Generate Claim
					</Button>
				</Box>
			</Grid>
			<GenerateClaimDialog
				open={showModal}
				onClose={handleCloseModal}
				onPreview={handlePreview}
				onConfirm={handleConfirm}
			/>
		</div>
	);
}
