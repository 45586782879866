export interface ProjectModel {
	id: number;
	name: string;
	status: number;
	createdAt: string;
	updatedAt: string;
}

export enum ProjectStatusType {
	InActive = 0,
	InProgress = 1,
	Completed = 2,
}

export interface ProjectsDataModel {
	currentPage: number;
	totalPages: number;
	totalItems: number;
	projects: Array<ProjectModel>;
}

export interface SubTypeValueDataModel {
	id: number;
	value: string;
	sellingPrice?: number;
	purchaseCost?: number;
	subtypeId?: number;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface SubTypeDataModel {
	subTypeId: number;
	subTypeName: string;
	subtypeValuesList: Array<SubTypeValueDataModel>;
}

export interface ProjectToTypesDataModel {
	id: number;
	projectId: number;
	typeId: number;
	typeName: string;
	subTypeId: number;
	clientId: number;
	clientName: string;
}

export interface TypeDataModel {
	id: number;
	name: string;
	createdAt: string;
	updatedAt: string;
}

export interface ProjectToTypesFullDetailsResponseDataModel {
	projectToTypesId: number;
	projectId: number;
	clientId: number;
	clientName: string;
	typeId: number;
	typeName: string;
	subTypeId: number;
	subTypeValueId: number;
	subTypeName: string;
	subTypeValue: string;
	subTypeSellingPrice: number;
	subTypePurchaseCost: number;
}

export interface ProjectToTypesFullDetailsDataModel {
	id: number;
	projectId: number;
	clientId: number;
	clientName: string;
	typeId: number;
	typeName: string;
	subTypes: Array<SubTypeDataModel>;
}
