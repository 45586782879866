import React, { useState, useRef } from 'react';
import type { TextFieldProps } from '@mui/material';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress } from '@mui/material';
import * as yup from 'yup';

import { addBlock, updateBlock } from '../../services/block.service';

export const AddEditBlockDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const numberRef = useRef<TextFieldProps>(null);
	const levelToRef = useRef<TextFieldProps>(null);
	const unitFromRef = useRef<TextFieldProps>(null);
	const unitToRef = useRef<TextFieldProps>(null);
	const blockSchema = yup.object().shape({
		precinctId: yup.number().required(),
		number: yup
			.string()
			.min(1, 'Minimum 1 character required for Block name.')
			.max(5, 'Block name cannot be of more than 5 characters long.')
			.required(),
		levelTo: yup.number().positive().required(),
		unitFrom: yup.number().positive().required(),
		unitTo: yup.number().positive().required(),
	});

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			precinctId: props.precinctId,
			number: numberRef?.current?.value,
			levelTo: levelToRef?.current?.value,
			unitFrom: unitFromRef?.current?.value,
			unitTo: unitToRef?.current?.value,
		};

		try {
			setLoading(true);
			blockSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await addBlock('block/add', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.onRefreshBlockPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const handleUpdate = async () => {
		let payload = {
			blockId: props.selectedBlock?.id,
			precinctId: props.precinctId,
			number: numberRef?.current?.value,
			levelTo: levelToRef?.current?.value,
			unitFrom: unitFromRef?.current?.value,
			unitTo: unitToRef?.current?.value,
		};

		try {
			setLoading(true);
			blockSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await updateBlock('block/update', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.onRefreshBlockPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props.isEditMode ? 'Edit' : 'Add'} Block</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<div>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props.isEditMode ? props.selectedBlock?.number : null}
						label='Number'
						variant='standard'
						inputRef={numberRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props.isEditMode ? props.selectedBlock?.levelTo : null}
						label='LevelTo'
						variant='standard'
						inputRef={levelToRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props.isEditMode ? props.selectedBlock?.unitFrom : null}
						label='unitFrom'
						variant='standard'
						inputRef={unitFromRef}
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '10px' }}>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props.isEditMode ? props.selectedBlock?.unitTo : null}
						label='unitTo'
						variant='standard'
						inputRef={unitToRef}
						fullWidth
					/>
				</div>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							onClick={props.isEditMode ? handleUpdate : handleSubmit}>
							{props.isEditMode ? 'Update' : 'Add'}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
