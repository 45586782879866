import axios from './api';

export const fetchAllPayments = async (page: number, limit: number) => {
	const response = await axios.get(`payment/getAll?page=${page}&limit=${limit}`);

	return response.data;
};

export const createPayment = async (payload: any) => {
	const response = await axios.post('payment/create', payload);

	return response.data;
};

export const fetchClaimRefs = async (projectId: number, clientId: number) => {
	const response = await axios.get('claim/claimRefsByProjectClient?projectId=' + projectId + '&clientId=' + clientId);

	return response;
};

export const fetchLatestClaimRef = async (projectId?: number, clientId?: number) => {
	const response = await axios.get(
		'claim//latestClaimByProjectClient?projectId=' + projectId + '&clientId=' + clientId
	);

	return response.data;
};

export const fetchTaxByDateRange = async (fromDate: any, toDate: any) => {
	const response = await axios.post('payment/calculate_tax', {
		fromDate,
		toDate,
	});

	return response.data;
};
