import React, { useState, FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Box, IconButton, Switch, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { EType } from '../../models/user-redux.model';
import { updateUserStatus, deleteUser } from '../../services/user.service';
import { useAppSelector } from '../../hooks/redux-hooks';
import { AddEditUserDialog } from '../../components/Dialog/AddEditUserDialog';
import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { UsersDataModel, UserModel } from '../../models/user-redux.model';
import { useUsers } from './hooks/users';
import { DefaultGridLayout, IDefaultGridLayoutProps } from '../../components/Layout/';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { ISegmentProps, spaceDelimiter } from '../../components/Layout/Panel/views/Segment';
import DeleteConfirmationDialog from '../../components/Dialog/DeleteConfirmationDialog';

export function ManageUser(props: any) {
	const DEFAULT_PAGE_SIZE = 20;
	const [page, setPage] = useState<number>(0);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selectedUserForEdit, setSelectedUserForEdit] = useState<UserModel>();
	const [showUserFormDialog, setUserFormDialog] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<UserModel>();
	const [userType, setUserType] = useState<string>(EType.admin);
	const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] = useState<boolean>(false);
	const [userToDelete, setUserToDelete] = useState<number | null>(null);
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		await changeUserStatus({ ...selectedUser });
	};

	const user = useAppSelector((state) => state.user.user);
	const changeUserStatusSchema = yup.object().shape({
		userId: yup.number().required(),
		status: yup.boolean().required(),
	});

	const usersDataModel: UsersDataModel | undefined = useUsers(page, userType, toggleForReload, DEFAULT_PAGE_SIZE);

	const handleClose = () => {
		setUserFormDialog((prevState) => false);
	};

	const refreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
	};

	const onUserAdded = () => {
		// Either one of the state change will refresh the page
		if (usersDataModel!.users.length == DEFAULT_PAGE_SIZE) {
			// We want to move to the next page if current page is already full
			setSelectedUser((prevState) => undefined);
			setPage((prevState) => prevState + 1);
		} else {
			refreshPanel();
		}
	};

	const changeUserStatus = async (userInfo: any) => {
		let payload = {
			userId: userInfo.id,
			status: !userInfo.workerEnabled,
		};

		changeUserStatusSchema
			.validate(payload)
			.then(async (value) => {
				const result: any = await updateUserStatus('change/workerStatus?adminId=' + user?.id, payload);

				if (result.data.success) {
					toast.success('status changed successfully');
					refreshPanel();
				} else {
					toast.error(result.data.message);
				}
			})
			.catch((err: any) => {
				toast.error(err?.response?.data?.message || err?.message);
			});
	};

	const getUserList = () => {
		let userList: UserModel[] = [];

		if (usersDataModel?.users.length) userList = usersDataModel.users;

		return [...userList];
	};

	const onDeleteUser = async (userId: number) => {
		try {
			const result: any = await deleteUser(userId);

			if (result?.success) {
				toast.success(result.message);
				refreshPanel();
			} else {
				toast.error(result.message);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
		setUserToDelete(null);
		setShowDeleteUserConfirmation(false);
	};

	const getUsersData = () => {
		let result = [];

		if (usersDataModel?.users) {
			let userList = getUserList();

			for (let i = 0; i < userList.length; i++) {
				result.push({
					text: userList[i].name,
					textClickCb: () => {
						let info = { ...userList[i] };
						setSelectedUser((prevState) => ({ ...info }));
					},
					isTextSelected: selectedUser?.id === userList[i]?.id,
					icons: [
						{
							icon: EIcon.DELETE,
							iconCb: () => (setUserToDelete(userList[i].id), setShowDeleteUserConfirmation(true)),
							textClickCb: () => {
								let info = { ...userList[i] };
								setSelectedUser((prevState) => ({ ...info }));
							},
						},
					],
				});
			}
		}

		return result;
	};

	const usersProps: ISegmentProps = {
		header: {
			text: 'User',
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						setUserFormDialog((prevState) => !prevState);
					},
				},
			],
		},
		placeholderText: 'No users created',
		data: getUsersData(),
		width: '40%',
		pagination: {
			currentPage: page,
			totalPages: usersDataModel?.totalPages as number,
			gotoNextPageCb: () => {
				if (usersDataModel) {
					const { totalPages, currentPage } = usersDataModel;

					if (currentPage < totalPages - 1) {
						setSelectedUser(undefined);
						setPage((prevState) => prevState + 1);
					}
				}
			},
			gotoPrevPageCb: () => {
				if (usersDataModel) {
					const { totalPages, currentPage } = usersDataModel;

					if (currentPage > 0) {
						setSelectedUser(undefined);
						setPage((prevState) => prevState - 1);
					}
				}
			},
		},
	};

	const createUserDetailLayout: FunctionComponent = () => {
		return (
			<Box sx={{ position: 'relative' }}>
				<Box
					sx={{
						height: '100px',
						width: '100%',
						background: 'linear-gradient(90deg, rgba(249,249,249,1) 0%, rgb(220,220,220, 1) 100%)',
					}}>
					<span style={{ display: 'table', float: 'right' }}>
						<Box sx={{ display: 'table-row', float: 'right' }}>
							<IconButton
								onClick={() => {
									if (selectedUser) {
										setIsEditMode((prevState) => true);
										setSelectedUserForEdit((prevState) => ({ ...selectedUser }));
										setUserFormDialog((prevState) => true);
									}
								}}
								color='primary'
								aria-label='edit'>
								<EditIcon fontSize='small'></EditIcon>
							</IconButton>
						</Box>
						{selectedUser && selectedUser?.type !== EType.admin ? (
							<Box sx={{ display: 'table-row' }}>
								{selectedUser?.workerEnabled ? 'Active' : 'Inactive'}
								<Switch
									checked={!!selectedUser?.workerEnabled}
									onChange={handleChange}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</Box>
						) : null}
					</span>
				</Box>
				<Box sx={{ position: 'relative' }}>
					<AccountCircleIcon
						style={{
							position: 'absolute',
							top: '-20px',
							height: '100px',
							width: '100px',
							borderRadius: '50px',
							color: '#696969',
						}}></AccountCircleIcon>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '110px' }}>
						<Box>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<p style={{ fontSize: '16px', fontWeight: 'bold' }}>{selectedUser?.name ? selectedUser?.name : ''}</p>
							</Box>
							{selectedUser?.email ? (
								<Box sx={{ display: 'flex' }}>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<MailIcon style={{ fontSize: '16px' }} />
										<p style={{ marginLeft: '5px', lineHeight: '5px', fontSize: '12px' }}>
											{selectedUser?.email ? selectedUser?.email : ''}
										</p>
									</Box>
									<Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
										<CallIcon style={{ fontSize: '16px' }} />
										<p style={{ marginLeft: '5px', lineHeight: '5px', fontSize: '12px' }}>
											{selectedUser?.mobileNumber ? selectedUser?.mobileNumber : ''}
										</p>
									</Box>
								</Box>
							) : null}
							{/* Display user type */}
							<p style={{ lineHeight: '10px', fontSize: '12px' }}>
								{selectedUser?.type ? (
									<span>
										<span style={{ fontWeight: 'bold' }}>Type: </span>
										{selectedUser.type}
									</span>
								) : (
									''
								)}
							</p>

							{/* Display user salary */}
							<p style={{ lineHeight: '10px', fontSize: '12px' }}>
								{selectedUser?.salary ? (
									<span>
										<span style={{ fontWeight: 'bold' }}>Salary: $</span>
										{selectedUser.salary}
									</span>
								) : (
									''
								)}
							</p>

							{/* Display other costs */}
							<p style={{ lineHeight: '10px', fontSize: '12px' }}>
								{selectedUser?.otherCosts ? (
									<span>
										<span style={{ fontWeight: 'bold' }}>Other costs: $</span>
										{selectedUser.otherCosts}
									</span>
								) : (
									''
								)}
							</p>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const userDetailsProps: ISegmentProps = {
		header: {
			text: `Name${spaceDelimiter}Email${spaceDelimiter}Phone${spaceDelimiter}Type`,
			icons: [
				{
					icon: EIcon.EDIT,
					iconCb: () => null,
				},
			],
		},
		placeholderText: 'Select a user',
		dataElement: selectedUser ? createUserDetailLayout : undefined,
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [usersProps, userDetailsProps],
	};

	const layoutProps: IDefaultGridLayoutProps = {
		panelOne: <Panel panelProps={panelProps}></Panel>,
	};

	useEffect(() => {
		if (usersDataModel?.users.length) {
			let userList = getUserList();

			if (!selectedUser) {
				setSelectedUser({ ...userList[0] });
			} else {
				const updatedUserInfo = userList.find((newUser) => newUser.id === selectedUser.id);

				if (updatedUserInfo) setSelectedUser((prevState) => ({ ...updatedUserInfo }));
			}
		}
	}, [usersDataModel]);

	useEffect(() => {
		setSelectedUser(undefined);
	}, [userType]);

	return (
		<>
			<Helmet>
				<title>{'Users'}</title>
			</Helmet>
			<Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h2>Manage Users</h2>
					<FormControl fullWidth sx={{ width: '300px', height: '50px' }}>
						<InputLabel>{'User Type'}</InputLabel>
						<Select
							onChange={(e: any) => {
								setUserType(e.target.value);
								setPage(0);
							}}
							value={userType}
							label='User Type'
							variant='outlined'>
							<MenuItem value={EType.admin}>{EType.admin}</MenuItem>
							<MenuItem value={EType.worker}>{EType.worker}</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<DefaultGridLayout layoutProps={layoutProps}></DefaultGridLayout>
				<AddEditUserDialog
					isEditMode={isEditMode}
					selectedUserForEdit={selectedUserForEdit}
					show={showUserFormDialog}
					refreshPanel={refreshPanel}
					close={() => {
						handleClose();
						setIsEditMode((prevState) => false);
						setSelectedUserForEdit((prevState) => undefined);
					}}
					onAdded={() => {
						onUserAdded();
					}}
				/>
			</Box>
			<DeleteConfirmationDialog
				open={showDeleteUserConfirmation}
				onClose={() => setShowDeleteUserConfirmation(false)}
				onConfirm={() => onDeleteUser(userToDelete)}
				entity={'user'}
			/>
		</>
	);
}
