import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import type { GridAlignment } from '@mui/x-data-grid';
import { Box, Select, MenuItem, FormControl, InputLabel, IconButton, ListSubheader, Collapse } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getFullUsersList } from '../../services/user.service';
import { deleteWorkDoneRecord, fetchAllWorkDoneByWorker, updateWorkDoneRecord } from '../../services/workdone.service';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { EditWorkDoneDialog } from '../../components/Dialog/EditWorkDoneDialog';
import { styled } from '@mui/system';

export interface EditParams {
	id: number;
	level: number;
	unit: number;
	levelTo: number;
	unitFrom: number;
	unitTo: number;
}

const StyledIconButton = styled(IconButton, { name: 'StyledIconButton', slot: 'Wrapper' })({
	color: 'gray',
	'&:hover': {
		color: 'red',
	},
});

const StyledIconButtonForEdit = styled(IconButton, { name: 'StyledIconButton', slot: 'Wrapper' })({
	color: 'gray',
	'&:hover': {
		color: 'blue',
	},
});

const StyledListSubheader = styled(ListSubheader)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	color: 'blue',
	paddingTop: '8px',
	borderBottom: '1px solid #ccc',
	cursor: 'pointer',
	position: 'sticky',
	top: 0,
	zIndex: 1,
	background: '#fff',
});

export function ManageWorkDone() {
	const [pageSize, setPageSize] = useState<number>(10);
	const [loading, setLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [workDoneData, setWorkDoneData] = useState<any>([]);
	const [userData, setUserData] = useState<{ Admins: any[]; Workers: any[] }>({ Admins: [], Workers: [] });
	const [initialLoad, setInitialLoad] = useState<boolean>(true);
	const [selectedWorker, setSelectedWorker] = useState<number>(0);
	const [selectedWorkerName, setSelectedWorkerName] = useState<string>('');
	const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
	const [editParams, setEditParams] = useState<EditParams>({
		id: 0,
		level: 0,
		unit: 0,
		unitFrom: 0,
		unitTo: 0,
		levelTo: 0,
	});
	const [itemsTotal, setItemsTotal] = useState<number>(1);

	const [showList, setShowList] = useState<boolean>(false);

	const [showAdmins, setShowAdmins] = useState<boolean>(true);
	const [showWorkers, setShowWorkers] = useState<boolean>(true);

	const columns = [
		{
			field: 'projectName',
			headerName: 'Project',
			width: 200,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'clientName',
			headerName: 'Client',
			width: 350,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'precinctName',
			headerName: 'Precinct',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'location',
			headerName: 'Address',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'typeName',
			headerName: 'Type',
			width: 250,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'subTypeNames',
			headerName: 'Sub-Type',
			width: 250,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'subTypeValue',
			headerName: 'Sub Type Value',
			width: 180,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'blockNumber',
			headerName: 'Block',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'level',
			headerName: 'Level',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'levelTo',
			headerName: 'LevelTo',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			hide: true,
		},
		{
			field: 'unit',
			headerName: 'Unit',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'unitFrom',
			headerName: 'UnitFrom',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			hide: true,
		},
		{
			field: 'unitTo',
			headerName: 'UnitTo',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			hide: true,
		},
		{
			field: 'createdAt',
			headerName: 'Created At',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'updatedAt',
			headerName: 'Updated At',
			width: 150,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
		},
		{
			field: 'Edit',
			headerName: 'Edit',
			width: 100,
			align: 'center' as GridAlignment,
			headerAlign: 'center' as GridAlignment,
			renderCell: (params: any) => {
				return params.row?.Edit ? (
					<div style={{ flexDirection: 'row' }}>
						<StyledIconButtonForEdit onClick={() => handleEdit(params.row)}>
							<EditIcon fontSize='small' />
						</StyledIconButtonForEdit>
						<StyledIconButton onClick={() => handleDelete(params.row.id)}>
							<DeleteIcon fontSize='small' />
						</StyledIconButton>
					</div>
				) : (
					<div></div>
				);
			},
		},
	];

	const loadDataToGrid = async (workerId: number, pageNumber: number) => {
		try {
			setLoading(true);
			const result: any = await fetchAllWorkDoneByWorker(workerId, pageNumber, pageSize);

			if (result.success) {
				let tmpList: any = [];
				if (result?.data?.rows?.length === 0 && page > 0) {
					handlePageChange(page - 1);
					return;
				}

				for (let i = 0; i < result?.data?.rows?.length; i++) {
					const info = result.data.rows[i];

					const row = {
						id: info.id,
						clientId: info.clientId,
						progressPaymentNum: info.progressPaymentNumber,
						claimRefNum: info.claimRefNum,
						projectName: info.projectName,
						clientName: info.clientName,
						precinctName: info.precinctName,
						location: info.location,
						typeName: info.typeName,
						subTypeNames: info.subTypeNames,
						subTypeValue: info.subTypeValue,
						blockNumber: info.blockNumber,
						level: info.level,
						levelTo: info.levelTo,
						unit: info.unit,
						unitFrom: info.unitFrom,
						unitTo: info.unitTo,
						createdAt: moment(info.createdAt).format('DD-MM-YYYY'),
						updatedAt: moment(info.updatedAt).format('DD-MM-YYYY'),
						Edit: info.claimId > 0 ? false : true,
					};

					tmpList.push(row);
				}

				setItemsTotal(result.data.totalItems || 0);
				setWorkDoneData([...tmpList]);
			}
			setLoading(false);
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message || 'Something went wrong');
			setLoading(false);
		}
	};

	const handlePageChange = (pageNumber: any) => {
		setPage(pageNumber);
		loadDataToGrid(selectedWorker, pageNumber);
	};

	const loadUsers = async () => {
		try {
			setLoading(true);
			const result = await getFullUsersList();

			if (result.success) {
				const admins = result.data.rows.filter((user: any) => user.type === 'admin');
				const workers = result.data.rows.filter((user: any) => user.type === 'worker');

				setUserData({ Admins: admins, Workers: workers });
			}
			setLoading(false);
		} catch (err: any) {
			console.error(err);
			toast.error('An error occurred while fetching users data.');
		}
	};

	const handleEdit = (row: any) => {
		const { id, level, unit, levelTo, unitFrom, unitTo } = row;
		const editParams = { id, level, unit, levelTo, unitFrom, unitTo };

		setEditParams(editParams);
		setShowEditDialog(true);
	};

	const handleDelete = async (id: number) => {
		try {
			const result = await deleteWorkDoneRecord(id);
			if (result.success) {
				toast.success('Work done record deleted successfully!');
				loadDataToGrid(selectedWorker, page);
			} else {
				toast.error(result.message);
			}
		} catch (err: any) {
			console.error(err);
			toast.error('An error occurred while deleting work done record.');
		}
	};

	const handleDialogClose = () => {
		setShowEditDialog(false);
		loadDataToGrid(selectedWorker, page);
	};

	const onSubmit = async (id: number, level: number, unit: number) => {
		try {
			if (level !== editParams.level || unit !== editParams.unit) {
				setShowEditDialog(false);
				let response = await updateWorkDoneRecord(id, level, unit);

				if (response.success) {
					toast.success(`Work done record updated successfully!`);
					loadDataToGrid(selectedWorker, page);
				}
			} else {
				toast.info(`No values changed!`);
			}
		} catch (e) {
			toast.error(`Error updating the work done record!`);
			console.error('Error updating the work done record user data: ', e);
		}
	};

	const handleCategoryClick = (category: string, event: React.MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		console.log(`Category clicked: ${category}`);
		if (category === 'Admins') {
			setShowAdmins(!showAdmins);
		} else {
			setShowWorkers(!showWorkers);
		}
	};

	useEffect(() => {
		loadUsers();
		setInitialLoad(false);
	}, []);

	useEffect(() => {
		if (!initialLoad) {
			loadDataToGrid(selectedWorker, page);
		}
	}, [selectedWorker, page, pageSize]);

	const handleWorkerSelect = (userId: number, userName: string) => {
		setSelectedWorkerName(userName);
		setSelectedWorker(userId);
		setShowList(false);
	};

	return (
		<Box sx={{ flex: 1, height: '100%' }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<h2>All Work Done</h2>
				<FormControl fullWidth variant='outlined' sx={{ width: '300px', height: '50px' }}>
					<InputLabel id='worker-select-label' sx={{ backgroundColor: 'white', paddingRight: '10px' }}>
						Select Admin / Worker
					</InputLabel>
					<Select
						labelId='worker-select-label'
						id='worker-select'
						value={selectedWorker}
						onClick={() => setShowList(!showList)}
						open={showList}
						label='Select Worker'
						renderValue={() => selectedWorkerName || 'Select Admin / Worker'}>
						<MenuItem disabled value={0}>
							Select Admin / Worker
						</MenuItem>
						<StyledListSubheader id='admins' key={'Admins'} onClick={(e) => handleCategoryClick('Admins', e)}>
							Admins ({userData.Admins.length}) {showAdmins ? <ExpandLess /> : <ExpandMore />}
						</StyledListSubheader>
						<Collapse id='admins' key={'Admins'} in={showAdmins} timeout='auto' unmountOnExit>
							{userData.Admins.map((admin) => (
								<MenuItem key={admin.id} value={admin.id} onClick={() => handleWorkerSelect(admin.id, admin.name)}>
									{admin.name}
								</MenuItem>
							))}
						</Collapse>
						<StyledListSubheader
							id='workers'
							key={'Workers'}
							tabIndex={0}
							onClick={(e) => handleCategoryClick('Workers', e)}>
							Workers ({userData.Workers.length}) {showWorkers ? <ExpandLess /> : <ExpandMore />}
						</StyledListSubheader>
						<Collapse id='workers' key={'Workers'} in={showWorkers} timeout='auto' unmountOnExit>
							{userData.Workers.map((worker) => (
								<MenuItem key={worker.id} value={worker.id} onClick={() => handleWorkerSelect(worker.id, worker.name)}>
									{worker.name}
								</MenuItem>
							))}
						</Collapse>
					</Select>
				</FormControl>
			</Box>
			<Box sx={{ height: 600, width: '100%', mt: 2 }}>
				<DataGrid
					rows={workDoneData}
					columns={columns}
					loading={loading}
					rowCount={itemsTotal}
					page={page}
					pageSize={pageSize}
					rowsPerPageOptions={[10, 20, 50, 100]}
					pagination
					paginationMode='server'
					onPageSizeChange={(size) => setPageSize(size)}
					onPageChange={handlePageChange}
					localeText={{
						noRowsLabel: selectedWorkerName === '' ? 'Select A Worker' : 'Data not available',
					}}
				/>
			</Box>
			{showEditDialog && (
				<EditWorkDoneDialog show={showEditDialog} params={editParams} onSubmit={onSubmit} onClose={handleDialogClose} />
			)}
		</Box>
	);
}
