import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const HorizontalBarChart = ({ data }) => {
	const barChartRef = useRef(null);
	const legendRef = useRef(null);

	useEffect(() => {
		if (data.length > 0) {
			renderBarChart(barChartRef.current, data);
			renderLegend(legendRef.current);
		}
	}, [data]);

	const renderBarChart = (ref, data) => {
		// Sort data by month in ascending order
		const sortedData = [...data].sort((a, b) => parseInt(a.month) - parseInt(b.month));
		const topMonths = sortedData.slice(0, 10);

		// Dimensions and margins
		const margin = { top: 20, right: 20, bottom: 40, left: 100 };
		const width = 700 - margin.left - margin.right;
		const height = 850 - margin.top - margin.bottom;

		// Remove any existing chart
		d3.select(ref).selectAll('*').remove();

		// Create SVG
		const svg = d3
			.select(ref)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`);

		// Y scale (for months)
		const y = d3
			.scaleBand()
			.domain(topMonths.map((d) => getMonthName(parseInt(d.month))))
			.range([0, height])
			.padding(0.1);

		// X scale (for revenue/expenses)
		const x = d3
			.scaleLinear()
			.domain([0, d3.max(topMonths, (d) => Math.max(d.totalRevenue, d.totalExpenses))])
			.nice()
			.range([0, width]);

		// Y Axis
		svg.append('g').call(d3.axisLeft(y));

		// X Axis
		svg.append('g').attr('transform', `translate(0,${height})`).call(d3.axisBottom(x));

		// Bars for Revenue
		svg
			.selectAll('.bar-revenue')
			.data(topMonths)
			.enter()
			.append('rect')
			.attr('class', 'bar-revenue')
			.attr('y', (d) => y(getMonthName(parseInt(d.month))))
			.attr('x', 0)
			.attr('height', y.bandwidth() / 4)
			.attr('width', (d) => x(d.totalRevenue))
			.attr('fill', '#4caf50');

		// Bars for Expenses
		svg
			.selectAll('.bar-expenses')
			.data(topMonths)
			.enter()
			.append('rect')
			.attr('class', 'bar-expenses')
			.attr('y', (d) => y(getMonthName(parseInt(d.month))) + y.bandwidth() / 3)
			.attr('x', 0)
			.attr('height', y.bandwidth() / 4)
			.attr('width', (d) => x(d.totalExpenses))
			.attr('fill', '#ffeb3b');

		svg
			.selectAll('.bar-profit')
			.data(topMonths)
			.enter()
			.append('rect')
			.attr('class', 'bar-profit')
			.attr('y', (d) => y(getMonthName(parseInt(d.month))) + 2 * (y.bandwidth() / 3))
			.attr('x', (d) => x(Math.min(0, -d.totalProfits)))
			.attr('height', y.bandwidth() / 4)
			.attr('width', (d) => x(-d.totalProfits))
			.attr('fill', (d) => (d.totalProfits < 0 ? 'red' : '#1976d2'));

		// Title
		svg
			.append('text')
			.attr('x', width / 2)
			.attr('y', -10)
			.attr('text-anchor', 'middle')
			.style('font-size', '16px')
			.style('font-weight', 'bold');

		// Function to get month name from numeric month
		function getMonthName(month) {
			const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[month - 1];
		}
	};

	const renderLegend = (ref) => {
		const legendData = [
			{ label: 'Revenue', color: '#4caf50' },
			{ label: 'Expenses', color: '#ffeb3b' },
			{ label: 'Loss', color: 'red' },
			{ label: 'Profit', color: '#1976d2' },
		];

		const legend = d3.select(ref);

		legend.selectAll('*').remove();

		const legendItem = legend
			.selectAll('.legend-item')
			.data(legendData)
			.enter()
			.append('g')
			.attr('class', 'legend-item')
			.attr('transform', (d, i) => `translate(0, ${i * 20})`);

		legendItem
			.append('rect')
			.attr('width', 12)
			.attr('height', 12)
			.attr('fill', (d) => d.color);

		legendItem
			.append('text')
			.attr('x', 20)
			.attr('y', 10)
			.attr('dy', '0.35em')
			.text((d) => d.label)
			.style('font-size', '12px');
	};

	return (
		<div>
			<svg ref={barChartRef}></svg>
			<svg ref={legendRef} width={150} height={120}></svg>
		</div>
	);
};

export default HorizontalBarChart;
