import axios from './api';

export const addNewUser = async (url: string, payload: any) => {
	const response = await axios.post('auth/' + url, payload);

	return response;
};

export const editUser = async (url: string, payload: any) => {
	const response = await axios.put('auth/' + url, payload);

	return response;
};

export const fetchAllUsers = async (page: number, type: string, limit: number) => {
	const response = await axios.get('auth/all/users?page=' + page + '&type=' + type + '&limit=' + limit);

	return response.data;
};

export const updateUserStatus = async (url: string, payload: any) => {
	const response = await axios.put('auth/' + url, payload);

	return response;
};

export const deleteUser = async (userId: number) => {
	const response = await axios.delete('auth/user/delete?userId=' + userId);

	return response.data;
};

export const getFullUsersList = async (type?: string) => {
	let url = `auth/get/allUsers${type ? `?type=${type}` : ''}`;
	const response = await axios.get(url);
	return response.data;
};
