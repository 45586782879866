import axios from './api';

export const fetchGSTValue = async (fromDate: string, toDate: string) => {
	const response = await axios.post('config/get/gst_by_date_range', {
		fromDate,
		toDate,
	});

	return response.data;
};

export const addGSTValue = async (gst: number) => {
	const response = await axios.post('config/add/gst', {
		gst,
	});
	return response.data;
};

export const fetchLastGSTValue = async () => {
	const response = await axios.get('config/last/gst');

	return response.data;
};

export const changePassword = async (id: number | undefined, currentPassword: string, newPassword: string) => {
	const response = await axios.put('auth/change/password', {
		id: id,
		oldPassword: currentPassword,
		newPassword: newPassword,
	});
	return response.data;
};
