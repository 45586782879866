import { useEffect, useState } from 'react';
import { fetchAllPrecincts } from '../../../services/precinct.service';
import { fetchAllBlocks } from '../../../services/block.service';
import { PrecinctModel, BlockModel } from '../../../models/precinct.model';

export const usePrecinctsForProject = (projectId: number, toggle: boolean): Array<PrecinctModel> | undefined => {
	const [precinctsList, setPrecinctsList] = useState<Array<PrecinctModel>>();

	const fetchPrecinctsForProject = async () => {
		if (!projectId) return;
		const precinctsResponse = await fetchAllPrecincts('precinct/getAll?projectId=' + projectId);

		if (!precinctsResponse.data?.success) return;
		setPrecinctsList(precinctsResponse.data.data);
	};

	useEffect(() => {
		fetchPrecinctsForProject();
	}, [projectId, toggle]);

	return precinctsList;
};

export const useBlocksForPrecinct = (precinctId: number): Array<BlockModel> | undefined => {
	const [blocksList, setBlocksList] = useState<Array<BlockModel>>();

	const fetchBlocksForPrecinct = async () => {
		const blocksResponse = await fetchAllBlocks('block/getAll?projectId=' + precinctId);

		if (!blocksResponse.data?.success) return;
		setBlocksList(blocksResponse.data.data);
	};

	useEffect(() => {
		fetchBlocksForPrecinct();
	}, [precinctId]);

	return blocksList;
};
