import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box } from '@mui/material';
import { GstConfigureDialog } from '../Dialog/GstConfigureDialog';
import { ChangePasswordDialog } from '../Dialog/ChangePasswordDialog';

export const SettingsChoicesDialog = (props: any) => {
	const [openSettingDialog, setOpenSettingDialog] = useState<boolean>(false);
	const [openChangePasswordDialog, setChangePasswordDialog] = useState<boolean>(false);

	return (
		<>
			<Dialog open={props.show} onClose={props.onClose} aria-labelledby='customized-dialog-title'>
				<DialogTitle>Settings Choices</DialogTitle>
				<DialogContent style={{ width: '500px' }}>
					<Box>
						<Button
							color='primary'
							variant='contained'
							onClick={() => setChangePasswordDialog(!openChangePasswordDialog)}>
							Change Password
						</Button>
					</Box>
					<Box sx={{ paddingTop: '10px' }}>
						<Button color='primary' variant='contained' onClick={() => setOpenSettingDialog(!openSettingDialog)}>
							Change Default GST
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
			<GstConfigureDialog show={openSettingDialog} onClose={() => setOpenSettingDialog(false)} />
			<ChangePasswordDialog show={openChangePasswordDialog} onClose={() => setChangePasswordDialog(false)} />
		</>
	);
};
