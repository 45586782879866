import axios from './api';

export const fetchAllWorkerAnalytics = async (fromDate: string, toDate: string, page?: number, limit?: number) => {
	let url = `worker/get-worker-analytics`;

	if (page && limit) {
		url += `?page=${page}&limit=${limit}`;
	}

	const response = await axios.get(url, {
		params: {
			toDate: toDate,
			fromDate: fromDate,
		},
	});

	console.log('response', response);

	return response;
};

export const fetchWorkerWorkDone = async (userId: number, fromDate: any, toDate: any) => {
	const response = await axios.get('worker/get-worker-work-done', {
		params: {
			id: userId,
			toDate: toDate,
			fromDate: fromDate,
		},
	});

	return response;
};

export const fetchAllMonthlyAnalytics = async (months: number) => {
	const response = await axios.get(`worker/get-monthly-analytics`, {
		params: {
			months: months,
		},
	});

	return response;
};
