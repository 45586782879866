import { useState, useRef } from 'react';
import type { TextFieldProps } from '@mui/material';
import { toast } from 'react-toastify';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	CircularProgress,
	Box,
} from '@mui/material';
import * as yup from 'yup';

import { addType, updateType } from '../../services/type.service';

export const AddEditTypeDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const typeRef = useRef<TextFieldProps>(null);
	const updateTypeSchema = yup.object().shape({
		typeId: yup.number().required(),
		name: yup
			.string()
			.min(1, 'Minimum 1 character required')
			.max(25, 'Type name cannot be of more than 25 characters long.')
			.required(),
	});
	const typeSchema = yup.object().shape({
		name: yup
			.string()
			.min(1, 'Minimum 1 character required')
			.max(25, 'Type name cannot be of more than 25 characters long.')
			.required(),
	});

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const handleSubmit = async () => {
		let payload = {
			name: typeRef?.current?.value,
		};

		try {
			setLoading(true);
			typeSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await addType('type/add', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.onAdded();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const handleUpdate = async () => {
		let payload = {
			typeId: props.selectedTypeForEdit?.id,
			name: typeRef?.current?.value,
		};

		try {
			setLoading(true);
			updateTypeSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await updateType('type/name', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.onAdded();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props?.isEditMode ? 'Edit' : 'Add'} Type</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<Box>
					<TextField
						style={{ marginTop: '2%' }}
						defaultValue={props?.isEditMode ? props?.selectedTypeForEdit?.name : null}
						inputRef={typeRef}
						label='Type'
						variant='standard'
						fullWidth
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							onClick={props?.isEditMode ? handleUpdate : handleSubmit}>
							{props?.isEditMode ? 'Update' : 'Add'}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
