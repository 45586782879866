export enum EType {
	admin = 'admin',
	worker = 'worker',
}

export interface UserModel {
	id: number;
	name: string;
	email: string;
	mobileNumber: string;
	type: EType;
	workerEnabled?: boolean;
	salary?: string;
	otherCosts?: string;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface UsersDataModel {
	currentPage: number;
	totalPages: number;
	totalItems: number;
	users: Array<UserModel>;
}

export interface UserReduxModel {
	user?: UserModel;
}
