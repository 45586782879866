import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Panel, IPanelProps } from '../Layout/Panel/Panel';
import { EIcon } from '../Layout/Panel/views/components/Icon';
import { ISegmentProps } from '../Layout/Panel/views/Segment';
import { SummaryPanel, SummaryPanelProps } from '../../pages/Project/SummaryPanel';
import { useAllSubTypesByType, useAllSubTypeValues } from '../../pages/Project/hooks/types';
import { fetchAllSubValuesBySubType } from '../../services/type.service';
import { addSubTypesToProject } from '../../services/project.service';
import { SubTypeValueDataModel } from '../../models/project.model';
import { SubTypeDataModel, SubValueDataModel } from '../../models/types.model';

export const EditSubTypeDialog = (props: any) => {
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);

	const [fields, setFields] = useState<any>({});
	const [subTypePreviewMode, setSubTypePreviewMode] = useState<boolean>(false);
	// For the currently selected sub type
	const [subTypeValues, setSubTypeValues] = useState<Array<SubValueDataModel>>([]);
	const [selectedSubType, setSelectedSubType] = useState<SubTypeDataModel>();
	// Dictonary of selectedSubTypeName to Array of selectedSubTypeValues
	const [selectedSubTypeValues, setSelectedSubTypeValues] = useState(Object);

	const subTypesModel: Array<SubTypeDataModel> | undefined = useAllSubTypesByType(props?.selectedProjectType?.typeId);

	const subTypeValuesMaster: Array<SubTypeValueDataModel> | undefined = useAllSubTypeValues();

	const onRefreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
		props.onRefreshTypePanel();
	};

	const handleInputChange = (event: any) => {
		const { name, value } = event.target;

		// Update the fields state with the new value
		setFields({
			...fields,
			[name]: value,
		});
	};

	const onSubTypeClick = async (subTypeId: number) => {
		if (subTypeId) {
			const response = await fetchAllSubValuesBySubType('type/subtype/value/getall?subtypeId=' + subTypeId);

			if (!response.data?.success) return;
			setSubTypeValues(response.data.data);
		}
	};

	const getSubTypes = () => {
		let data: any = [];

		if (subTypesModel) {
			data = subTypesModel.map((subType) => ({
				text: subType.name,
				isTextSelected: selectedSubType?.id === subType.id,
				textClickCb: async () => {
					setSelectedSubType(subType);
					await onSubTypeClick(subType.id);
				},
			}));
		}

		return data;
	};

	const getSubTypeNameFromId = (id: number): string => {
		if (id && subTypesModel) {
			const info = subTypesModel.find((item) => item.id === id);

			return info?.name ? info?.name : '';
		}

		return '';
	};

	const getSubTypeValueFromId = (id: number) => {
		if (id && subTypeValuesMaster) {
			const info = subTypeValuesMaster.find((item) => item.id === id);

			return info?.value ? info?.value : '';
		}

		return '';
	};

	const getSubTypeValueFromIds = (ids: Array<number>) => {
		let data: any = [];

		if (ids) {
			ids.map((id) => data.push(getSubTypeValueFromId(Number(id))));
		}

		return data;
	};

	const subTypeNamesProps: ISegmentProps = {
		header: {
			text: 'Name',
		},
		placeholderText: 'No sub types set',
		data: getSubTypes(),
	};

	const handleClose = () => {
		props.close();
		setSubTypePreviewMode(false);
		setSelectedSubTypeValues({});
	};

	const subTypeValuesProps: ISegmentProps = {
		header: {
			text: 'Value',
		},
		placeholderText: 'No values available',
		data: subTypeValues.map((value) => {
			let selectedValues =
				selectedSubType?.id && selectedSubTypeValues[selectedSubType?.id]
					? selectedSubTypeValues[selectedSubType?.id]
					: [];
			return {
				text: value.value,
				icons: [
					{
						icon: EIcon.CHECKBOX,
						iconCb: () => {
							if (selectedValues?.includes(value.id)) {
								if (selectedSubType) {
									// Remove item from array
									let newList = selectedValues?.filter((selectedValue: any) => selectedValue !== value.id);
									if (newList.length == 0) delete selectedSubTypeValues[selectedSubType?.id];
									else selectedSubTypeValues[selectedSubType.id] = newList;
								}
							} else {
								if (selectedSubType && selectedValues) {
									// Add item into array
									selectedSubTypeValues[selectedSubType.id] = [...selectedValues, value.id];
								}
							}

							setSelectedSubTypeValues({ ...selectedSubTypeValues });
						},
						// This will update the component whenever state changes
						isIconChecked: selectedValues?.includes(value.id),
					},
				],
			};
		}),
	};

	const subTypePanelProps: IPanelProps = {
		container: {
			height: '250px',
			padding: '2px',
		},
		segments: [subTypeNamesProps, subTypeValuesProps],
	};

	const getSellingPriceFromSubTypeValueIds = (subTypeId: number, valueIds: Array<number>) => {
		let sellingPriceList: any = [];

		if (props.selectedSubTypeList?.length) {
			let indx = props.selectedSubTypeList?.findIndex((subTypeInfo: any) => subTypeInfo?.subTypeId === subTypeId);

			sellingPriceList = valueIds.map((valueId) => {
				let valInfo = props.selectedSubTypeList[indx]?.subtypeValuesList?.find((tmp: any) => tmp.id === valueId);

				return valInfo?.sellingPrice ? valInfo.sellingPrice : 0;
			});
		}

		return sellingPriceList;
	};

	const getPurchaseCostFromSubTypeValueIds = (subTypeId: number, valueIds: Array<number>) => {
		let purchaseCostList: any = [];

		if (props.selectedSubTypeList?.length) {
			let indx = props.selectedSubTypeList?.findIndex((subTypeInfo: any) => subTypeInfo?.subTypeId === subTypeId);

			purchaseCostList = valueIds.map((valueId) => {
				let valInfo = props.selectedSubTypeList[indx]?.subtypeValuesList?.find((tmp: any) => tmp.id === valueId);

				return valInfo?.purchaseCost ? valInfo.purchaseCost : 0;
			});
		}

		return purchaseCostList;
	};

	const summaryPanelProps: SummaryPanelProps = {
		containerHeight: '250px',
		items: Object.keys(selectedSubTypeValues).map((key) => {
			const props = {
				subtypeId: Number(key),
				name: getSubTypeNameFromId(Number(key)),
				values: getSubTypeValueFromIds(selectedSubTypeValues[key]),
				valuesId: selectedSubTypeValues[key],
				sellingPriceList: getSellingPriceFromSubTypeValueIds(Number(key), selectedSubTypeValues[key]),
				purchaseCostList: getPurchaseCostFromSubTypeValueIds(Number(key), selectedSubTypeValues[key]),
			};
			return props;
		}),
		handleInputChange: handleInputChange,
	};

	const handleSubmit = async () => {
		// TODO: Upload and reset state
		try {
			let payload: any = [];
			// Iterate through selected values to get the sellingPrice from fields
			Object.keys(selectedSubTypeValues).map((subTypeId: any) => {
				selectedSubTypeValues[subTypeId].map((selectedSubTypeValueId: number) => {
					// Form the key to lookup `fields` variable to get the sellingPrice
					const sellingPriceKey = `sellingPrice-${subTypeId},${selectedSubTypeValueId}`;
					const purchaseCostKey = `purchaseCost-${subTypeId},${selectedSubTypeValueId}`;

					const key = subTypeId + ',' + selectedSubTypeValueId;
					let oldSubtypeIndex = payload.findIndex((subTypeInfo: any) => subTypeInfo.subTypeId === subTypeId);
					if (oldSubtypeIndex >= 0) {
						payload[oldSubtypeIndex].subTypeValues.push({
							valueId: selectedSubTypeValueId,
							sellingPrice: fields[key + '_sellingPrice'],
							purchaseCost: fields[key + '_purchaseCost'],
						});
					} else {
						payload.push({
							subTypeId: subTypeId,
							subTypeValues: [
								{
									valueId: selectedSubTypeValueId,
									sellingPrice: fields[key + '_sellingPrice'],
									purchaseCost: fields[key + '_purchaseCost'],
								},
							],
						});
					}
				});
			});
			const result = await addSubTypesToProject(
				'project/addSubTypeToType?projectToTypeId=' + props.selectedProjectType.id,
				{ data: payload }
			);

			if (result.data.success) {
				toast.success(result.data.message);
				onRefreshPanel();
			} else {
				toast.error(result.data.message);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
		handleClose();
	};

	useEffect(() => {
		if (props.selectedSubTypeList) {
			let data: any = {};

			props.selectedSubTypeList.map((subtype: any) => {
				data[subtype.subTypeId] = subtype.subtypeValuesList.map((value: any) => value.id);
			});

			setSelectedSubTypeValues({ ...data });
		}
	}, [props.show, props.selectedSubTypeList]);

	useEffect(() => {
		setSubTypeValues([]);
		if (subTypesModel?.length) {
			setSelectedSubType((prevState) => ({ ...subTypesModel[0] }));
			onSubTypeClick(subTypesModel[0].id);
		}
	}, [subTypesModel]);

	const handleNext = () => {
		setSubTypePreviewMode((prevState) => !prevState);
		if (!subTypePreviewMode) {
			summaryPanelProps &&
				summaryPanelProps.items?.map((item: any, index: number) => {
					item.valuesId.map((valueId: any, index: number) => {
						fields[item.subtypeId + ',' + valueId] = item.sellingPriceList[index];
						fields[item.subtypeId + ',' + valueId] = item.purchaseCostList[index];
					});
				});
		}
	};

	return (
		<Dialog open={props.show} onClose={handleClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{!subTypePreviewMode ? 'Edit SubType' : 'Confirmation'}</DialogTitle>
			<DialogContent style={{ width: '500px' }}>
				{!subTypePreviewMode ? (
					<Panel panelProps={subTypePanelProps}></Panel>
				) : (
					<SummaryPanel panelProps={summaryPanelProps}></SummaryPanel>
				)}
			</DialogContent>
			<DialogActions>
				{!subTypePreviewMode && (
					<Button variant='outlined' size='small' onClick={handleClose}>
						Cancel
					</Button>
				)}
				<Button variant='contained' size='small' onClick={handleNext}>
					{!subTypePreviewMode ? 'Next' : 'Back'}
				</Button>
				{subTypePreviewMode && (
					<Button type='submit' size='small' variant='contained' onClick={handleSubmit}>
						{'Confirm'}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
