import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { PaymentModel } from '../../models/payment.model';
import '../Layout/Panel/css/InvoiceSegment.css';
import { Typography } from '@mui/material';
export const PaymentsTable = (props: any) => {
	const { data, loading, single } = props;

	const [payment, setPayment] = useState<any>([]);

	const paymentColumns = [
		{
			field: 'col1',
			headerName: 'Item No.',
			flex: 1,
			width: 200,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col2',
			headerName: 'Project ID',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col3',
			headerName: 'Date',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col4',
			headerName: 'Amount',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col5',
			headerName: 'GST Amount',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col6',
			headerName: 'Total Amount',
			width: 150,
			flex: 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'col7',
			headerName: 'Existing/New Invoice Number',
			width: 150,
			flex: 2,
			align: 'center',
			headerAlign: 'center',
		},
	];

	useEffect(() => {
		if (data) {
			formatPaymentData(data);
		}
	}, [data]);

	const formatPaymentData = async (data: PaymentModel[]) => {
		let tmpList = data.map((info: PaymentModel, index: number) => {
			const row = {
				id: info.id,
				col1: info.itemNo ? info.itemNo : 0,
				col2: info.projectId,
				col3: info.date,
				col4: info.amount,
				col5: info.gst,
				col6: info.totalAmount ? info.totalAmount : 0,
				col7: info.invoiceNumber ? info.invoiceNumber : 0,
				isTemporary: info.isTemporary ? info.isTemporary : false,
			};
			return row;
		});
		setPayment(tmpList);
	};

	const emptyListComponent = () => {
		return <Typography textAlign={'center'}>No payments record found!</Typography>;
	};

	return (
		<div style={{ display: 'flex', alignContent: 'center', marginTop: '20px' }}>
			<div style={{ width: '100%' }}>
				<DataGrid
					rows={payment}
					columns={paymentColumns}
					loading={loading}
					hideFooterPagination={true}
					autoHeight
					hideFooter={single || true}
					getRowClassName={(params) => (params.row.isTemporary ? 'temporary-row' : 'normal-row')}
					components={{
						NoRowsOverlay: emptyListComponent,
					}}
				/>
			</div>
		</div>
	);
};
