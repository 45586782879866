import React, { useState, useRef, useEffect } from 'react';
import type { TextFieldProps } from '@mui/material';
import { toast } from 'react-toastify';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	CircularProgress,
	Select,
	MenuItem,
} from '@mui/material';
import * as yup from 'yup';

import { createProject, updateProject } from '../../services/project.service';

export const AddEditProjectDialog = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [projectStatus, setProjectStatus] = useState<any>(0);
	const projectNameRef = useRef<TextFieldProps>(null);
	const projectSchema = yup.object().shape({
		name: yup.string().required(),
		status: yup.number(),
	});

	const onClose = () => {
		setLoading(false);
		props.close();
	};

	const addProject = async () => {
		let payload = {
			name: projectNameRef?.current?.value,
			status: projectStatus,
		};

		try {
			setLoading(true);
			projectSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await createProject('project/create', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.onAdded();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	const handleUpdate = async () => {
		let payload = {
			projectId: props?.selectedProjectForEdit?.id,
			name: projectNameRef?.current?.value,
			status: projectStatus,
		};

		try {
			setLoading(true);
			projectSchema
				.validate(payload)
				.then(async (value) => {
					const result: any = await updateProject('project/update', payload);

					if (result.data.success) {
						toast.success(result.data.message);
						props.refreshPanel();
						onClose();
					} else {
						toast.error(result.data.message);
					}
					setLoading(false);
				})
				.catch((err: any) => {
					toast.error(err?.response?.data?.message || err?.message);
					setLoading(false);
				});
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (props?.isEditMode) {
			setProjectStatus(props?.selectedProjectForEdit?.status);
		}
	}, [props?.isEditMode]);

	return (
		<Dialog open={props.show} onClose={onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>{props?.isEditMode ? 'Edit' : 'Add'} Project</DialogTitle>
			<DialogContent style={{ width: '400px' }}>
				<div>
					<TextField
						style={{ marginTop: '2%' }}
						inputRef={projectNameRef}
						label='Project Name'
						defaultValue={props?.isEditMode ? props?.selectedProjectForEdit?.name : null}
						variant='standard'
						fullWidth
					/>
				</div>
				<div style={{ marginTop: '20px' }}>
					<Select
						value={projectStatus}
						onChange={(e: any) => {
							setProjectStatus(e.target.value);
						}}
						label='Project Status'
						fullWidth
						variant='standard'>
						<MenuItem value={0}>{'Inactive'}</MenuItem>
						<MenuItem value={1}>{'In-Progress'}</MenuItem>
						<MenuItem value={2}>{'Completed'}</MenuItem>
					</Select>
				</div>
			</DialogContent>
			<DialogActions>
				{loading ? (
					<CircularProgress color='primary' />
				) : (
					<>
						<Button variant='outlined' size='small' style={{ marginLeft: '20px' }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							type='submit'
							size='small'
							variant='contained'
							onClick={props?.isEditMode ? handleUpdate : addProject}>
							{props?.isEditMode ? 'Update' : 'Add'}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};
