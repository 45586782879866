import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import { changePassword } from '../../services/common.service';
import { useAppSelector } from '../../hooks/redux-hooks';

export const ChangePasswordDialog = (props: any) => {
	const [currentPassword, setCurrentPassword] = React.useState<string>('');
	const [newPassword, setNewPassword] = React.useState<string>('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>('');
	const user = useAppSelector((state) => state.user.user);
	const submitPassword = async () => {
		try {
			if (newPassword.length < 4 || newPassword.length > 50) {
				throw new Error('Password must be between 4 and 50 characters.');
			}
			if (newPassword == confirmNewPassword) {
				const result = await changePassword(user?.id, currentPassword, newPassword);
				if (result?.success) {
					toast.success('Password updated successfully');
					props.onClose();
				}
			} else {
				throw new Error('New Password and Confirm New Password does not match.');
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	return (
		<Dialog open={props.show} onClose={props.onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>Change Password</DialogTitle>
			<DialogContent style={{ width: '500px' }}>
				<Box sx={{ padding: '10px' }}>
					<TextField
						fullWidth
						label='Current Password'
						inputProps={{
							id: 'currentPassword',
							minLength: 4,
							maxLength: 50,
						}}
						onChange={(e) => setCurrentPassword(e.target.value)}
					/>
				</Box>
				<Box sx={{ padding: '10px' }}>
					<TextField
						fullWidth
						label='New Password'
						inputProps={{
							id: 'newPassword',
							minLength: 4,
							maxLength: 50,
						}}
						onChange={(e) => setNewPassword(e.target.value)}
					/>
				</Box>
				<Box sx={{ padding: '10px' }}>
					<TextField
						fullWidth
						label='Confirm New Password'
						inputProps={{
							id: 'confirmNewPassword',
							minLength: 4,
							maxLength: 50,
						}}
						onChange={(e) => setConfirmNewPassword(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' size='small' onClick={props.onClose}>
					Cancel
				</Button>
				<Button type='submit' size='small' variant='contained' onClick={submitPassword}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
