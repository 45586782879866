import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactNode } from 'react';

export interface IDefaultGridLayoutProps {
	containerHeight?: string;
	panelOne: ReactNode;
	panelTwo?: ReactNode;
	panelThree?: ReactNode;
	panelFour?: ReactNode;
}

/**
 *  * Grid layout (2 panels)
 * __________________________
 * |       		|          	|
 * |       		|    		|
 * |  			|			|
 * | PanelOne   | PanelTwo	|
 * |       		|			|
 * |       	   	|    	    |
 * |____________|___________|
 *
 * Grid layout (3 panels)
 * __________________________
 * |       |          		|
 * |       |    PanelTwo	|
 * | Panel |________________|
 * | One   |	          	|
 * |       |	PanelThree	|
 * |       |    	      	|
 * |_______|________________|
 *
 *  * Grid layout (4 panels)
 * __________________________
 * |       |    Panel Two   |
 * |	   |________________|
 * |       |    	        |
 * | Panel |    PanelTwo    |
 * | One   |________________|
 * |       |		        |
 * |       |    PanelThree	|
 * |_______|________________|
 */
export const DefaultGridLayout = (props: { layoutProps: IDefaultGridLayoutProps }) => {
	const rowGap = '5px';
	const boxHeight = props.layoutProps.containerHeight || '80vh';
	const leftPanelHeight = `calc(100% + ${rowGap})`;
	return (
		<Box sx={{ flexGrow: 1, height: boxHeight }}>
			<Grid container columnSpacing={1} alignItems='stretch' height='100%'>
				{props.layoutProps.panelFour ? (
					<>
						<Grid xs={12} md={2} lg={2} height={leftPanelHeight}>
							{props.layoutProps.panelOne}
						</Grid>
						<Grid container xs={12} md={10} lg={10} rowGap={rowGap} height='100%'>
							<Grid xs={12} height='10%'>
								{props.layoutProps.panelTwo}
							</Grid>
							<Grid xs={12} height='45%'>
								{props.layoutProps.panelThree}
							</Grid>
							<Grid xs={12} height='45%'>
								{props.layoutProps.panelFour}
							</Grid>
						</Grid>
					</>
				) : props.layoutProps.panelThree ? (
					<>
						<Grid xs={12} md={2} lg={2} height={leftPanelHeight}>
							{props.layoutProps.panelOne}
						</Grid>
						<Grid container xs={12} md={10} lg={10} rowGap={rowGap} height='100%'>
							<Grid xs={12} height='50%'>
								{props.layoutProps.panelTwo}
							</Grid>
							<Grid xs={12} height='50%'>
								{props.layoutProps.panelThree}
							</Grid>
						</Grid>
					</>
				) : !props.layoutProps.panelTwo ? (
					<>
						<Grid xs={12} height='100%'>
							{props.layoutProps.panelOne}
						</Grid>
					</>
				) : (
					<>
						<Grid xs={6} height='100%'>
							{props.layoutProps.panelOne}
						</Grid>
						<Grid xs={6} height='100%'>
							{props.layoutProps.panelTwo}
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
};
