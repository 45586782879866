import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import { addGSTValue, fetchLastGSTValue } from '../../services/common.service';

export const GstConfigureDialog = (props: any) => {
	const [gstValue, setGSTValue] = React.useState<number>(7);

	const loadGSTValue = async () => {
		try {
			const result = await fetchLastGSTValue();

			if (result?.success) {
				setGSTValue(result?.data?.value);
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	useEffect(() => {
		loadGSTValue();
	}, [props.show]);

	const submitGST = async () => {
		if (!gstValue || gstValue <= 0) {
			toast.error('Please enter valid GST value');
			return;
		}

		try {
			const result = await addGSTValue(gstValue);
			if (result?.success) {
				toast.success('GST Value updated successfully');
				props.onClose();
			}
		} catch (err: any) {
			toast.error(err?.response?.data?.message || err?.message);
		}
	};

	return (
		<Dialog open={props.show} onClose={props.onClose} aria-labelledby='customized-dialog-title'>
			<DialogTitle>Configure Default GST</DialogTitle>
			<DialogContent style={{ width: '500px' }}>
				<Box sx={{ padding: '10px' }}>
					<TextField
						fullWidth
						label='GST Value'
						type='number'
						inputProps={{ min: 1 }}
						value={gstValue}
						onChange={(e) => setGSTValue(parseInt(e.target.value))}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' size='small' onClick={props.onClose}>
					Cancel
				</Button>
				<Button type='submit' size='small' variant='contained' onClick={submitGST}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
