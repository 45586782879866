import { useEffect, useRef } from 'react';
import { Typography, CircularProgress, Card, CardContent, Container } from '@mui/material';
import * as d3 from 'd3';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ProjectAnalyticsPieChart from './ProjectAnalyticsPieChart';
import BarChart from './BarChart';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	root: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
	},
	dashboard: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	cardContainer: {},
	chartsContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '10px',
		width: '100%',
	},
	firstGroup: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	chartContainer: {
		display: 'flex',
		flex: 1,
		width: '100%',
		minHeight: '320px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	card: {
		marginBottom: '12px',
		height: '550px',
		width: '100%',
	},
	typography: {
		display: 'flex',
		lineHeight: '1.2',
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	infoCard: {
		marginBottom: '12px',
		width: '100%',
	},
	barChart: {
		marginTop: '200px',
		marginLeft: '10px',
		height: '1030px',
	},
});

const AnalyticsDashboard = (props: any) => {
	const styles = useStyles();
	const {
		loadDataToGrid,
		loading,
		workerAnalyticsData,
		revenue,
		expenses,
		totalProfitLoss,
		monthsOfSalaryPaid,
		projectAnalyticsData,
		noOfActiveProjects,
		totalNoOfWorkers,
		monthlyAnalyticsData,
	} = props;
	useEffect(() => {
		loadDataToGrid();
	}, []);
	const barChartRef = useRef(null);
	const pieChartRef = useRef(null);
	const revenuePieChartRef = useRef(null);
	console.log(monthlyAnalyticsData);
	const pieData = [
		{ name: 'Revenue', value: revenue },
		{ name: 'Expenses', value: expenses },
	];

	const projectPieData = projectAnalyticsData.map((d) => ({
		name: d.projectName,
		value: parseInt(d.totalSellingPrice),
	}));

	useEffect(() => {
		if (!loading && workerAnalyticsData.length > 0) {
			//   renderLineChart(lineChartRef.current, workerAnalyticsData);
			renderBarChart(barChartRef.current, workerAnalyticsData);
			renderPieChart(pieChartRef.current, revenue, expenses);
			renderProjectAnalyticsPieChart(revenuePieChartRef.current, projectAnalyticsData);
		}
	}, [workerAnalyticsData, loading, totalProfitLoss, expenses]);

	const renderBarChart = (ref, data) => {
		// Calculate profit/loss for each worker
		data.forEach((worker) => {
			worker.profitLoss = worker.revenue - worker.expenses;
		});

		// Sort workers by profit/loss
		const sortedData = [...data].sort((a, b) => b.profitLoss - a.profitLoss);
		const topWorkers = sortedData.slice(0, 10);

		// Dimensions and margins
		const margin = { top: 0, right: 20, bottom: 40, left: 60 };
		const width = 700 - margin.left - margin.right;
		const height = 550 - margin.top - margin.bottom;

		// Remove any existing chart
		d3.select(ref).selectAll('*').remove();

		// Create SVG
		const svg = d3
			.select(ref)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`);

		// X scale
		const x = d3
			.scaleBand()
			.domain(topWorkers.map((d) => d.workerName))
			.range([0, width])
			.padding(0.2);

		// Y scale
		const y = d3
			.scaleLinear()
			.domain([0, d3.max(topWorkers, (d) => Math.max(d.revenue, d.expenses))])
			.nice()
			.range([height, 0]);

		// X Axis
		svg
			.append('g')
			.attr('transform', `translate(0,${height})`)
			.call(d3.axisBottom(x))
			.selectAll('text')
			.attr('transform', 'rotate(90)')
			.attr('x', 9)
			.attr('y', 0)
			.style('text-anchor', 'start');

		// Y Axis
		svg.append('g').call(d3.axisLeft(y));

		// Bars for Profit/Loss
		svg
			.selectAll('.bar-profitloss')
			.data(topWorkers)
			.enter()
			.append('rect')
			.attr('class', 'bar-profitloss')
			.attr('x', (d) => x(d.workerName))
			.attr('y', (d) => {
				const profitLossAbs = Math.abs(d.profitLoss);
				return y(profitLossAbs);
			})
			.attr('width', x.bandwidth() / 2)
			.attr('height', (d) => {
				const profitLossAbs = Math.abs(d.profitLoss);
				return height - y(profitLossAbs);
			})
			.attr('fill', (d) => {
				return d.profitLoss > 0 ? '#1976d2' : 'none';
			})
			.attr('stroke', '#1976d2')
			.attr('stroke-width', 2);

		// Bars for expenses
		svg
			.selectAll('.bar-expenses')
			.data(topWorkers)
			.enter()
			.append('rect')
			.attr('class', 'bar-expenses')
			.attr('x', (d) => x(d.workerName) + x.bandwidth() / 2)
			.attr('y', (d) => y(d.expenses))
			.attr('width', x.bandwidth() / 2)
			.attr('height', (d) => height - y(d.expenses))
			.attr('fill', 'red');

		// Title
		svg
			.append('text')
			.attr('x', width / 2)
			.attr('y', -45)
			.attr('text-anchor', 'middle')
			.style('font-size', '16px')
			.style('font-weight', 'bold')
			.text('Revenue and Expenses by Top 10 Workers (Based on Profit/Loss) [Last 3 Months]');
	};

	const renderPieChart = (ref, totalRevenue, totalExpenses) => {
		const margin = { top: 20, right: 30, bottom: 50, left: 150 };
		const width = 350 - margin.left - margin.right;
		const height = 275 - margin.top - margin.bottom;

		// Remove any existing chart
		d3.select(ref).selectAll('*').remove();

		const svg = d3
			.select(ref)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', `translate(${width / 2}, ${height / 2 - 10})`);

		const data = [
			{ label: 'Salary', value: totalRevenue },
			{ label: 'Expenses', value: totalExpenses },
		];

		const color = d3
			.scaleOrdinal()
			.domain(data.map((d) => d.label))
			.range(['#1976d2', 'orange']);

		//@ts-ignore
		const pie = d3.pie().value((d) => d.value);

		const arc = d3
			.arc()
			.innerRadius(0)
			.outerRadius(Math.min(width, height) / 2 - 1);

		//@ts-ignore
		const arcs = pie(data);

		svg
			.selectAll('path')
			.data(arcs)
			.enter()
			.append('path')
			//@ts-ignore
			.attr('fill', (d) => color(d.data.label))
			//@ts-ignore
			.attr('d', arc)
			.append('title')
			//@ts-ignore
			.text((d) => `${d.data.label}: $${d.data.value.toLocaleString()}`);

		svg
			.selectAll('text')
			.data(arcs)
			.enter()
			.append('text')
			.attr('transform', (d) => {
				//@ts-ignore
				const centroid = arc.centroid(d);
				const x = centroid[0];
				const y = centroid[1];
				const angle = x < 0 ? Math.atan2(y, x) * (180 / Math.PI) + 180 : Math.atan2(y, x) * (180 / Math.PI);
				return `translate(${x}, ${y}) rotate(${angle})`;
			})
			.attr('dy', '.35em')
			.attr('text-anchor', 'middle')
			.style('fill', 'white')
			//@ts-ignore
			.text((d) => `${((d.data.value / (totalRevenue + totalExpenses)) * 100).toFixed(2)}%`);

		const legend = svg
			.selectAll('.legend')
			.data(data)
			.enter()
			.append('g')
			.attr('class', 'legend')
			.attr('transform', (d, i) => `translate(${width / 2.5 + 25},${i * 20 - 30})`);

		legend
			.append('rect')
			.attr('width', 20)
			.attr('height', 20)
			//@ts-ignore
			.attr('fill', (d) => color(d.label));

		legend
			.append('text')
			.attr('x', 20)
			.attr('y', 10)
			.attr('dy', '.4em')
			.style('font-size', '10px')
			.text((d) => `${d.label} ($${d.value.toLocaleString()})`);
	};

	const renderProjectAnalyticsPieChart = (ref, projectAnalyticsData) => {
		const margin = { top: 20, right: 30, bottom: 50, left: 150 };
		const width = 350 - margin.left - margin.right;
		const height = 275 - margin.top - margin.bottom;
		// Remove any existing chart
		d3.select(ref).selectAll('*').remove();

		const svg = d3
			.select(ref)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', `translate(${width / 2}, ${height / 2 - 10})`);

		const data = projectAnalyticsData.map((d) => ({
			label: d.projectName,
			value: parseInt(d.totalSellingPrice),
		}));

		const color = d3
			.scaleOrdinal()
			.domain(data.map((d) => d.label))
			.range(d3.schemeCategory10);

		//@ts-ignore
		const pie = d3.pie().value((d) => d.value);

		const arc = d3
			.arc()
			.innerRadius(0)
			.outerRadius(Math.min(width, height) / 2 - 1);

		const arcs = pie(data);

		svg
			.selectAll('path')
			.data(arcs)
			.enter()
			.append('path')
			//@ts-ignore
			.attr('fill', (d) => color(d.data.label))
			//@ts-ignore
			.attr('d', arc)
			.append('title')
			//@ts-ignore
			.text((d) => `${d.data.label}: $${d.data.value.toLocaleString()}`);

		svg
			.selectAll('text')
			.data(arcs)
			.enter()
			.append('text')
			.attr('transform', (d) => {
				//@ts-ignore
				const centroid = arc.centroid(d);
				const x = centroid[0];
				const y = centroid[1];
				const angle = x < 0 ? Math.atan2(y, x) * (180 / Math.PI) + 180 : Math.atan2(y, x) * (180 / Math.PI);
				return `translate(${x}, ${y}) rotate(${angle})`;
			})
			.attr('dy', '.35em')
			.attr('text-anchor', 'middle')
			.style('fill', 'white')
			.style('font-size', '7px') // Adjust font size here
			//@ts-ignore
			.text((d) => `${((d.data.value / d3.sum(data, (d) => d.value)) * 100).toFixed(2)}%`);

		const legend = svg
			.selectAll('.legend')
			.data(data)
			.enter()
			.append('g')
			.attr('class', 'legend')
			.attr('transform', (d, i) => `translate(${width / 2.5 + 25},${i * 20 - 80})`);

		legend
			.append('rect')
			.attr('width', 20)
			.attr('height', 20)
			//@ts-ignore
			.attr('fill', (d) => color(d.label));

		legend
			.append('text')
			.attr('x', 24)
			.attr('y', 10)
			.attr('dy', '.35em')
			.style('font-size', '10px')
			//@ts-ignore
			.text((d) => `${d.label} ($${d.value.toLocaleString()})`);
	};

	// Calculate the width based on the larger value of revenue or expenses
	const formatNumberWithCommas = (number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const getWidth = (value) => {
		return Math.max(200, value.toString().length * 30); // Example width calculation
	};

	const maxWidth =
		Math.max(getWidth('$' + formatNumberWithCommas(revenue)), getWidth('$' + formatNumberWithCommas(expenses))) + 30;

	return (
		<div className={styles.dashboard}>
			<Typography variant='h4' gutterBottom>
				<DashboardIcon /> Analytics Dashboard
			</Typography>
			{loading ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '300px',
						width: '100%',
					}}>
					<CircularProgress size={100} />
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
					<Container style={{ maxWidth: '100%' }}>
						<div className={styles.root}>
							<div className={styles.cardContainer}>
								<div className={styles.firstGroup}>
									<Card
										variant='outlined'
										className={styles.infoCard}
										style={{ justifyContent: 'left', alignItems: 'self-start', marginRight: '10px' }}>
										<CardContent>
											<Typography variant='h6' gutterBottom className={styles.typography} style={{ fontSize: '25px' }}>
												No. of Active Projects (Last {monthsOfSalaryPaid} Month):
											</Typography>
											<Typography
												variant='h6'
												gutterBottom
												className={styles.typography}
												style={{ fontSize: '50px', marginBottom: '5px' }}>
												{formatNumberWithCommas(noOfActiveProjects)}
											</Typography>
										</CardContent>
									</Card>
									<Card
										variant='outlined'
										className={styles.infoCard}
										style={{ justifyContent: 'left', alignItems: 'self-start', marginRight: '10px' }}>
										<CardContent>
											<Typography variant='h6' gutterBottom className={styles.typography} style={{ fontSize: '25px' }}>
												No. of Workers:
											</Typography>
											<Typography
												variant='h6'
												gutterBottom
												className={styles.typography}
												style={{ fontSize: '50px', marginBottom: '5px' }}>
												{formatNumberWithCommas(totalNoOfWorkers)}
											</Typography>
										</CardContent>
									</Card>
								</div>
								<div className={styles.chartsContainer}>
									<div className={styles.column}>
										<Card
											variant='outlined'
											className={styles.card}
											style={{ justifyContent: 'left', alignItems: 'self-start' }}>
											<CardContent>
												<Typography
													variant='h6'
													gutterBottom
													className={styles.typography}
													style={{ fontSize: '25px' }}>
													Total Revenue (Last {monthsOfSalaryPaid} Month):
												</Typography>
												<Typography
													variant='h6'
													gutterBottom
													className={styles.typography}
													style={{ fontSize: '50px', marginBottom: '50px' }}>
													${formatNumberWithCommas(revenue)}
												</Typography>
											</CardContent>
										</Card>
										<Card variant='outlined' className={styles.card}>
											<CardContent>
												<Typography
													variant='h6'
													gutterBottom
													className={styles.typography}
													style={{ fontSize: '25px' }}>
													Total Expenses (Last {monthsOfSalaryPaid} Month):
												</Typography>
												<Typography
													variant='h6'
													gutterBottom
													className={styles.typography}
													style={{ fontSize: '50px', marginBottom: '50px' }}>
													${formatNumberWithCommas(expenses)}
												</Typography>
											</CardContent>
										</Card>
									</div>
									<div className={styles.column}>
										<Card variant='outlined' className={styles.card}>
											<CardContent>
												<Typography
													variant='h6'
													gutterBottom
													className={styles.typography}
													style={{ fontSize: '25px' }}>
													Revenue Breakdown:
												</Typography>
												<div className={styles.chartContainer}>
													<ProjectAnalyticsPieChart data={projectPieData} />
												</div>
											</CardContent>
										</Card>
										<Card variant='outlined' className={styles.card}>
											<CardContent>
												<Typography
													variant='h6'
													gutterBottom
													className={styles.typography}
													style={{ fontSize: '25px' }}>
													Expense Breakdown:
												</Typography>
												<div className={styles.chartContainer}>
													<ProjectAnalyticsPieChart data={pieData} />
												</div>
											</CardContent>
										</Card>
									</div>
								</div>
							</div>
							<div>
								<Card variant='outlined' className={styles.infoCard}>
									<CardContent>
										<Typography variant='h6' gutterBottom className={styles.typography} style={{ fontSize: '25px' }}>
											Overview of Revenue-Expenses-Profits (Past 3 Months):
										</Typography>
										<div className={styles.barChart}>
											<BarChart data={monthlyAnalyticsData} />
										</div>
									</CardContent>
								</Card>
							</div>
						</div>
					</Container>
				</div>
			)}
		</div>
	);
};

export default AnalyticsDashboard;
