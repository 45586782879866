import React, { useEffect, useState } from 'react';

import { Panel, IPanelProps } from '../../components/Layout/Panel/Panel';
import { ProjectModel, ProjectsDataModel } from '../../models/project.model';
import { EIcon } from '../../components/Layout/Panel/views/components/Icon';
import { IDataProps } from '../../components/Layout/Panel/views/components/SegmentBody';
import { ISegmentProps } from '../../components/Layout/Panel/views/Segment';
import { useProjects } from './hooks/projects';
import { AddEditProjectDialog } from '../../components/Dialog/AddEditProjectDialog';

export const ProjectPanel = (props: any) => {
	const DEFAULT_PAGE_SIZE = 20;
	const [selectedProject, setSelectedProject] = useState<any | null>();
	const [page, setPage] = useState<number>(0);
	const [showAddProjectDialog, setShowProjectDialog] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selectedProjectForEdit, setSelectedProjectForEdit] = useState<ProjectModel>();
	// Just alternates to trigger reload
	const [toggleForReload, setToggleForReload] = useState<boolean>(false);

	// Fetch all projects
	const projectsDataModel: ProjectsDataModel | undefined = useProjects(page, toggleForReload, DEFAULT_PAGE_SIZE);

	const createProjectData = (): Array<IDataProps> => {
		if (!projectsDataModel) return [];
		return projectsDataModel.projects.map((project: ProjectModel) => {
			return {
				text: project.name,
				textClickCb: () => {
					setSelectedProject(project);
					props.onProjectClick(project);
				},
				isTextSelected: selectedProject?.id === project?.id,
				icons: [
					{
						icon: EIcon.EDIT,
						iconCb: () => {
							setIsEditMode(true);
							setSelectedProjectForEdit({ ...project });
							setShowProjectDialog(true);
						},
					},
				],
			};
		});
	};

	const onAddIconClick = () => {
		setShowProjectDialog(true);
	};

	const refreshPanel = () => {
		// Purely for refreshing the page
		setToggleForReload(!toggleForReload);
	};

	const onProjectAdded = () => {
		// Either one of the state change will refresh the page
		if (projectsDataModel!.projects.length == DEFAULT_PAGE_SIZE) {
			// We want to move to the next page if current page is already full
			setPage((prevState) => prevState + 1);
		} else {
			refreshPanel();
		}
	};

	const projectProps: ISegmentProps = {
		header: {
			text: `Projects`,
			icons: [
				{
					icon: EIcon.ADD,
					iconCb: () => {
						onAddIconClick();
					},
				},
			],
		},
		placeholderText: 'No projects created',
		data: createProjectData(),
		pagination: {
			currentPage: page,
			totalPages: projectsDataModel?.totalPages as number,
			gotoNextPageCb: () => {
				if (projectsDataModel) {
					const { totalPages, currentPage } = projectsDataModel;

					if (currentPage < totalPages - 1) {
						setPage((prevState) => prevState + 1);
					}
				}
			},
			gotoPrevPageCb: () => {
				if (projectsDataModel) {
					const { totalPages, currentPage } = projectsDataModel;

					if (currentPage > 0) {
						setPage((prevState) => prevState - 1);
					}
				}
			},
		},
	};

	const panelProps: IPanelProps = {
		container: {
			width: props.width ? props.width : '100%',
			height: props.height ? props.height : '100%',
			padding: '8px',
		},
		segments: [projectProps],
	};

	useEffect(() => {
		if (projectsDataModel?.projects.length) {
			if (!selectedProject) {
				setSelectedProject({ ...projectsDataModel?.projects[0] });
				props.onProjectClick(projectsDataModel?.projects[0]);
			} else {
				const updatedProjectInfo = projectsDataModel?.projects.find((proj) => proj.id === selectedProject.id);

				if (updatedProjectInfo) {
					setSelectedProject({ ...updatedProjectInfo });
					props.onProjectClick(updatedProjectInfo);
				}
			}
		}
	}, [projectsDataModel]);

	return (
		<>
			<Panel panelProps={panelProps}></Panel>
			<AddEditProjectDialog
				isEditMode={isEditMode}
				selectedProjectForEdit={selectedProjectForEdit}
				show={showAddProjectDialog}
				close={() => {
					setShowProjectDialog(false);
					setIsEditMode(false);
					setSelectedProjectForEdit(undefined);
				}}
				refreshPanel={refreshPanel}
				onAdded={onProjectAdded}
			/>
		</>
	);
};
