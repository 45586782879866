import { useEffect, useState } from 'react';
import {
	fetchAllTypes,
	fetchAllSubTypesByType,
	fetchAllSubValuesBySubType,
	fetchAllValues,
} from '../../../services/type.service';
import { TypeDataModel, SubTypeValueDataModel } from '../../../models/project.model';
import { SubTypeDataModel, SubValueDataModel } from '../../../models/types.model';

export const useAllTypes = (): Array<TypeDataModel> | undefined => {
	const [typesList, setTypesList] = useState<Array<TypeDataModel>>();

	const fetchAllType = async () => {
		const typesResponse = await fetchAllTypes('type/all');

		if (!typesResponse.data?.success) return;
		setTypesList(typesResponse.data.data);
	};
	useEffect(() => {
		fetchAllType();
	}, []);

	return typesList;
};

export const useAllSubTypesByType = (typeId?: number): Array<SubTypeDataModel> | undefined => {
	const [subTypesList, setSubTypesList] = useState<Array<SubTypeDataModel>>();

	const fetchAllSubType = async () => {
		const subTypesResponse = await fetchAllSubTypesByType('type/subtype/name/getall?typeId=' + typeId);

		if (!subTypesResponse.data?.success) return;
		setSubTypesList(subTypesResponse.data.data);
	};

	useEffect(() => {
		if (typeId) fetchAllSubType();
	}, [typeId]);

	return subTypesList;
};

export const useAllSubValuesBySubType = (subTypeId: number): Array<SubValueDataModel> | undefined => {
	const [subValuesList, setSubValuesList] = useState<Array<SubValueDataModel>>();

	const fetchAllSubValues = async () => {
		const valuesResponse = await fetchAllSubValuesBySubType('type/subtype/value/getall?subtypeId=' + subTypeId);

		if (!valuesResponse.data?.success) return;
		setSubValuesList(valuesResponse.data.data);
	};

	useEffect(() => {
		fetchAllSubValues();
	}, [subTypeId]);

	return subValuesList;
};

export const useAllSubTypeValues = (): Array<SubTypeValueDataModel> | undefined => {
	const [valuesList, setValuesList] = useState<Array<SubTypeValueDataModel>>();

	const fetchAllSubtypeValues = async () => {
		const valuesResponse = await fetchAllValues('type/value/getall');

		if (!valuesResponse.data?.success) return;
		setValuesList(valuesResponse.data.data);
	};

	useEffect(() => {
		fetchAllSubtypeValues();
	}, []);

	return valuesList;
};
