import axios from './api';

export const fetchAllWorkDoneByWorker = async (userId: number, page: number, limit: number) => {
	const response = await axios.get('worker/get?userId=' + userId + '&page=' + page + '&limit=' + limit);

	return response.data;
};

export const updateWorkDoneRecord = async (id: number, level: number, unit: number) => {
	const response = await axios.put('worker/update?id=' + id + '&level=' + level + '&unit=' + unit);

	return response.data;
};

export const deleteWorkDoneRecord = async (id: number) => {
	const response = await axios.delete('worker/delete?id=' + id);

	return response.data;
};

export const getTotalNumberOfWorkers = async () => {
	const response = await axios.get('worker/get-total-number-of-workers');

	return response;
};
